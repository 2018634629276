/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { strFormatter } from "../../../helpers/common-utils";
import Api, { endpoints } from "../../../services/api.service";
import useApi from "../../../services/useApi";
import { GSTINPUTREGEX } from "../../../constants/regex";

const useCategoryNbrandsHook = () => {
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  const [category, setCategory] = useState(null);

  const [categoriesError, setCategoriesError] = useState(null);

  const {
    execute: getCategoriesBrands,
    isLoading,
    response,
    error,
  } = useApi(Api.get);

  const formatCategories = (categories) =>
    categories.map((category) => ({
      name: category.name,
      value: category.type,
    }));

  const formatBrands = (brands) =>
    brands.map((brand) => ({
      name: strFormatter(brand),
      value: brand?.split(" ").join("_"),
    }));

  const findCategorySpecificBrands = (categoryValue) => {
    let filteredCategories = response?.filter(
      (category) => category.type === categoryValue
    );
    let categoryObj =
      filteredCategories?.length > 0 ? filteredCategories[0] : {};
    return categoryObj?.brands || [];
  };

  const getCategories = () => {
    getCategoriesBrands(endpoints.getCategoriesAndBrands);
  };

  const getCategoryName = (categoryValue) => {
    let categoryIndex = categories?.findIndex(
      (category) => category.value === categoryValue
    );
    return categoryIndex >= 0
      ? categories[categoryIndex]?.name
      : strFormatter(categoryValue);
  };

  const getBrandName = (brandValue) => {
    let brandIndex = brands?.findIndex(
      (category) => category.value === brandValue
    );
    return brandIndex >= 0
      ? brands[brandIndex]?.name
      : strFormatter(brandValue);
  };

  const gstInputValidation = (e, setFieldValue, fieldName) => {
    const { value } = e.target,
      MAX_ALLOWED = 2;
    if (
      (GSTINPUTREGEX.test(value) || value === "") &&
      value.length <= MAX_ALLOWED
    ) {
      setFieldValue(fieldName, value);
    }
  };

  useEffect(() => {
    if (response && category) {
      let brandsList = findCategorySpecificBrands(category);
      setBrands(formatBrands(brandsList));
    }
  }, [category, response]);

  useEffect(() => {
    getCategoriesBrands(endpoints.getCategoriesAndBrands);
  }, []);

  useEffect(() => {
    if (response) {
      setCategories(formatCategories(response));
      setCategoriesError(null);
    } else if (error) {
      setCategories([]);
      setCategoriesError(error);
    }
  }, [response, error]);

  return {
    loadingCategoryNbrands: isLoading,
    categories,
    brands,
    setCategory,
    getCategories,
    categoriesError,
    getCategoryName,
    getBrandName,
    gstInputValidation,
  };
};

export default useCategoryNbrandsHook;
