import { useEffect, useState } from "react";
import Api from "../../../services/apiv2.service";

const useDrawerAPI = (activeOrderId) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchOrderCategoryDetails = async () => {
      let res = await Api.getOrderCategoryDetails(`?orderId=${activeOrderId}`);
      if (res.ok) {
        setData(res.data);
        setLoading(false);
      }
    };
    fetchOrderCategoryDetails();
    // eslint-disable-next-line
  }, []);
  return { loading, data };
};

export default useDrawerAPI;
