import React from "react";
import { Box } from "@mui/material";
import useStyles from "./style";
import clsx from "clsx";

export default function SidebarLink({ label, handleFun, Icon }) {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.menuItem)} onClick={handleFun}>
      <div className="menu_container">
        <span className="menu_icon">
          <Icon />
        </span>
        <span className="menu_title">{label}</span>
      </div>
    </Box>
  );
}
