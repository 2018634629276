import React from "react";
import QRCode from "react-qr-code";

const CustomQRCode = ({ value = "" }) => {
  return (
    <div>
      <QRCode value={value} />
    </div>
  );
};

export default CustomQRCode;
