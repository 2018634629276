import React from "react";
import initialState from "./initialState";
import reducer from "./reducer";

const PaymentsContext = React.createContext();

function PaymentsProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <PaymentsContext.Provider value={value}>
      {children}
    </PaymentsContext.Provider>
  );
}

function usePayments() {
  const context = React.useContext(PaymentsContext);
  if (context === undefined) {
    throw new Error("usePayments must be used within a PaymentsProvider");
  }
  return context;
}

export { PaymentsProvider, usePayments };
