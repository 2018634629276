/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useImperativeHandle } from "react";
import AppModal from "../AppModal";
import CollectPayment from "./CollectPayment";
import useCollectPaymentHook from "./useCollectPayment.hook";
import { usePayments as usePaymentsContex } from "./store/contex";

// eslint-disable-next-line no-empty-pattern
const Payments = ({ getPaymentData }, ref) => {
  const {
    open,
    openModal,
    closeModal,
    collectPayment,
    data,
    isOnline,
    showNetworkMsg,
    showVerifyBtn,
    checkPaymentStatus,
  } = useCollectPaymentHook(getPaymentData);
  const {
    state: { verifyPayment },
  } = usePaymentsContex();

  useImperativeHandle(
    ref,
    () => ({
      openCollectPaymentModal: openModal,
      closeCollectPaymentModal: closeModal,
      collectPayment: collectPayment,
    }),
    []
  );

  if (!open) return null;

  return (
    <AppModal
      title={"Collect Payment"}
      showModal={open}
      closeOnBackgroundClick={false}
      handleClose={closeModal}
    >
      <CollectPayment
        verifyPayment={verifyPayment?.data}
        data={data}
        onCancel={closeModal}
        isOnline={isOnline}
        showNetworkMsg={showNetworkMsg}
        showVerifyBtn={showVerifyBtn}
        checkPaymentStatus={checkPaymentStatus}
      />
    </AppModal>
  );
};

export default forwardRef(Payments);
