/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { upload } from "../imageEditor/constants";
import WhatsappTemplateView from "../WhatsappTemplateView";
import EditImageModal from "../EditImageModal";
import useWhatsappView from "./useWhatsappView.hook";
// import { images } from "../constants/whatsappTemplateImages";
import "../css/whatsapp.scss";
import useAnalytics from "../useAnalytics.createCampaign.hook";
import SectionHeader from "../../../components/SectionHeader";

const WhatsappView = ({ setPreviewDataOnUpload }) => {
  const {
    templateData,
    handleBack,
    previewImage,
    activeTab,
    setActiveTab,
    handleImageClick,
    editImage,
    setEditImage,
    handlePrevieImageClick,
    url,
    disableContinueButton,
    setDisableContinueButton,
    images,
  } = useWhatsappView();
  const {
    logCreateWhatsappPageLoaded,
    logCancelBtnClickInEditTemplateWhatsApp,
    logContinueBtnClickInEditTemplateWhatsApp,
  } = useAnalytics();

  useEffect(() => {
    logCreateWhatsappPageLoaded();
  }, []);

  let whatsaAppPayloadOverride = { ...templateData };
  if (templateData.data) {
    whatsaAppPayloadOverride = {
      data: {
        ...templateData.data,
        varaiables: { ...templateData.data.variables, storename: "bla" },
      },
    };
  }
  console.log(whatsaAppPayloadOverride);

  return (
    <>
      <SectionHeader heading="Edit Template Message" />
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={0}>
        {templateData && Object.keys(templateData).length !== 0 && (
          <WhatsappTemplateView
            data={{ ...templateData }}
            handleBack={handleBack}
            previewImage={previewImage}
            setPreviewDataOnUpload={setPreviewDataOnUpload}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            images={images}
            handleImageClick={handleImageClick}
            setDisableContinueButton={setDisableContinueButton}
          />
        )}

        <Box gridColumn="span 12" className="empty-variables-text">
          {disableContinueButton && (
            <span> Variables field can't be empty. </span>
          )}
        </Box>

        <Box gridColumn="span 12" className="edit-template-button-container">
          <Button
            onClick={() => {
              logCancelBtnClickInEditTemplateWhatsApp();
              handleBack();
            }}
            className="edit-template-cancel-button"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            component={Link}
            to={`${url}/${upload}`}
            className="edit-template-continue-button"
            disabled={disableContinueButton}
            onClick={logContinueBtnClickInEditTemplateWhatsApp}
          >
            Continue
          </Button>
        </Box>
      </Box>

      {editImage?.openModal && (
        <EditImageModal
          images={images}
          editImage={editImage}
          setEditImage={setEditImage}
          handlePrevieImageClick={handlePrevieImageClick}
        />
      )}
    </>
  );
};

export default WhatsappView;
