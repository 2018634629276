import { Alert, Snackbar } from "@mui/material";
import React from "react";

const AppAlert = ({ error, close, type = "error" }) => {
  return error ? (
    <Snackbar open={!!error} autoHideDuration={5000} onClose={close}>
      <Alert onClose={close} severity={type} sx={{ width: "100%" }}>
        {typeof error === "string" ? error : "Something went wrong!"}
      </Alert>
    </Snackbar>
  ) : null;
};

export default AppAlert;
