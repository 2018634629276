import React from "react";
import { TableCell, TableRow } from "@mui/material";

import { withStyles } from "@mui/styles";

const CustomTableCell = withStyles(() => ({
  root: {
    minWidth: 150,
    textAlign: "center !important",
    fontSize: "14px !important",
    lineHeight: "1.5rem !important",
    fontWeight: "500 !important",
    padding: "6.5px !important",
    color: "#527466",
  },
}))(TableCell);

export default function CustomerDetailsBody({ data }) {
  return (
    <TableRow>
      <CustomTableCell>{data.firstName}</CustomTableCell>
      <CustomTableCell>{data.lastName}</CustomTableCell>
      <CustomTableCell>{data.mobile}</CustomTableCell>
      <CustomTableCell>{data.email}</CustomTableCell>
      <CustomTableCell>
        {data.address1} {data.address2}
      </CustomTableCell>
      <CustomTableCell>{data.district}</CustomTableCell>
      <CustomTableCell>{data.state}</CustomTableCell>
      <CustomTableCell>{data.pincode}</CustomTableCell>
    </TableRow>
  );
}
