import React, { useEffect } from "react";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { growthbook_url } from "../../config";
import { useLocation } from "react-router-dom";
import { getBrowserDetails, parseJwt } from "./utils";

const growthbook = new GrowthBook({
  trackingCallback: (experiment, result) => {
    console.log({
      experimentId: experiment.key,
      variationId: result.variationId,
    });
  },
});

export default function GrowthBookFeatureProvider({ children }) {
  const location = useLocation();
  useEffect(() => {
    const callGrowthBook = async () => {
      fetch(growthbook_url)
        .then((res) => res.json())
        .then((json) => {
          growthbook.setFeatures(json.features);
        });
      let tokenValues = parseJwt(localStorage?.API_TOKEN);
      let finalSetAttribute = { ...tokenValues, browser: getBrowserDetails() };
      delete finalSetAttribute["iat"];
      delete finalSetAttribute["exp"];
      delete finalSetAttribute["isAdmin"];
      growthbook.setAttributes(finalSetAttribute);
    };

    if (location.pathname !== "/sign-in") {
      callGrowthBook();
    }
  }, [location]);
  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
}
