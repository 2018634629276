import {
  decrementQty,
  getExtras,
  incrementQty,
  removeItemAtIndex,
  replaceItemAtIndex,
} from "../../../helpers/common-utils";
import {
  GET_RETAILER_DETAILS_ERROR,
  GET_RETAILER_DETAILS_LOADING,
  GET_RETAILER_DETAILS_SUCCESS,
  STORE_CUSTOMER_DETAILS_SUCCESS,
  ADD_PRODUCT,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
  INCREMENT_QUANTITY,
  DECREMENT_QUANTITY,
  STORE_ADDITIONAL_CHARGE,
  STORE_DISCOUNT_DETAILS,
  STORE_RECEIVED_AMOUNT_DETAILS,
  STORE_IS_ROUND_OFF,
  STORE_INVOICE_DATE,
  GENERATE_INVOICE_LOADING,
  GENERATE_INVOICE_SUCCESS,
  GENERATE_INVOICE_ERROR,
  RESET_INVOICE_DETAILS,
  RESETTING,
  STORE_ERRORS,
  GET_INVOICE_DETAILS_LOADING,
  GET_INVOICE_DETAILS_SUCCESS,
  GET_INVOICE_DETAILS_ERROR,
} from "./constants";
import initialState from "./initialState";

function generateInvoiceReducer(state, action) {
  let newProducts;
  let newState;

  switch (action.type) {
    case GET_RETAILER_DETAILS_LOADING:
      return {
        ...state,
        retailerDetails: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case GET_RETAILER_DETAILS_SUCCESS:
      return {
        ...state,
        retailerDetails: {
          loading: false,
          data: action.data,
          error: null,
        },
      };

    case GET_RETAILER_DETAILS_ERROR:
      return {
        ...state,
        retailerDetails: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case STORE_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        customerDetails: action.data,
      };

    case ADD_PRODUCT:
      newProducts = [action.data, ...state.products];
      newState = getExtras({
        products: newProducts,
        receivedAmount: state.receivedAmount,
        additionalCharges: state.additionalCharges,
        discounts: state.discounts,
        isRoundOff: state.isRoundOff,
      });
      return {
        ...state,
        products: newProducts,
        ...newState,
      };

    case EDIT_PRODUCT:
      newProducts = replaceItemAtIndex(
        state.products,
        action.index,
        action.data
      );
      newState = getExtras({
        products: newProducts,
        receivedAmount: state.receivedAmount,
        additionalCharges: state.additionalCharges,
        discounts: state.discounts,
        isRoundOff: state.isRoundOff,
      });
      return {
        ...state,
        products: newProducts,
        ...newState,
      };

    case DELETE_PRODUCT:
      newProducts = removeItemAtIndex(state.products, action.index);
      newState = getExtras({
        products: newProducts,
        receivedAmount: state.receivedAmount,
        additionalCharges: state.additionalCharges,
        discounts: state.discounts,
        isRoundOff: state.isRoundOff,
      });
      return {
        ...state,
        products: newProducts,
        ...newState,
      };

    case INCREMENT_QUANTITY:
      newProducts = replaceItemAtIndex(
        state.products,
        action.index,
        incrementQty(action.product)
      );
      newState = getExtras({
        products: newProducts,
        receivedAmount: state.receivedAmount,
        additionalCharges: state.additionalCharges,
        discounts: state.discounts,
        isRoundOff: state.isRoundOff,
      });
      return {
        ...state,
        products: newProducts,
        ...newState,
      };

    case DECREMENT_QUANTITY:
      newProducts = replaceItemAtIndex(
        state.products,
        action.index,
        decrementQty(action.product)
      );
      newState = getExtras({
        products: newProducts,
        receivedAmount: state.receivedAmount,
        additionalCharges: state.additionalCharges,
        discounts: state.discounts,
        isRoundOff: state.isRoundOff,
      });
      return {
        ...state,
        products: newProducts,
        ...newState,
      };

    case STORE_ADDITIONAL_CHARGE:
      newState = getExtras({
        products: state.products,
        receivedAmount: state.receivedAmount,
        additionalCharges: action.data,
        discounts: state.discounts,
        isRoundOff: state.isRoundOff,
      });
      return {
        ...state,
        ...newState,
        additionalCharges: action.data,
      };

    case STORE_DISCOUNT_DETAILS:
      newState = getExtras({
        products: state.products,
        receivedAmount: state.receivedAmount,
        additionalCharges: state.additionalCharges,
        // percentage: action.data.percentage,
        discounts: action.data,
        isRoundOff: state.isRoundOff,
      });
      return {
        ...state,
        discounts: action.data,
        ...newState,
      };

    case STORE_RECEIVED_AMOUNT_DETAILS:
      newState = getExtras({
        products: state.products,
        receivedAmount: state.receivedAmount,
        additionalCharges: state.additionalCharges,
        discounts: state.discounts,
        isRoundOff: state.isRoundOff,
        ...action.data,
      });
      return {
        ...state,
        ...action.data,
        ...newState,
      };

    case STORE_IS_ROUND_OFF:
      newState = getExtras({
        products: state.products,
        receivedAmount: state.receivedAmount,
        additionalCharges: state.additionalCharges,
        discounts: state.discounts,
        isRoundOff: action.isRoundOff,
      });
      return {
        ...state,
        isRoundOff: action.isRoundOff,
        ...newState,
      };

    case STORE_INVOICE_DATE:
      return {
        ...state,
        invoiceDate: action.date,
      };

    case GENERATE_INVOICE_LOADING:
      return {
        ...state,
        invoiceDetails: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case GENERATE_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceDetails: {
          loading: false,
          data: action.data,
          error: null,
        },
      };

    case GENERATE_INVOICE_ERROR:
      return {
        ...state,
        invoiceDetails: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case GET_INVOICE_DETAILS_LOADING:
      return {
        ...state,
        invoiceData: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case GET_INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        invoiceData: {
          loading: false,
          data: action.data,
          error: null,
        },
      };

    case GET_INVOICE_DETAILS_ERROR:
      return {
        ...state,
        invoiceData: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case RESETTING:
      return {
        ...state,
        resetting: true,
      };

    case RESET_INVOICE_DETAILS:
      return {
        ...initialState,
        retailerDetails: state.retailerDetails,
        resetting: false,
      };

    case STORE_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };

    default:
      return state;
    // {
    //   throw new Error(`Unhandled action type: ${action?.type}`);
    // }
  }
}

export default generateInvoiceReducer;
