import React, { useEffect, useState } from "react";
import useOnlineStatus from "./useOnlineStatus";
import { toast } from "react-toastify";

export default function AppOnlineOROfflineNotifier() {
  const online = useOnlineStatus();
  const [offline, setOffline] = useState(null);

  useEffect(() => {
    if (!online && !offline) {
      toast.dismiss();
      toast.error("Your network is down");
      setOffline(true);
    } else if (offline && online) {
      toast.dismiss();
      toast.success("Your network is back");
      setOffline(null);
    }
    toast.clearWaitingQueue();
  }, [online, offline]);

  return <></>;
}
