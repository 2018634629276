import DateFNSUtils from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField } from "@mui/material";
import React from "react";
import { INPUT_DATE_FORMAT } from "../../helpers/constants";

const AppDatePicker = ({
  value = null,
  label,
  error,
  helperText,
  format,
  onClick,
  fullWidth = false,
  borderError = false,
  ...rest
}) => (
  <div style={{ width: fullWidth ? "100%" : "88%", display: "inline-block" }}>
    <LocalizationProvider dateAdapter={DateFNSUtils}>
      <DatePicker
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            size="small"
            helperText={helperText}
            fullWidth
            style={{
              borderRight: "0px",
              border: borderError ? "1px solid red" : "",
            }}
            onClick={onClick}
          />
        )}
        value={value}
        label={!value ? label : ""}
        {...rest}
        inputFormat={format || INPUT_DATE_FORMAT}
      />
    </LocalizationProvider>
  </div>
);

export default AppDatePicker;
