import { Grid, CircularProgress } from "@mui/material";
import React from "react";
import Layout from "../../components/Layout";
import "./campaign_style.css";
import useCampaignHistory from "./hook/useCampaignHistory.hook";
import AppPagination from "../../components/AppPagination";
import CampaignHistoryCard from "./CampaignHistoryCard";

export default function CampaignHistory() {
  const { campaignData, handleSetPage, pageNo, PAGE_SIZE, loading } =
    useCampaignHistory();

  return (
    <Layout pageTitle="campaign">
      <div className="campaign_history_container">
        <div className="campaign_history_title">
          <h3>Campaign History</h3>
        </div>
        <div className="campaign_history_list">
          {loading && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress color="success" />
            </div>
          )}
          <Grid container spacing={2}>
            {campaignData?.data.length > 0 &&
              campaignData?.data.map((item, index) => {
                return (
                  <Grid item xs="12" md="6" key={index}>
                    <CampaignHistoryCard cardData={item} />
                  </Grid>
                );
              })}
            {campaignData?.data?.length === 0 && (
              <div
                style={{ textAlign: "center", width: "100%", height: "100px" }}
              >
                <p>No Records Found</p>
              </div>
            )}
          </Grid>
        </div>
        <AppPagination
          name="Campaign History Page"
          totalCount={campaignData?.total}
          page={pageNo}
          setPage={handleSetPage}
          itemsPerRow={PAGE_SIZE}
        />
      </div>
    </Layout>
  );
}
