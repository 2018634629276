import { useEffect, useState } from "react";
import Api, { endpoints } from "../../../services/api.service";
import useAnalytics from "../useAnalytics.hook";

const useProfile = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const { logPageLoaded } = useAnalytics();

  const fetchRetailers = async () => {
    try {
      const data = await Api.get(endpoints.retailerProfile);
      setProfileData(data);
      setLoading(false);
    } catch (e) {
      setError(e);
    }
  };

  useEffect(() => {
    fetchRetailers();
    logPageLoaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, error, profileData };
};

export default useProfile;
