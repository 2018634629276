import React from "react";
import CustomInput from "../CustomInput";
import useStyles from "./style";

const TermsAndConditions = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.header}>Terms and Conditions</div>
      <CustomInput
        name="termsAndConditions"
        multiline
        rows={4}
        showCharCount
        maxCharCount={400}
      />
    </div>
  );
};

export default TermsAndConditions;
