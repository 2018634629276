import React, { useEffect, useState } from "react";
import { GoStorLogo } from "../../assets/images";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  MarkerClusterer,
  InfoWindow,
} from "@react-google-maps/api";
import "./style.css";
import GoogleAutoComplete from "./GoogleAutoComplete";
import { IconButton } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { toast } from "react-toastify";
import goRetailImg from "../../assets/images/icons/goretail.png";
import Api from "../../services/apiv2.service";

export default function StoreLocator() {
  const { isLoaded } = useJsApiLoader({
    id: "google-maps-script",
    googleMapsApiKey: process.env.REACT_APP_PUBLIC_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });
  const [selected, setSelected] = useState(null);
  const [center, setCenter] = useState({ lat: 20.59, lng: 78.9 });
  const [retailerDetails, setRetailerDetails] = useState(null);
  const [retailersList, setRetailersList] = useState([]);

  function locateUser() {
    toast.success("Fetching user location");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          toast.success("Located user");
          setSelected({ ...pos });
        },
        (pos) => {
          toast.error(
            `${pos.message}. Please enable location on the browser and in browser settings`
          );
        }
      );
    } else {
      // Browser doesn't support Geolocation
      toast.error("Error fetching location");
    }
  }

  async function getNearByStores(location) {
    const params = {
      radius: "60",
      longitude: location.lng,
      latitude: location.lat,
    };
    const resp = await Api.getNearbyStores(params);
    if (resp?.data?.status === "success") {
      const stores = resp?.data?.docs;
      if (stores.length === 0) {
        toast.success(
          "No Retailers found around you. Please select another nearby location"
        );
      }
      setRetailersList(resp?.data?.docs);
    }
  }

  useEffect(() => {
    if (selected) {
      setCenter(selected);
      getNearByStores(selected);
      // api call
    }
  }, [selected]);

  useEffect(() => {
    if (retailerDetails)
      setCenter({
        lat: retailerDetails.coordinates[1],
        lng: retailerDetails.coordinates[0],
      });
  }, [retailerDetails]);

  return (
    <>
      <section className="header-bar">
        <img src={GoStorLogo} alt="brand-logo" className="brand-logo" />
      </section>
      <section>
        <h1 className="goretail-heading">GoRetail Store Locator</h1>
      </section>
      <section className="map-integration">
        <div>
          {isLoaded && (
            <div className="maps">
              <div className="autocomplete-locate">
                <GoogleAutoComplete setSelected={setSelected} />
                <IconButton aria-label="locate" onClick={locateUser}>
                  <MyLocationIcon />
                </IconButton>
              </div>
              <div>
                <GoogleMap
                  zoom={selected || retailerDetails ? 15 : 5}
                  center={center}
                  mapContainerClassName="map-container"
                >
                  {selected && <Marker position={selected} />}
                  <MarkerClusterer>
                    {(clusterer) =>
                      retailersList &&
                      retailersList.map((eachRetailer) => {
                        const storeLocation = {
                          lat: eachRetailer.coordinates[1],
                          lng: eachRetailer.coordinates[0],
                        };
                        return (
                          <Marker
                            position={{ ...storeLocation }}
                            clusterer={clusterer}
                            title={eachRetailer.storeName}
                            icon={goRetailImg}
                            key={eachRetailer.storeName}
                          />
                        );
                      })
                    }
                  </MarkerClusterer>
                  {retailerDetails && (
                    <InfoWindow
                      position={{
                        lat: retailerDetails.coordinates[1],
                        lng: retailerDetails.coordinates[0],
                      }}
                    >
                      <div className="info-window">
                        {retailerDetails?.storeName}
                      </div>
                    </InfoWindow>
                  )}
                </GoogleMap>
              </div>
            </div>
          )}
        </div>
        <div className="store-names">
          {retailersList &&
            retailersList.map((eachRetailer) => (
              <div
                className="store"
                onClick={() => setRetailerDetails({ ...eachRetailer })}
                key={eachRetailer.name}
              >
                <div className="store-name">{eachRetailer.storeName}</div>
                <div className="store-address">
                  Address: {eachRetailer.address}
                </div>
                <div className="store-phone">
                  Phone: {eachRetailer.phoneNumber}
                </div>
              </div>
            ))}
          {retailersList.length === 0 && (
            <h2 className="empty-list">
              Please enter/select your location to see near by goRetail stores
            </h2>
          )}
        </div>
      </section>
    </>
  );
}
