import { useEffect, useState } from "react";
import Api from "../../../../services/apiv2.service";

const useOfferCard = (featureImageID) => {
  const hideImage = false;
  const [imageURL, setImageURL] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchFeaturedImage = async (imageId) => {
    const imageResponse = await Api.getFeaturedImage(`${imageId}?_fields=guid`);
    setImageURL(imageResponse.data);
    setLoading(false);
  };

  useEffect(() => {
    if (!hideImage) {
      if (featureImageID) {
        fetchFeaturedImage(featureImageID);
      }
    }
  }, [featureImageID, hideImage]);

  return { imageURL, loading };
};

export default useOfferCard;
