import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    closeButton: {
      float: "right",
      cursor: "pointer",
    },
  })
);

export default useStyles;
