import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "20px 40px",
      height: "100%",
      fontSize: "14px",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        padding: "10px 20px",
      },
    },
    fieldWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      // margin: "10px 0px",
      borderBottom: "1px solid #ccc",
      padding: "12px 0px",
      "&:last-child": {
        borderBottom: "none",
      },
    },
    paymentFieldWrapper: {
      display: "flex",
      alignItems: "flex-start",
      gap: "10px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    fieldLabel: { color: theme.palette.primary.main },
    fieldValue: { fontWeight: "600", display: "flex" },
    payments: {},
    paymentTitle: {
      fontSize: "16px",
      fontWeight: "600",
      padding: "10px 0px",
    },
    paymentTypeWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      // marginBottom: "25px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    paymentTypeContainer: {
      background: "#348F6C08",
      border: "1px solid #348F6C64",
      padding: "10px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      marginRight: "10px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginRight: "0",
        marginBottom: "10px",
      },
    },
    paymentImg: {
      width: "28px",
      height: "28px",
      marginRight: "10px",
    },
    checkBoxOterCircle: {
      width: "25px",
      height: "25px",
      background: "white",
      border: "1px solid #ccc",
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 0,
      marginLeft: "10px",
      [theme.breakpoints.down("md")]: {
        marginRight: "10px",
        marginLeft: "auto",
      },
    },
    selectedWrapper: {
      background: "#338f6c",
      color: "white !important",
    },
    selected: {
      background: "#338f6c",
    },
    checkboxSelected: {
      width: "16px",
      height: "16px",
      background: "white",
      border: "1px solid #ccc",
      borderRadius: "16px",
      zIndex: 1,
    },
    totalAmount: {
      fontSize: "18px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    taxWrapper: {
      borderBottom: "1px solid #ccc",
      padding: "12px 0px",
    },
    taxContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    additionalChargeWrapper: {
      borderBottom: "1px solid #ccc",
      padding: "12px 0px",
    },
    additionalCharge: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "10px",
    },
    additionalChargePriceField: {
      margin: "0px 10px",
    },
    pointerLabel: {
      cursor: "pointer",
    },
    discountLable: {
      marginRight: "10px",
    },
  })
);

export default useStyles;
