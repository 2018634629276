import React from "react";
import initialState from "./initialState";
import reducer from "./reducer";

const GenerateInvoiceContext = React.createContext();

function GenerateInvoiceProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <GenerateInvoiceContext.Provider value={value}>
      {children}
    </GenerateInvoiceContext.Provider>
  );
}

function useGenerateInvoiceContex() {
  const context = React.useContext(GenerateInvoiceContext);
  if (context === undefined) {
    throw new Error(
      "useGenerateInvoiceContex must be used within a GenerateInvoiceProvider"
    );
  }
  return context;
}

export { GenerateInvoiceProvider, useGenerateInvoiceContex };
