import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import createMarkup from "../../helpers/createMarkup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "700px",
  bgcolor: "background.paper",
  borderRaius: "10px",
  boxShadow: 24,
  overflow: "auto",
  p: 4,
};

const DetailsModal = ({ open, handleClose, title, content }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title && title}

          <span
            style={{ float: "right", cursor: "pointer" }}
            onClick={handleClose}
          >
            X
          </span>
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, overflow: "auto" }}
        >
          {content && (
            <span
              className="wordpress-content"
              dangerouslySetInnerHTML={createMarkup(content)}
            />
          )}
        </Typography>
      </Box>
    </Modal>
  );
};

export default DetailsModal;
