import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker.hook";

const useAnalytics = () => {
  const { logEvent } = useAnalyticsEventTracker("Sidebar");

  const logMenuClick = (name = "", type = "menu") => {
    logEvent(
      `${name
        .split(" ")
        .join("_")
        .toLocaleUpperCase()}_${type.toLocaleUpperCase()}_CLICKED`,
      `${name} ${type} CTA`
    );
  };

  const logGostorLanding = () => {
    logEvent(`GO_STOR_PAGE_LANDING`, `Gostor CTA `);
  };

  return {
    logMenuClick,
    logGostorLanding,
  };
};

export default useAnalytics;
