import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    childrenContainer: {
      padding: "12px 12px",
      overflow: "auto",
      [theme.breakpoints.down("md")]: {
        padding: "0px",
      },
    },
    card: {
      boxShadow: "#d2d2d2 3px 2px 10px 0px",
    },
    profileBox: {
      margin: "12px",
      [theme.breakpoints.down("md")]: {
        margin: "0px 0px 12px 0",
      },
    },
  })
);

export default useStyles;
