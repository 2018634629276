import React from "react";
import StyledTableCell from "../../helpers/StyledTableCell";
import AppNoData from "../../components/AppNoData";
import { withStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  Chip,
  TableCell,
} from "@mui/material";

const CustomTableCell = withStyles(() => ({
  root: {
    fontSize: "14px",
    lineHeight: "1.5rem !important",
    fontWeight: "500 !important",
    padding: "6.5px !important",
    width: "0px",
    color: "#527466",
  },
}))(TableCell);

export default function BulkDownloadDesktop({ bulkDowloadData, headerTitles }) {
  return (
    <Table stickyHeader aria-label="sticky table">
      <TableHead height="50">
        <TableRow>
          {headerTitles.map((title, i) => (
            <StyledTableCell key={i}>
              <Typography sx={{ fontSize: 13 }} color="white" fontWeight="bold">
                {title}
              </Typography>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody className="Body">
        {bulkDowloadData?.bulkInvoiceData?.length > 0 ? (
          bulkDowloadData?.bulkInvoiceData?.map((bulkData, i) => (
            <TableRow key={i}>
              <CustomTableCell>#{i + 1}</CustomTableCell>
              {/* <CustomTableCell>#{i + 1}</CustomTableCell> */}
              <CustomTableCell>{bulkData.dateRange}</CustomTableCell>
              <CustomTableCell>{bulkData.status}</CustomTableCell>
              <CustomTableCell>
                {bulkData.status === "FAILED" && (
                  <small>Please try again with different date range.</small>
                )}
                {bulkData.status !== "FAILED" && (
                  <>
                    {bulkData.downloadLink ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={bulkData.downloadLink}
                      >
                        Download is ready <br />
                        <small
                          style={{
                            color: "black",
                            textDecoration: "underline",
                          }}
                        >
                          Please Click here to download
                        </small>
                      </a>
                    ) : (
                      <Chip color="primary" label="In Progress" />
                    )}
                  </>
                )}
              </CustomTableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={headerTitles.length}>
              <AppNoData />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
