import React from "react";

const ImageTypesTab = ({
  tabName,
  defaultClassName,
  currentActiveTab,
  setActiveTab,
  activeTab,
}) => {
  return (
    <span
      className={`${defaultClassName} ${
        activeTab === currentActiveTab ? "active" : ""
      }`}
      onClick={() => setActiveTab(currentActiveTab)}
    >
      {tabName}
    </span>
  );
};

export default ImageTypesTab;
