import {
  Table,
  TableRow,
  TableHead,
  Typography,
  TableBody,
  CircularProgress,
  Box,
  TableContainer,
} from "@mui/material";
import StyledTableCell from "../../helpers/StyledTableCell";
import React from "react";
import Layout from "../../components/Layout";
import useCustomerDetails from "./hook/useCustomer.hook";
import CustomerDetailsBody from "../../components/CustomerDetailsBody";
import AppPagination from "../../components/AppPagination";
import useStyle from "./style/style";
import { BrowserView, MobileView } from "react-device-detect";
import { RootTable } from "../../helpers/RootTable";

const headerTitles = [
  "First Name",
  "Last Name",
  "Mobile",
  "Email",
  "Address",
  "District",
  "State",
  "Pincode",
];

export default function CustomerDetails() {
  const { customerData, pageNo, handleSetPage, PAGE_SIZE, loading } =
    useCustomerDetails();
  const classes = useStyle();
  return (
    <Layout pageTitle={"Customer Details"}>
      <Box>
        <BrowserView>
          <Box className={classes.loadingContainer}>
            {loading && <CircularProgress color="success" />}
          </Box>
          <Typography color={"primary"} fontSize={20} fontWeight={"bold"} m={2}>
            Total - {customerData && customerData.total}
          </Typography>
          <Box>
            <TableContainer>
              <RootTable>
                <Table stickyHeader>
                  <TableHead height="50">
                    <TableRow>
                      {headerTitles.map((title, i) => (
                        <StyledTableCell key={i} align="center">
                          <Typography
                            sx={{ fontSize: 13 }}
                            color="white"
                            fontWeight="bold"
                          >
                            {title}
                          </Typography>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className="Body">
                    {customerData && customerData.customers.length ? (
                      customerData.customers.map((data, i) => (
                        <CustomerDetailsBody key={i} data={data} />
                      ))
                    ) : (
                      <div>
                        <p>No Records</p>
                      </div>
                    )}
                  </TableBody>
                </Table>
              </RootTable>
            </TableContainer>
          </Box>
          <AppPagination
            name="Customer Repository Page"
            totalCount={customerData?.total}
            page={pageNo}
            setPage={handleSetPage}
            itemsPerRow={PAGE_SIZE}
          />
        </BrowserView>
      </Box>
      <MobileView>
        {customerData && customerData.customers ? (
          customerData.customers.map((data, i) => (
            <>
              <Typography>
                <span className={classes.mobileTitle}>First Name</span> -{" "}
                {data.firstName}
              </Typography>
              <Typography>
                <span className={classes.mobileTitle}>Last Name</span> -{" "}
                {data.lastName}
              </Typography>
              <Typography>
                <span className={classes.mobileTitle}>Mobile</span> -{" "}
                {data.mobile}
              </Typography>
              <Typography>
                <span className={classes.mobileTitle}>Email</span> -{" "}
                {data.email}
              </Typography>
              <Typography>
                <span className={classes.mobileTitle}>Address</span> -{" "}
                {data.address1} {data.address2}
              </Typography>
              <Typography>
                <span className={classes.mobileTitle}>District</span> -{" "}
                {data.district}
              </Typography>
              <Typography>
                <span className={classes.mobileTitle}>State</span> -{" "}
                {data.state}
              </Typography>
              <Typography>
                <span className={classes.mobileTitle}>Pincode</span> -{" "}
                {data.pincode}
              </Typography>
              <hr />
            </>
          ))
        ) : (
          <p>No records</p>
        )}
        <AppPagination
          name="Customer Repository Page"
          totalCount={customerData?.total}
          page={pageNo}
          setPage={handleSetPage}
          itemsPerRow={PAGE_SIZE}
        />
      </MobileView>
    </Layout>
  );
}
