import { useState } from "react";
import { toast } from "react-toastify";
import Api, { endpoints } from "../../../services/api.service";
import useDebounce from "../../../hooks/useDebounce";

const useCreatePayment = () => {
  const [showModal, setShowModal] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [showOthers, setShowOthers] = useState(false);
  const [loading, setLoading] = useState(false);

  const createPaymentApi = async (payload) => {
    setLoading(true);
    payload["customerDetails"] = { mobile: payload.mobile.toString() };
    delete payload["mobile"];
    payload["type"] = "ONLINE";
    payload["fasterCheckout"] = true;
    payload["totalAmount"] = payload.totalAmount.toString();
    const response = await Api.post(
      `${endpoints.generateInvoice}?cp=1`,
      payload
    );
    if (response?.paymentLink) {
      setPaymentData(response);
      setShowModal(true);
      setLoading(false);
    } else {
      toast.error("Error while creating Payment Link");
      setLoading(false);
    }
  };

  const setProductDetails = (data, setFieldValue, values) => {
    if (data) {
      setFieldValue("products", [
        {
          productCatalogueId: data._id,
          category: data.category
            ? data.category
            : data.productCategoryInfo_name,
          hsn: data.productCategoryInfo_hsnCode,
          gst: data.productCategoryInfo_gstPercentage,
          brand: data.brand_name,
          model: data.modelName,
          image: data.images_primaryImage,
          mrp: data.mrp,
          quantity: 1,
          finalPrice: "",
          unitPrice: "",
        },
        ...values.products,
      ]);
    } else {
      setFieldValue("products", [
        { category: "", brand: "", finalPrice: "", unitPrice: "", quantity: 1 },
        ...values.products,
      ]);
      setShowOthers(true);
    }
  };

  const removeProductCard = (index, values, set) => {
    let products = [...values.products];
    if (products.length > 0) {
      products.splice(index, 1);
      set("products", products);
    }
    calculateTotalAmount(set, products);
  };

  const onQuantityUpdate = (val, setFieldValue, index, values, flag) => {
    let products = [...values.products];
    products[index].quantity += val;
    products[index].finalPrice = (
      products[index].quantity * parseInt(products[index]?.unitPrice)
    ).toString();
    setFieldValue("products", products);
    calculateTotalAmount(setFieldValue, products);
  };

  const onPriceChange = (val, setFieldValue, index, values) => {
    let products = [...values.products];
    products[index].unitPrice = val;
    products[index].finalPrice = (val * products[index]?.quantity).toString();
    setFieldValue("products", products);
    calculateTotalAmount(setFieldValue, products);
  };

  const calculateTotalAmount = useDebounce((set, products) => {
    let totalAmount = 0;
    products.forEach((p) => {
      if (p.unitPrice) totalAmount += parseInt(p.unitPrice) * p.quantity;
    });
    set("totalAmount", totalAmount);
  }, 100);

  return {
    createPaymentApi,
    showModal,
    paymentData,
    loading,
    setProductDetails,
    showOthers,
    removeProductCard,
    onQuantityUpdate,
    onPriceChange,
  };
};

export default useCreatePayment;
