/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useQueryParams } from "../../../../../helpers/common-utils";

const useExtrasHook = (values, invoiceData, checkQC) => {
  const [taxableAmount, setTaxableAmount] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [gsts, setGsts] = useState([]);

  const params = useQueryParams();
  let isEdit = params?.edit === "1";

  if (checkQC) {
    isEdit = true;
  }

  useEffect(() => {
    if (values?.products) {
      let { products } = values;

      let newGsts = [];
      let newTaxableAmount = 0;
      let newFinalPrice = 0;
      let newBalanceAmount = 0;

      products.map((product) => {
        let finalPrice = product?.unitPrice
          ? Number(product.unitPrice) * Number(product.quantity)
          : product?.finalPrice;

        let gstValue = Number(product.gst) / 100 + 1;
        newTaxableAmount += Number(finalPrice) / gstValue;
        let newTaxableAmountPerUnit = Number(finalPrice) / gstValue;

        let gst = newTaxableAmountPerUnit * (Number(product.gst) / 100);
        let cgstPerc = Number(product.gst) / 2;
        newGsts.push({
          cgstPerc: cgstPerc,
          cgstAmount: gst / 2,
          sgstPerc: cgstPerc,
          sgstAmount: gst / 2,
        });
        newFinalPrice += Number(finalPrice);
        return null;
      });

      newBalanceAmount = newFinalPrice - Number(amountPaid);

      setGsts(newGsts);
      setTaxableAmount(newTaxableAmount);
      setFinalPrice(newFinalPrice);
      setBalanceAmount(newBalanceAmount);
    }
  }, [values, amountPaid]);

  useEffect(() => {
    if (isEdit && invoiceData?.products) {
      setAmountPaid(invoiceData.totalAmount);
    }
  }, [invoiceData, isEdit]);

  return {
    taxableAmount,
    gsts,
    finalPrice,
    amountPaid,
    balanceAmount,
  };
};

export default useExtrasHook;
