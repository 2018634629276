import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Backdrop,
  Modal,
  Fade,
  Link,
} from "@mui/material";
import moment from "moment";
import useStyle from "./styles";
import clsx from "clsx";
import {
  formatCurrency,
  formatDate,
  formatPaymentMode,
  getTransactionStatus,
  transactionOrderStatusClasses,
} from "../../helpers/constants";
import useTransactionHistoryCardHook from "../../components/TransactionHistoryCard/useTransactionHistoryCardHook";
import AppDatePicker from "../../components/AppDatePicker";
import AppInput from "../../components/AppInput";
import useRedirectionDialog from "../../hooks/useRedirectionDialog";
import ModalContentORLoader from "../../components/ModalContentORLoader";
import useAnalytics from "./useAnalytics.hook";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default function TransactionCard({
  data,
  adminUser,
  onEditSettlement,
  handleInvoiceModalOpen,
  handleRaiseRequestOpen,
  installationFeature,
}) {
  const { dialogActive, setRedirectionURL } = useRedirectionDialog();
  const {
    logCreateInvoiceBtnClick,
    logEditInvoiceBtnClick,
    logViewInvoiceBtnClick,
  } = useAnalytics();

  const {
    edit,
    setEdit,
    error,
    handleEditSettlementData,
    settledAmount,
    setSettledAmount,
    settledDateTime,
    setSettledDateTime,
  } = useTransactionHistoryCardHook(
    data.orderId,
    data.settlementAmount,
    data.total_amount,
    data.settlementDate,
    onEditSettlement,
    data.created_at
  );

  const classes = useStyle();

  const handleInvoiceState = (invoiceState, invoiceUrl) => {
    switch (invoiceState) {
      case "NOT_INITIATED": {
        return (
          <>
            <Box display="flex" justifyContent="space-around">
              <Button
                variant="outlined"
                size="small"
                style={{ fontSize: "10px" }}
                color="primary"
                onClick={() => {
                  logCreateInvoiceBtnClick();
                  setRedirectionURL(
                    `/generate-invoice?orderId=${
                      data.orderId
                    }&redirectFrom=order-history&cp=1${
                      data.fasterCheckout ? "&qc=1" : ""
                    }`
                  );
                }}
              >
                Create invoice
              </Button>
              <Button
                variant="outlined"
                size="small"
                style={{ fontSize: "10px" }}
                color="primary"
                onClick={() => handleInvoiceModalOpen(data.orderId)}
              >
                Upload Invoice
              </Button>
            </Box>
          </>
        );
      }
      case "SYSTEM_GENERATED": {
        return (
          <Button
            variant="outlined"
            size="small"
            style={{ fontSize: "10px" }}
            color="primary"
            onClick={() => {
              logEditInvoiceBtnClick();
              setRedirectionURL(
                `/generate-invoice?invoiceNumber=${data.invoiceNumber}&edit=1`
              );
            }}
            disabled={data.editOrderLimitExceeded || data.editOrderInProgress}
          >
            Edit invoice
          </Button>
        );
      }
      case "USER_GENERATED": {
        return (
          <Button
            variant="outlined"
            size="small"
            style={{ fontSize: "10px", marginTop: "10px" }}
            color="primary"
            onClick={() => handleInvoiceModalOpen(data.orderId)}
          >
            {invoiceUrl === "" ? "Upload Invoice" : "Re-Upload Invoice"}
          </Button>
        );
      }
      default:
        return "";
    }
  };

  return (
    <>
      <Box mt={3} className={classes.transactionContainer}>
        <Grid container className={classes.topContainer} p={1}>
          <Grid item xs={9}>
            <Typography className={classes.headerTitle}>
              PAYMENT ID :{" "}
              <span className={classes.transactionData}>#{data?.orderId}</span>
            </Typography>
            <Typography className={classes.transactionData}>
              {moment(data.paymentDate).format("DD-MMM-YYYY")} |{" "}
              {data?.customerMobile}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Box textAlign={"center"}>
              <p
                className={clsx(
                  classes.transactionData,
                  classes[transactionOrderStatusClasses(data.orderStatus)]
                )}
              >
                {getTransactionStatus(data?.orderStatus)}
              </p>
            </Box>
          </Grid>
        </Grid>

        <Grid container pl={2} pr={2} pb={2} pt={2} m={0} bgcolor={"#EFF4F0"}>
          {adminUser && (
            <Grid>
              <Typography className={classes.transactionTitle}>
                STORE NAME:{" "}
                <span className={classes.transactionData}>
                  {data.storeName}
                </span>
              </Typography>
              <Typography className={classes.transactionTitle}>
                PAYMENT REFERENCE:{" "}
                <span className={classes.transactionData}>
                  {data.paymentRefrence}
                </span>
              </Typography>
              <Typography className={classes.transactionTitle}>
                RETAILER NAME:{" "}
                <span className={classes.transactionData}>
                  {data?.retailerName || "-"}
                </span>
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography className={classes.transactionTitle}>
              CATEGORIES :{" "}
              <span className={classes.transactionData}>
                {formatPaymentMode(data.allCategories)}
              </span>
            </Typography>
            <Typography className={classes.transactionTitle}>
              PAYMENT MODE:{" "}
              <span className={classes.transactionData}>
                {formatPaymentMode(data.paid_through)}
              </span>
            </Typography>
            <Typography className={classes.transactionTitle}>
              TOTAL ORDER AMOUNT:{" "}
              <span className={classes.transactionData}>
                ₹ {formatCurrency(data?.totalOrderAmount)}
              </span>
            </Typography>
            <Typography className={classes.transactionTitle}>
              PAYMENT TYPE:{" "}
              <span className={classes.transactionData}>
                {data.orderType ? data.orderType.replace("_", " ") : "-"}
              </span>
            </Typography>
            <Typography className={classes.transactionTitle}>
              INVOICE :
              <span style={{ marginLeft: "5px" }}>
                {data?.invoiceUrl && data?.invoiceUrl !== "" ? (
                  <Link
                    href={data?.invoiceUrl}
                    onClick={logViewInvoiceBtnClick}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {data?.invoiceNumber}
                  </Link>
                ) : (
                  "-"
                )}
              </span>
              <div style={{ marginTop: "3px", marginBottom: "5px" }}>
                {handleInvoiceState(data?.invoiceState, data?.invoiceUrl)}
              </div>
            </Typography>
            {installationFeature && (
              <Typography className={classes.transactionTitle}>
                INSTALLATION:{" "}
                {data?.products?.map((product) => {
                  if (product.installable) {
                    return (
                      <div key={product.hsn}>
                        <Typography sx={{ mb: "5px", fontSize: "10px" }}>
                          {product.brand} - {product.model_name}
                        </Typography>
                        <span>
                          <Button
                            size="small"
                            variant="outlined"
                            sx={{ mr: "5px" }}
                            onClick={() =>
                              handleRaiseRequestOpen(product, data.orderId)
                            }
                            disabled={
                              product.request_already_raised ||
                              data?.invoiceState === "NOT_INITIATED"
                            }
                          >
                            {data?.invoiceState !== "NOT_INITIATED"
                              ? product.request_already_raised
                                ? "Request Raised"
                                : "Raise Request"
                              : "Create Invoice to raise Request"}
                          </Button>
                        </span>
                      </div>
                    );
                  } else {
                    return (
                      <div key={product.hsn}>
                        <Typography sx={{ mb: "5px", fontSize: "10px" }}>
                          {product.brand} - {product.model_name}
                        </Typography>
                        <span>
                          <Button
                            size="small"
                            variant="outlined"
                            sx={{ mr: "5px" }}
                            disabled={true}
                          >
                            Not Serviceable
                          </Button>
                        </span>
                      </div>
                    );
                  }
                })}
              </Typography>
            )}

            <Typography className={classes.transactionTitle}>
              SETTLEMENT AMOUNT:{" "}
              <span className={classes.transactionData}>
                {formatCurrency(data.settlementAmount) || "-"}
              </span>
            </Typography>
            <Typography className={classes.transactionTitle}>
              SETTLEMENT DATE:{" "}
              <span className={classes.transactionData}>
                {formatDate(`${data.settlementDate}Z`)}
              </span>
            </Typography>
          </Grid>
          {adminUser && (
            <Grid mt={2} container justifyContent="end">
              <Button
                size="small"
                className="primary"
                variant="outlined"
                onClick={() => setEdit(true)}
              >
                Edit
              </Button>
            </Grid>
          )}
        </Grid>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={edit}
          onClose={() => setEdit(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={edit}>
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Edit Settlement
              </Typography>
              <Box mt={2}>
                <AppDatePicker
                  name="Settlement Date"
                  label="Settlement Date"
                  value={settledDateTime}
                  onChange={(date) => {
                    if (moment(date).isValid()) {
                      const pickedDate = moment(date);
                      const selectedDateTime = moment();
                      selectedDateTime.date(pickedDate.date());
                      selectedDateTime.month(pickedDate.month());
                      selectedDateTime.year(pickedDate.year());
                      setSettledDateTime(selectedDateTime);
                    }
                  }}
                  error={!!error?.dateError}
                  helperText={error?.dateError}
                  minDate={new Date(data?.created_at)}
                  maxDate={new Date()}
                />
              </Box>
              <Box mt={2}>
                <AppInput
                  type="Number"
                  value={settledAmount}
                  onChange={setSettledAmount}
                  placeholder="Settlement Amount"
                  error={!!error.priceError}
                  helperText={error.priceError}
                />
              </Box>
              <Box display="flex" mt={3}>
                <Button
                  variant="contained"
                  onClick={handleEditSettlementData}
                  style={{ marginRight: 5 }}
                >
                  Submit
                </Button>
                <Button variant="outlined" onClick={() => setEdit(false)}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </Box>
      <ModalContentORLoader
        open={dialogActive}
        title="Redirecting..."
        hideClose={true}
        msg="Please wait while we redirect you to Create Invoice Screen"
      />
    </>
  );
}
