import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";

const CustomButton = withStyles({
  root: {
    borderRadius: "4 !important",
    padding: "10px 10px !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    lineHeight: "20px !important",
    letterSpacing: 0.02857,
    textTransform: "capitalize !important",
    width: "70%",
  },
})(Button);

const AppButton = ({ children, ...rest }) => (
  <CustomButton color="primary" variant="contained" {...rest}>
    {children}
  </CustomButton>
);

export default AppButton;
