import React from "react";

import Layout from "../../components/Layout";
// import CollectPaymentFormWrapper from "./components/CollectPaymentForm";

// import { GenerateInvoiceProvider } from "../generate-invoice-v3/store/contex";
// import { PaymentsProvider } from "../../components/Payments/store/contex";
import QuickCheckout from "../QuickCheckout";
import { useFeature } from "@growthbook/growthbook-react";
import ProPromo from "../sign-in/ProPromo";

const CollectPayment = () => {
  const isEnabled = useFeature("goretail_quickcheckout").on;
  const isCollectPaymentEnabled = useFeature("show-collect-payment").on;
  localStorage.setItem("qc", isEnabled);
  return (
    <>
      {
        // isEnabled ? (
        isCollectPaymentEnabled ? (
          <Layout pageTitle={"Collect Payment"}>
            <ProPromo />
          </Layout>
        ) : (
          <QuickCheckout />
        )
        // ) : (
        //   <Layout pageTitle={"Collect Payment"}>
        //     <GenerateInvoiceProvider>
        //       <PaymentsProvider>
        //         <CollectPaymentFormWrapper />
        //       </PaymentsProvider>
        //     </GenerateInvoiceProvider>
        //   </Layout>
        // )
      }
    </>
  );
};

export default CollectPayment;
