/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./style.scss";

import { toTitleCase } from "../../../../../helpers/common-utils";
import Api, { endpoints } from "../../../../../services/api.service";
import useApi from "../../../../../services/useApi";

import CustomInput from "../CustomInput";

const CustomerForm = ({
  values,
  setFieldValue,
  // setFieldError,
  formErrors,
  setFormErrors,
  disableFields,
}) => {
  const {
    execute: getStateCity,
    isLoading: isLoadingPincode,
    response: pincodeResponse,
    error: pincodeError,
  } = useApi(Api.get);

  useEffect(() => {
    if (pincodeResponse && String(values.pincode).length === 6) {
      setFieldValue("state", toTitleCase(pincodeResponse?.state));
      setFieldValue("district", toTitleCase(pincodeResponse?.district));
      setFormErrors("pincode", "");
    } else if (pincodeError && String(values.pincode).length === 6) {
      setFormErrors("pincode", String(pincodeError));
      setFieldValue("state", "");
      setFieldValue("district", "");
    }
  }, [pincodeResponse, pincodeError]);

  useEffect(() => {
    setFormErrors("pincode", "");
    if (values?.pincode && String(values?.pincode).length === 6) {
      //  Get State and District
      getStateCity(endpoints.getStateCity, { pincode: values?.pincode });
    } else {
      setFieldValue("state", "");
      setFieldValue("district", "");
    }
  }, [values?.pincode]);

  return (
    <div className="customer-form">
      <div className="form-label">Customer Details</div>
      <div className="field-container">
        <CustomInput
          placeholder="First Name*"
          name={`firstName`}
          label={`First Name*`}
          autoComplete="off"
        />

        <CustomInput
          placeholder="Last Name"
          name={`lastName`}
          label={`Last Name`}
          autoComplete="off"
        />
      </div>

      <div className="field-container">
        <CustomInput
          startingText="+91"
          placeholder="Mobile Number*"
          name={`mobile`}
          label={`Mobile Number*`}
          disabled={disableFields}
          autoComplete="off"
        />

        <CustomInput
          placeholder="Email Address"
          name={`email`}
          label={`Email Address`}
          autoComplete="off"
        />
      </div>

      <div className="form-label">Address Details</div>

      <div className="field-container">
        <CustomInput
          placeholder="House / Flat / Block No*"
          name={`address1`}
          label={`House / Flat / Block No*`}
          autoComplete="off"
        />

        <CustomInput
          placeholder="Apartment / Road"
          name={`address2`}
          label={`Apartment / Road`}
          autoComplete="off"
        />
      </div>

      <div className="field-container">
        <CustomInput
          placeholder="Area"
          name={`area`}
          label={`Area`}
          autoComplete="off"
        />

        <CustomInput
          placeholder="PIN Code*"
          name={`pincode`}
          label={`PIN Code*`}
          isLoading={isLoadingPincode}
          autoComplete="off"
          error={formErrors?.pincode}
        />
      </div>

      <div className="field-container">
        <CustomInput
          placeholder="State"
          name={`state`}
          label={`State`}
          disabled={true}
        />

        <CustomInput
          placeholder="City"
          name={`district`}
          label={`City`}
          disabled={true}
        />
      </div>
    </div>
  );
};

export default CustomerForm;
