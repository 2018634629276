import {
  CREATE_PAYMENT_LINK_LOADING,
  CREATE_PAYMENT_LINK_SUCCESS,
  CREATE_PAYMENT_LINK_ERROR,
  VERIFY_PAYMENT_LINK_LOADING,
  VERIFY_PAYMENT_LINK_SUCCESS,
  VERIFY_PAYMENT_LINK_ERROR,
  RESET_PAYMENT_LINK,
} from "./constants";
import initialState from "./initialState";

function paymentsReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_PAYMENT_LINK_LOADING:
      return {
        ...state,
        createPayment: {
          isLoading: true,
          data: null,
          error: null,
        },
      };

    case CREATE_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        createPayment: {
          isLoading: false,
          data: action.data,
          error: null,
        },
      };

    case CREATE_PAYMENT_LINK_ERROR:
      return {
        ...state,
        createPayment: {
          isLoading: false,
          data: null,
          error: action.error,
        },
      };

    case VERIFY_PAYMENT_LINK_LOADING:
      return {
        ...state,
        verifyPayment: {
          isLoading: true,
          data: null,
          error: null,
        },
      };

    case VERIFY_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        verifyPayment: {
          isLoading: false,
          data: action.data,
          error: null,
        },
      };

    case VERIFY_PAYMENT_LINK_ERROR:
      return {
        ...state,
        verifyPayment: {
          isLoading: false,
          data: null,
          error: action.error,
        },
      };

    case RESET_PAYMENT_LINK:
      return {
        ...initialState,
      };

    default:
      return state;
    // {
    //   throw new Error(`Unhandled action type: ${action?.type}`);
    // }
  }
}

export default paymentsReducer;
