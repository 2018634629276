import { useEffect, useState } from "react";
import Api from "../../../services/apiv2.service";

export default function useAppDrawer() {
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const handleOpen = () => setOpenModalStatus(true);
  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setOpenModalStatus(false);
    }
  };

  useEffect(() => {
    if (openModalStatus) {
      async function callGetGostorApi() {
        let res = await Api.getGostorURL();
        if (res.data.redirect_url) {
          setOpenModalStatus(false);
          window.open(res.data.redirect_url, "_blank");
        }
      }
      callGetGostorApi();
    }
  }, [openModalStatus]);

  // const handleLogout = () => {
  //   localStorage.clear();
  //   handleUserTrackingAPI({
  //     event: TRACKING_VALUES["logout"],
  //     timestamp: Date.now(),
  //   });
  //   history.push("/transaction-history");
  // };

  return { openModalStatus, handleOpen, handleClose };
}
