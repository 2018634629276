import { useState } from "react";
import { isMobile } from "react-device-detect";

const useFormStatus = () => {
  const [formStatus, setFormStatus] = useState(isMobile ? false : true);
  const handleFormStatus = () => {
    setFormStatus(!formStatus);
  };
  return { formStatus, handleFormStatus };
};

export default useFormStatus;
