import Api from "./apiv2.service";

// const events = ["LOGIN", "LOGOUT", "IDLE", "OUT_OF_FOCUS", "IN_FOCUS"];

export const TRACKING_VALUES = {
  login: "LOGIN",
  logout: "LOGOUT",
  idle: "IDLE",
  focus_out: "OUT_OF_FOCUS",
  focus_in: "IN_FOCUS",
  active: "ACTIVE",
};

const disableURL = ["/sign-in"];

export const handleUserTrackingAPI = async (ev, callback = () => {}) => {
  if (!disableURL.includes(window.location.pathname)) {
    let res = await Api.activityTracker(ev);
    if (res.ok) {
      callback();
    }
  }
};
