import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      width: "150px",
      padding: "10px",
    },
    navMenus: {
      marginLeft: "25px",
      marginTop: "25px",
    },
    card: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      height: "100%",
    },
    icon: {
      width: "24px",
      height: "24px",
      marginRight: "10px",
    },
    sidebarContainer: {
      padding: "5px 0px",
      display: "flex",
      justifyContent: "center",
    },
    menuItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "10px",
      fontSize: "18px",
      paddingRight: "15px",
      textDecoration: "none",
      marginLeft: "10px",
      "&:hover": {
        backgroundColor: "white",
        color: "black",
        borderTopLeftRadius: "35px",
        borderBottomLeftRadius: "35px",
        cursor: "pointer",
      },
    },
    activeMenu: {
      backgroundColor: "white",
      color: "black",
      borderTopLeftRadius: "35px",
      borderBottomLeftRadius: "35px",
    },
    subMenuContainer: {
      backgroundColor: "white",
      width: "75%",
      margin: "0px 0px 0px auto",
      color: theme.palette.primary.main,
      borderBottomLeftRadius: "10px",
    },
    subMenuItem: {
      color: "#0000008E",
      width: "95%",
      margin: "0 auto",
      display: "block",
      textAlign: "left",
      padding: "0px",
      marginTop: "7px",
      marginBottom: "7px",
    },
    subnav: {
      backgroundColor: "#EEF6F3",
      color: "#338F6C",
      width: "100%",
      margin: "0 auto",
    },
    menuSubTitle: {
      fontSize: "14px",
      color: "#4C4C4C",
      padding: "5px",
    },
    subMenuActive: {
      fontWeight: "bold",
      borderRadius: "10px",
      width: "100%",
    },
    logout_position: {
      position: "absolute",
      bottom: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        width: "auto",
      },
    },
    childrenContainer: {
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "15px",
    },
  })
);

export default useStyles;
