export const editorInit = {
  selection: {
    interactive: false,
    transformer: {
      borderStroke: "red",
      borderStrokeWidth: 3,
      anchorSize: 15,
      anchorColor: "red",
      anchorStroke: "red",
      anchorBorderWidth: 1,
      anchorCornerRadius: 30,
      borderDash: [0, 0],
    },
  },
};

export const DEFAULT_LABEL_CONFIG = {
  type: "label",
  drawInfo: {
    container: {
      x: 40,
      y: 100,
    },
    tag: {
      fill: "#262626",
    },
    text: {
      text: "Double-click to edit text",
      fill: "cyan",
      fontSize: 40,
    },
  },
};

export const defaultTextDrawingsPayload = [{ ...DEFAULT_LABEL_CONFIG }];

// export const dataRight = {
//   stage: {
//     className: "Stage",
//     filters: [],
//     attrs: {
//       width: 593,
//       height: 338,
//       x: 0,
//       y: 0
//     }
//   },
//   layer: {
//     className: "Layer",
//     filters: [],
//     attrs: {
//       x: 0,
//       y: 0,
//       width: 593,
//       height: 338
//     }
//   },
//   background: {
//     image: {
//       className: "Image",
//       filters: [],
//       attrs: {
//         url: "",
//         x: 0,
//         y: 0,
//         width: 593,
//         height: 338
//       }
//     },
//     overlay: {
//       className: "Rect",
//       filters: [],
//       attrs: {
//         width: 593,
//         height: 338,
//         x: 0,
//         y: 0
//       }
//     }
//   },
//   shapes: [
//     {
//       className: "Label",
//       filters: [],
//       children: [
//         {
//           attrs: {
//             width: 217.8515625,
//             height: 40
//           },
//           className: "Tag"
//         },
//         {
//           attrs: {
//             text: "Double-click",
//             fill: "#ff0000",
//             fontSize: 24,
//             height: 40
//           },
//           className: "Text"
//         }
//       ],
//       attrs: {
//         x: 370,
//         y: 60,
//         width: 217.8515625,
//         height: 40
//       }
//     },
//     {
//       className: "Label",
//       filters: [],
//       children: [
//         {
//           attrs: {
//             width: 217.2382812499994,
//             height: 160,
//             scaleX: 0.9999999999999984
//           },
//           className: "Tag"
//         },
//         {
//           attrs: {
//             text: "Double-click to edit text longer messages",
//             fill: "#ff0000",
//             fontSize: 24,
//             height: 160,
//             width: 217.2382812499994,
//             scaleX: 0.9999999999999984
//           },
//           className: "Text"
//         }
//       ],
//       attrs: {
//         x: 370,
//         y: 113.99999999999977,
//         scaleY: 0.9999999999999984,
//         width: 217.2382812499994,
//         height: 160
//       }
//     }
//   ]
// };

// export const dataLeft = {
//   stage: {
//     className: "Stage",
//     filters: [],
//     attrs: {
//       width: 593,
//       height: 338,
//       x: 0,
//       y: 0
//     }
//   },
//   layer: {
//     className: "Layer",
//     filters: [],
//     attrs: {
//       x: 0,
//       y: 0,
//       width: 593,
//       height: 338
//     }
//   },
//   background: {
//     image: {
//       className: "Image",
//       filters: [],
//       attrs: {
//         url: "https://assets.gostor.com/3.png",
//         x: 0,
//         y: 0,
//         width: 593,
//         height: 338
//       }
//     },
//     overlay: {
//       className: "Rect",
//       filters: [],
//       attrs: {
//         width: 593,
//         height: 338,
//         x: 0,
//         y: 0
//       }
//     }
//   },
//   shapes: [
//     {
//       className: "Label",
//       filters: [],
//       children: [
//         {
//           attrs: {
//             width: 156.5156250000002,
//             height: 23,
//             scaleX: 0.9999999999999986
//           },
//           className: "Tag"
//         },
//         {
//           attrs: {
//             text: "Double-click",
//             fill: "#ff0000",
//             fontSize: 23,
//             height: "auto",
//             width: 156.5156250000002,
//             scaleX: 0.9999999999999986
//           },
//           className: "Text"
//         }
//       ],
//       attrs: {
//         x: 32,
//         y: 82.99999999999994,
//         scaleY: 0.9999999999999986,
//         width: 156.5156250000002,
//         height: 23
//       }
//     },
//     {
//       className: "Label",
//       filters: [],
//       children: [
//         {
//           attrs: {
//             width: 155.77148437500003,
//             height: 69,
//             scaleX: 0.9999999999999996
//           },
//           className: "Tag"
//         },
//         {
//           attrs: {
//             text: "Double-click to edit text longer messages",
//             fill: "#ff0000",
//             fontSize: 23,
//             height: "auto",
//             width: 155.77148437500003,
//             scaleX: 0.9999999999999996
//           },
//           className: "Text"
//         }
//       ],
//       attrs: {
//         x: 32,
//         y: 126,
//         scaleY: 0.9999999999999996,
//         width: 155.77148437500003,
//         height: 69
//       }
//     }
//   ]
// }
