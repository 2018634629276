import React from "react";
import { Box, Table, TableBody, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import useStyle from "./style";

export default function BankDetails({ bankData, kyc }) {
  const classes = useStyle();
  return (
    <Box>
      <Table
        aria-label="Bank details"
        className={classes.tableContianer}
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableBody className={classes.tableBody}>
          <TableRow>
            <TableCell className={classes.tableTitle}>Bank Name:</TableCell>
            <TableCell className={classes.tableCell} align="left">
              {bankData?.bank || "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell scope="row">Account No.:</TableCell>
            <TableCell className={classes.tableCell} align="left">
              {bankData?.account_no || "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>IFSC Code:</TableCell>
            <TableCell className={classes.tableCell} align="left">
              {bankData?.ifsc || "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Branch Name:</TableCell>
            <TableCell className={classes.tableCell} align="left">
              {bankData?.branch || "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Account Holder:</TableCell>
            <TableCell className={classes.tableCell} align="left">
              {bankData?.account_holder || "-"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
