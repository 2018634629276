import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import AppDropDown from "../AppDropDown";
import AppInput from "../AppInput";
import AppTags from "../AppTags";

const EditableField = ({ title, value, onChange, fieldType }) => {
  if (fieldType === "DROPDOWN") {
    return (
      <AppDropDown
        value={value}
        onChange={onChange}
        name={title}
        placeholder={title}
      />
    );
  }
  if (fieldType === "SWITCH") {
    return (
      <FormControlLabel
        style={{ height: 50 }}
        control={
          <Switch
            checked={value}
            onChange={onChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={title}
      />
    );
  }
  return <AppInput value={value} onChange={onChange} placeholder={title} />;
};

const DetailCard = ({ title, value, editable, onChange, fieldType }) => (
  <Grid item xs={3} mb={6} pr={6}>
    <Typography fontWeight="bold" color="text.title">
      {title}
    </Typography>
    <Box mt={1}>
      {(editable && (
        <EditableField
          title={title}
          value={value}
          editable={editable}
          fieldType={fieldType}
          onChange={onChange}
        />
      )) || <Typography fontWeight="medium">{value || "-"}</Typography>}
    </Box>
  </Grid>
);

const RetailerDetails = ({
  editable = false,
  onSubmit,
  onCancel,
  details = {},
  allCategories = [],
}) => {
  const {
    storeId,
    storeName,
    line_1,
    locality,
    city,
    state,
    pincode,
    is_active,
    services,
    categoryName,
    bankDetails,
  } = details;

  return (
    <Grid pl={10} pr={4} py={5}>
      <Grid display="flex" flexWrap="wrap">
        <DetailCard title="Store ID" value={storeId} editable={editable} />
        <DetailCard title="Store Name" value={storeName} editable={editable} />
        <DetailCard title="Address Line 1" value={line_1} editable={editable} />
        <DetailCard
          title="Address Line 2"
          value={locality}
          editable={editable}
        />
        <DetailCard title="City" value={city} editable={editable} />
        <DetailCard title="State" value={state} editable={editable} />
        <DetailCard title="Pin Code" value={pincode} editable={editable} />
        {storeId && (
          <DetailCard
            title="Active"
            value={is_active ? "Active" : "InActive"}
            editable={editable}
            fieldType="SWITCH"
          />
        )}
        <Grid item xs={12} md={2} pr={6}>
          <Typography fontWeight="bold" color="text.title" mb={2}>
            Services
          </Typography>
          <AppTags tags={services} editable={editable} options={[]} />
        </Grid>
        <Grid item xs={12} md={3} pr={6}>
          <Typography fontWeight="bold" color="text.title" mb={2}>
            Bank Details
          </Typography>
          <Typography variant="subtitle1">
            Account No. - {bankDetails?.account_no || "NONE"}
          </Typography>
          <Typography variant="subtitle1">
            Bank - {bankDetails.bank || "NONE"}
          </Typography>
          <Typography variant="subtitle1">
            IFSC - {bankDetails.ifsc || "NONE"}
          </Typography>
          <Typography>Branch - {bankDetails.branch || "NONE"}</Typography>
        </Grid>
        <Grid item xs={12} md={7} pr={6}>
          <Typography fontWeight="bold" color="text.title" mb={2}>
            Category Name
          </Typography>
          <AppTags
            tags={categoryName}
            editable={editable}
            options={allCategories}
          />
        </Grid>
      </Grid>

      {editable && (
        <Grid mt={6} display="flex" justifyContent="center">
          <Grid mr={2}>
            <Button variant="contained" onClick={onSubmit}>
              Submit
            </Button>
          </Grid>
          <Grid ml={2}>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default RetailerDetails;
