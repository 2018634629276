import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";

export default function BulkDownloadMobile({ bulkDowloadData, headerTitles }) {
  return (
    <>
      {bulkDowloadData?.bulkInvoiceData &&
      bulkDowloadData?.bulkInvoiceData.length > 0 ? (
        <TableContainer component={Paper}>
          {bulkDowloadData?.bulkInvoiceData.map((bulkData, i) => {
            return (
              <Table className="mobile-tickets" key={i}>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Sl.No
                    </TableCell>
                    <TableCell align="right">#{i + 1}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Date Range
                    </TableCell>
                    <TableCell align="right">{bulkData.dateRange}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Status
                    </TableCell>
                    <TableCell align="right">{bulkData.status}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Download Link
                    </TableCell>
                    <TableCell align="right">
                      {bulkData.status === "FAILED" && (
                        <small>
                          Please try again with different date range.
                        </small>
                      )}
                      {bulkData.status !== "FAILED" && (
                        <>
                          {bulkData.downloadLink ? (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={bulkData.downloadLink}
                            >
                              Download is ready <br />
                              <small
                                style={{
                                  color: "black",
                                  textDecoration: "underline",
                                }}
                              >
                                Please Click here to download
                              </small>
                            </a>
                          ) : (
                            <Chip color="primary" label="In Progress" />
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            );
          })}
        </TableContainer>
      ) : (
        "NO New Records"
      )}
    </>
  );
}
