// import Api, { endpoints } from "./api.service";

const getRefreshToken = async () => {
  // const { accessToken, refreshToken } = await Api.get(endpoints.getAccessToken);
  // localStorage.setItem("API_TOKEN", accessToken);
  // localStorage.setItem("REFRESH_TOKEN", refreshToken);
  // return accessToken;
  localStorage.clear();
  window.location.reload();
};

export default getRefreshToken;
