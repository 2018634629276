/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Formik } from "formik";
import { Alert, Button, Modal, Box, Typography } from "@mui/material";
import { useLocation } from "react-router";
import "./style.scss";

import useRetailer from "../store/useRetailer";
import useGenerateInvoiceHook from "./useGenerateInvoice.hook";
import { useGenerateInvoiceContex } from "../store/contex";

import generateInvoiceSchema from "./generateInvoiceSchema";

import GenerateInvoiceForm from "./GenerateInvoiceForm";
import InvoiceCreatedModal from "../../generate-invoice/components/InvoiceCreatedModal";
import Payments from "../../../components/Payments";
import Confirmation from "./components/Confirmation";
import ErrorListener from "../../../components/ErrorListener";
import Loader from "../../../components/Loader";
import ModalContentORLoader from "../../../components/ModalContentORLoader";

import { formatDate, useQueryParams } from "../../../helpers/common-utils";
import useAnalytics from "./useAnalytics.hook";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: "10px",
  "@media screen and (max-width: 899px)": {
    width: "90%",
  },
  bgcolor: "background.paper",
  boxShadow: 24,
};

const GenerateInvoice = ({ invoiceData }) => {
  const [formData, setFormData] = useState({});
  const [formErrors, setErrors] = useState({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { getRetailsDetails } = useRetailer();
  const {
    state: { retailerDetails, invoiceDetails },
  } = useGenerateInvoiceContex();
  const {
    confirmationRef,
    paymentRef,
    invoiceCreatedRef,
    dialogActive,
    handleSubmit,
    onError,
    resetGenerateInvoice,
    getPaymentData,
  } = useGenerateInvoiceHook();

  const location = useLocation();
  const params = useQueryParams();

  const { logSubmitBtnClick } = useAnalytics();

  const isCollectPayment = params?.cp === "1";
  const isEdit = params?.edit === "1";

  const disableFields =
    params?.redirectFrom === "order-history" ||
    params?.cp === "1" ||
    params?.qc === "1";

  const setFormErrors = (fieldName, error) => {
    setErrors((errors) => ({ ...errors, [fieldName]: error }));
  };

  function payProductDetails(disabled, product) {
    if (disabled) {
      if (params?.cp && product.finalPrice > 0) {
        return Number(product.finalPrice) / Number(product.quantity);
      }
      return "";
    } else {
      return Number(product.finalPrice) / Number(product.quantity);
    }
  }

  let initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    // mobile: "",
    address1: "",
    address2: "", //  both address2 and area are merged while passing to backend.
    area: "",
    pincode: "",
    state: "",
    district: "",
    // invoiceDate: Date.now(),
    // type: "ONLINE",
    mop: "",
    paymentRefId: "",
    additionalCharges: [],
    taxableAmount: "0",
    totalAmount: "0",
    balanceAmount: "0",
    discountAmount: "0",
    invoiceDiscount: "0",
    fullyPaid: false,
    isRoundOff: false,
    emiAmount: "",
    tenure: "",
    provider: "",
    ...invoiceData,
    ...invoiceData?.customerDetails,
    products: invoiceData
      ? invoiceData?.products?.map((product) => ({
          ...product,
          finalPrice: payProductDetails(disableFields, product),
          model: product?.model ? product.model : "",
          serialNo: product?.serialNo ? product.serialNo : "",
          hsn: product?.hsn ? product?.hsn : "",
          gst: product?.gst ? product?.gst : "",
          quantity: product?.quantity ? product?.quantity : 1,
        }))
      : [
          {
            category: "",
            brand: "",
            hsn: "",
            gst: "",
            model: "",
            serialNo: "",
            quantity: "1",
            finalPrice: "",
          },
        ],
    mobile: invoiceData?.customerDetails?.mobile || "",
    type:
      invoiceData?.type === "OFFLINE" ? "CASH" : invoiceData?.type || "ONLINE",
    invoiceDate: invoiceData?.createdAt
      ? formatDate(invoiceData.createdAt)
      : Date.now(),
    ...location.state,
    paidThrough: invoiceData?.mop || location?.state?.mop || "",
    termsAndConditions: "",
    signatureUrl: "",
    receivedAmount:
      invoiceData?.receivedAmount && String(invoiceData?.receivedAmount) !== "0"
        ? invoiceData?.receivedAmount
        : "",
  };

  return (
    <div>
      {retailerDetails?.error && (
        <Alert
          severity="error"
          action={
            <Button color="inherit" size="small" onClick={getRetailsDetails}>
              RETRY
            </Button>
          }
        >
          Unable to fetch <b>Retails Details!</b>
        </Alert>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={generateInvoiceSchema}
        onSubmit={(values) => {
          let totalProductSum = values.products.reduce(
            (partialSum, a) =>
              partialSum +
              Number(
                a?.unitPrice
                  ? Number(a.unitPrice) * Number(a.quantity)
                  : Number(a.finalPrice)
              ),
            0
          );
          if (totalProductSum === Number(values.totalAmount)) {
            logSubmitBtnClick(values?.type);
            setFormData(values);

            let hasGlobalError = Object.keys(formErrors).some(
              (key) => formErrors[key]
            );
            if (!hasGlobalError) {
              confirmationRef?.current?.openConfirmationModal(values);
            }
          } else {
            handleOpen();
          }
        }}
        render={({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldError,
          isSubmitting,
        }) => {
          return (
            <>
              <ErrorListener
                isSubmitting={isSubmitting}
                errors={errors}
                values={values}
                onError={() => onError(errors)}
              />
              <GenerateInvoiceForm
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
                disableFields={disableFields}
                isCollectPayment={isCollectPayment}
                isEdit={isEdit}
                invoiceData={invoiceData}
              />
            </>
          );
        }}
      />

      <Confirmation
        ref={confirmationRef}
        isCollectPayment={isCollectPayment}
        isEdit={isEdit}
        invoiceDetails={formData}
        oldInvoiceData={invoiceData}
        onSubmit={() => {
          handleSubmit(formData);
          confirmationRef?.current?.closeConfirmationModal();
        }}
      />

      <InvoiceCreatedModal
        ref={invoiceCreatedRef}
        onClose={resetGenerateInvoice}
      />

      <Payments ref={paymentRef} getPaymentData={getPaymentData} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              background: "#F6F9F7",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <p style={{ margin: "0px" }}>Error</p>
          </Box>
          <Typography id="modal-modal-description" sx={{ mt: 2, p: 3 }}>
            Total Paid Amount mismatch with total of products
          </Typography>
          <Box style={{ padding: "10px" }}>
            <div style={{ textAlign: "right" }}>
              <Button variant="outlined" onClick={handleClose}>
                Close
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>

      {(invoiceDetails?.loading || retailerDetails?.loading) && (
        <Loader open={true} />
      )}

      <ModalContentORLoader
        open={dialogActive}
        title="Redirecting..."
        hideClose={true}
        msg="Please wait while we redirect you to Order History"
      />
    </div>
  );
};

export default GenerateInvoice;
