const initialState = {
  createPayment: {
    isLoading: false,
    data: null,
    error: null,
  },
  verifyPayment: {
    isLoading: false,
    data: null,
    error: null,
  },
};

export default initialState;
