import React from "react";
import {
  Button,
  TextField,
  IconButton,
  Box,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Layout from "../../components/Layout";
import { BrowserView, MobileView } from "react-device-detect";
import InstallationBrowserView from "./components/InstallationBrowserView";
import InstallationMobileView from "./components/InstallationMobileVIew";
import RequestInstallationModal from "./components/RequestInstallationModal";
import useModalToggle from "./useModalToggle";
import useInstallation from "./useInstallation";
import AppPagination from "../../components/AppPagination";
import "./installation.scss";

const itemsPerRow = 20;

const Installation = () => {
  //   const classes = useStyle();
  //   const { loading, profileData } = useProfile();
  const { open, handleOpen, handleClose } = useModalToggle();
  const {
    installationData,
    page,
    setPage,
    handleOnMobileSearch,
    refetchData,
    installationDataLoading,
  } = useInstallation();

  const totalCount = installationData?.additionalInfo?.ticketCount;

  return (
    <Layout pageTitle={"Installation"}>
      <div className="installation-container">
        <div className="installation-header">
          <div className="installation-count">
            <h4>Total Orders - {totalCount}</h4>
          </div>
          <div className="search-request-container">
            <div className="search">
              <TextField
                fullWidth
                id="standard-bare"
                variant="outlined"
                size="small"
                placeholder="Phone no."
                onChange={(e) => handleOnMobileSearch(e)}
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </div>
            <div className="request-container">
              <Button variant="outlined" onClick={handleOpen}>
                Request Installation
              </Button>
            </div>
          </div>
        </div>
        {installationDataLoading ? (
          <Box textAlign="center">
            <CircularProgress style={{ marginTop: "20px" }} />
          </Box>
        ) : (
          <>
            <BrowserView>
              <InstallationBrowserView installationData={installationData} />
            </BrowserView>
            <MobileView>
              <InstallationMobileView installationData={installationData} />
            </MobileView>
            {installationData && (
              <AppPagination
                page={page}
                setPage={setPage}
                totalCount={totalCount}
                itemsPerRow={itemsPerRow}
              />
            )}
          </>
        )}
      </div>
      <RequestInstallationModal
        open={open}
        handleClose={handleClose}
        refetchData={refetchData}
      />
    </Layout>
  );
};

export default Installation;
