import React from "react";
import useStyles from "./style";

import { useGenerateInvoiceContex } from "../../../store/contex";

const Signature = ({ values, setFieldValue }) => {
  const {
    state: { retailerDetails },
  } = useGenerateInvoiceContex();

  const classes = useStyles();

  const removeSignature = () => {
    setFieldValue("signatureUrl", "");
  };

  const addSignatureUrl = () => {
    setFieldValue(
      "signatureUrl",
      retailerDetails?.data?.businessDetails?.signature
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        Authorised Signature for Company Name
      </div>
      <div className={classes.signature}>
        {values?.signatureUrl ? (
          <div className={classes.signatureImg}>
            <img src={values?.signatureUrl} alt="signature" height="80px" />
            <div className={classes.removeSignature} onClick={removeSignature}>
              Remove Signature
            </div>
          </div>
        ) : (
          <div className={classes.addSignature} onClick={addSignatureUrl}>
            + Add Signature
          </div>
        )}
      </div>
    </div>
  );
};

export default Signature;
