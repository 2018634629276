import * as yup from "yup";

export function maxDate18() {
  var result = new Date();
  result.setDate(result.getDate() + 365 * 18);
  return result;
}

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const validationSchema = yup.object({
  amount: yup
    .number()
    .required("ERROR: The number is required!")
    .test(
      "Is positive?",
      "ERROR: The number must be greater than 0!",
      (value) => value > 0
    ),
  pan: yup
    .string()
    .min(10, "too short")
    .max(10, "too long")
    .required("Pan Card number is required"),
  dob: yup.string().required("Date of Birth is required"),
  name: yup
    .string()
    .label("Customer Name")
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .max(40)
    .required(),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  phone: yup
    .string()
    .required("Phone Number is a required field")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "too short")
    .max(10, "too long"),
});

export const initialValues = {
  amount: "",
  pan: "",
  dob: "",
  name: "",
  email: "",
  phone: "",
};
