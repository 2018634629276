import React from "react";
import { Toolbar, IconButton, Typography } from "@mui/material/";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import { getStoreName } from "../../helpers/common-utils";
import "./style/appHeader.css";

const drawerWidth = 200;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "white",
  boxShadow: "none",
}));

export default function AppHeader({ open, handleDrawerOpen, pageTitle }) {
  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography color="#338F6C" fontWeight="bold" fontSize={"12px"}>
          {pageTitle}
        </Typography>
        <p className="headerTitle">
          {getStoreName() === "undefined" ? "" : `Hello, ${getStoreName()}`}
        </p>
      </Toolbar>
    </AppBar>
  );
}
