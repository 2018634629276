import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    promotionContainer: {
      width: "56%",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        width: "90%",
      },
    },
    templateContainer: {
      width: "70%",
      margin: "0 auto",
      height: "500px",
      overflow: "auto",
      border: "1px solid #ccc",
      borderRadius: "10px",
      backgroundColor: "white",
      [theme.breakpoints.down("md")]: {
        width: "95%",
      },
    },
    fileUploadContainer: {
      backgroundColor: "#ffffff",
      width: "600px",
      margin: "30px auto",
      padding: "20px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    uploadedFilesContainer: {
      width: "41%",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginBottom: "20px",
      },
    },
  })
);

export default useStyles;
