/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import usePaymentsActions from "./store/usePaymentsActions";
import { usePayments as usePaymentsContex } from "./store/contex";
import { paymentStatuses } from "../../constants/payments";
import { useQueryParams } from "../../helpers/common-utils";
import useOnlineHook from "../../custom.hooks/useOnline.hook";
import useAnalytics from "./useAnalytics.hook";

const useCollectPaymentHook = (getPaymentData) => {
  let intervalId;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  const { isOnline, showNetworkMsg } = useOnlineHook();
  const [showVerifyBtn, setShowVerifyBtn] = useState(false);
  const [isVerifying, setIsVerifying] = useState(true);
  const {
    logPopupLoaded,
    logPopupClosed,
    logPaymentSuccess,
    // logPaymentFailed,
  } = useAnalytics();

  useEffect(() => {
    if (open && !isOnline) {
      setShowVerifyBtn(true);
    }
  }, [isOnline]);

  const { verifyPaymentAction, resetPaymentLinkdetails } = usePaymentsActions();
  const {
    state: { verifyPayment },
  } = usePaymentsContex();

  const params = useQueryParams();

  params.cp = window?.location?.pathname === "/collect-payment" ? "1" : "";

  useEffect(() => {
    !isOnline && setIsVerifying(false);
  }, [isOnline]);

  useEffect(() => {
    if (
      isVerifying &&
      verifyPayment?.data?.status !== paymentStatuses.PAYMENT_COMPLETE
    ) {
      // openModal();
      intervalId = setInterval(function () {
        checkPaymentStatus(data?.generatedPaymentId);
      }, 5000);
    }
    return () => clearInterval(intervalId);
  }, [verifyPayment?.data, isVerifying]);

  useEffect(() => {
    if (verifyPayment?.data?.status === paymentStatuses.PAYMENT_COMPLETE) {
      logPaymentSuccess();
      setTimeout(() => {
        getPaymentData && getPaymentData(verifyPayment?.data);
        closeModal();
      }, 5000);
    }
  }, [verifyPayment?.data]);

  const checkPaymentStatus = (generatedPaymentId) => {
    setIsVerifying(true);
    setShowVerifyBtn(false);
    generatedPaymentId && verifyPaymentAction(generatedPaymentId, params);
  };

  const openModal = () => {
    setOpen(true);
    logPopupLoaded();
  };

  const closeModal = () => {
    resetPaymentLinkdetails();
    clearInterval(intervalId);
    setOpen(false);
    setIsVerifying(false);
    logPopupClosed();
  };

  const collectPayment = (payload) => {
    if (payload) {
      setData(payload);
      openModal();
      checkPaymentStatus(payload.generatedPaymentId);
    }
  };

  return {
    open,
    openModal,
    closeModal,
    collectPayment,
    data,
    isOnline,
    showNetworkMsg,
    showVerifyBtn,
    checkPaymentStatus,
  };
};

export default useCollectPaymentHook;
