import { useEffect, useState } from "react";
import Api from "../../../services/apiv2.service";

const useCategoriesAndBrand = () => {
  const [responseData, setResponseData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brandsData, setBrandsData] = useState([]);

  async function callApi() {
    const categoryAndBrandResponse = await Api.getCategoriesAndBrand();
    if (categoryAndBrandResponse.ok) {
      setResponseData(categoryAndBrandResponse.data);
      let formatCategories = (categories) =>
        categories.map((category) => ({
          name: category.name,
          value: category.type,
        }));
      setCategories(formatCategories(categoryAndBrandResponse.data));
    }
  }

  const filterBrandsForCategory = (
    e,
    handleChange,
    category,
    setFieldValue
  ) => {
    let filterCategoryData = responseData.filter(
      (item) => item.type === e.target.value
    );
    if (filterCategoryData.length === 1) {
      setBrandsData(filterCategoryData[0].brands);
    }
    setFieldValue(category, e.target.value);
  };

  useEffect(() => {
    callApi();
  }, []);
  return { responseData, categories, filterBrandsForCategory, brandsData };
};

export default useCategoriesAndBrand;
