import React from "react";
import { Typography } from "@mui/material";
import { Sms, WhatsApp } from "@mui/icons-material";

const ICON = {
  sms: Sms,
  whatsapp: WhatsApp,
};

export default function CampaignHistoryCard({ cardData }) {
  return (
    <div className="campaign_history_card">
      <div className="campaign_history_cardHeader">
        <div>
          <Typography>
            Date :{" "}
            <span className="campaign_details_fontStyle">{cardData.date}</span>
          </Typography>
          <Typography>
            Total Customer :{" "}
            <span className="campaign_details_fontStyle">
              {cardData.total_customers}
            </span>
          </Typography>
        </div>
        <div className="campaign_channel">
          {cardData.channel.map((item) => {
            let Component = ICON[item];
            return (
              <div key={item} className="campaign_channel_icon">
                <Component color="primary" />
              </div>
            );
          })}
        </div>
      </div>
      <div className="campaign_history_contentContainer">
        <h4>{cardData.campaign_name}</h4>
        <p>{cardData.template}</p>
      </div>
    </div>
  );
}
