import {
  Box,
  Button,
  Grid,
  Typography,
  Divider,
  CircularProgress,
  Collapse,
} from "@mui/material";
import React from "react";
import AppDatePicker from "../../components/AppDatePicker";
import Layout from "../../components/Layout";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import useBulkDownload from "./useBulkDownload";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import BulkDownloadDesktop from "./BulkDownloadDesktop";
import BulkDownloadMobile from "./BulkDownloadMobile";
import useFormStatus from "./useFormStatus";

const headerTitles = ["Sl.No", "Date Range", "Status", "Download Link"];

export default function BulkDownload() {
  const {
    loading,
    bulkDowloadData,
    setEndDate,
    setStartDate,
    startDate,
    endDate,
    createBulkDownloadRequest,
    disableDownloadBtn,
  } = useBulkDownload();
  const { handleFormStatus, formStatus } = useFormStatus();

  return (
    <Layout pageTitle="Historic Invoice">
      <Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Historic Invoice</Typography>
          {isMobile && (
            <Typography color="primary">
              <FilterAltOutlinedIcon onClick={handleFormStatus} />
            </Typography>
          )}
        </Box>
        <Divider style={{ margin: "10px" }} />
        <Collapse in={formStatus}>
          <Box style={{ width: "100%", margin: "0 auto" }}>
            <Grid container>
              <Grid item xs={12} md={4} style={{ margin: "10px" }}>
                <AppDatePicker
                  label="From Date"
                  value={startDate}
                  onChange={(e) => setStartDate(e)}
                  maxDate={new Date()}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4} style={{ margin: "10px" }}>
                <AppDatePicker
                  label="Till Date"
                  value={endDate}
                  onChange={(e) => setEndDate(e)}
                  maxDate={new Date()}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                style={{ textAlign: "center", margin: "11px" }}
              >
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={createBulkDownloadRequest}
                  disabled={disableDownloadBtn}
                >
                  Create Download Request
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Divider style={{ margin: "10px" }} />
        </Collapse>

        <Box style={{ marginTop: "15px" }}>
          {loading ? (
            <Box style={{ textAlign: "center" }}>
              <CircularProgress color="success" />
            </Box>
          ) : (
            <>
              <BrowserView>
                <BulkDownloadDesktop
                  bulkDowloadData={bulkDowloadData}
                  headerTitles={headerTitles}
                />
              </BrowserView>
              <MobileView>
                <BulkDownloadMobile
                  bulkDowloadData={bulkDowloadData}
                  headerTitles={headerTitles}
                />
              </MobileView>
            </>
          )}
        </Box>
      </Box>
    </Layout>
  );
}
