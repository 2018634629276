import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import {
  Box,
  Button,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material"
import { useTheme, withStyles } from "@mui/styles"
import React, { useEffect, useState } from "react"
import AppTags from "../AppTags"
import RetailerDetails from "../RetailerDetails"

const CustomTableCell = withStyles(() => ({
  root: {
    border: "none",
    padding: "10px !important",
    minWidth: 100,
    fontSize: "1rem !important",
    lineHeight: "1.5rem !important",
    fontWeight: "500 !important",
    maxWidth: 200,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    textAlign: "center !important",
  },
}))(TableCell)

const RetailerCard = ({ page, details, allCategories }) => {
  const {
    storeId,
    storeName,
    line_1,
    city,
    state,
    pincode,
    services,
    categoryName,
    is_active,
  } = details
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const theme = useTheme()

  useEffect(() => {
    if (page) {
      setOpen(false)
      setEdit(false)
    }
  }, [page])

  const handleEdit = () => {
    setEdit(true)
    setOpen(true)
  }

  const onEditOffer = () => {
    // TODO: handle API call
  }

  return (
    <>
      <TableRow height="50">
        <CustomTableCell style={{ minWidth: 10 }}>
          <IconButton onClick={() => setOpen(!open)} style={{ color: "black" }}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </CustomTableCell>
        <CustomTableCell style={{ textAlign: "left" }}>
          {storeId}
        </CustomTableCell>
        <CustomTableCell style={{ minWidth: 110 }}>
          {storeName || "-"}
        </CustomTableCell>
        <CustomTableCell style={{ minWidth: 140 }}>
          {line_1 || "-"}
        </CustomTableCell>
        <CustomTableCell>{city || "-"}</CustomTableCell>
        <CustomTableCell>{state || "-"}</CustomTableCell>
        <CustomTableCell>{pincode || "-"}</CustomTableCell>
        <CustomTableCell style={{ maxWidth: 300 }}>
          <AppTags
            tags={services}
            style={{ justifyContent: "center" }}
            onShowMore={() => setOpen(true)}
          />
        </CustomTableCell>
        <CustomTableCell style={{ maxWidth: 300 }}>
          <AppTags
            tags={categoryName}
            style={{ justifyContent: "center" }}
            onShowMore={() => setOpen(true)}
          />
        </CustomTableCell>
        <CustomTableCell
          style={{
            fontWeight: "bold",
            color: is_active && theme.palette.primary.main,
          }}
        >
          {is_active ? "Active" : "InActive"}
        </CustomTableCell>
        <CustomTableCell style={{ minWidth: 10 }}>
          <Button disabled={edit} variant="contained" onClick={handleEdit}>
            Edit
          </Button>
        </CustomTableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={12} style={{ padding: 0 }}>
          <Collapse in={open} timeout="auto">
            <Box borderTop="1px dashed rgba(112, 112, 112, 0.37)">
              <RetailerDetails
                details={details}
                editable={edit}
                onSubmit={onEditOffer}
                onCancel={() => setEdit(false)}
                allCategories={allCategories}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default RetailerCard
