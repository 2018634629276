import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";

export default function ModalContentORLoader({
  open,
  title,
  onClose,
  onContinue,
  msg,
  hideClose = false,
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown
    >
      <Box className="modal_style" container>
        <div className="modal_title">
          <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
            {title}
          </Typography>
        </div>
        <div className="modal_body">
          <Typography id="modal-modal-description">
            {msg ? msg : <CircularProgress color="success" />}
          </Typography>
        </div>
        {!hideClose && (
          <div className="modal_footer">
            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button
                variant={"contained"}
                color="inherit"
                onClick={onClose}
                style={
                  onContinue
                    ? {
                        marginRight: "8px",
                        background: "#7E7E7E 0% 0% no-repeat padding-box",
                        fontSize: "10px",
                        color: "#FFFFFF",
                        borderRadius: "8px",
                      }
                    : {}
                }
              >
                Close
              </Button>
              {onContinue && (
                <Button
                  variant={"outlined"}
                  onClick={onContinue}
                  style={{
                    background: "#4E8C6E 0% 0% no-repeat padding-box",
                    fontSize: "10px",
                    color: "#FFFFFF",
                    borderRadius: "8px",
                  }}
                >
                  Send Message
                </Button>
              )}
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
}
