import { Autocomplete, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

export default function GoogleAutoComplete({ setSelected }) {
  const {
    ready,
    value,
    setValue,
    suggestions: { data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    cache: 24 * 60 * 60,
  });

  const handleSelect = async (event, value) => {
    if (value) {
      setValue(value?.description, false);
      clearSuggestions();

      const results = await getGeocode({ address: value?.description });
      const result = await getLatLng(results[0]);
      const { lat, lng } = result;
      setSelected({ lat, lng });
    }
  };

  return (
    <Autocomplete
      id="google-autocomplete"
      options={data}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
          key={option.place_id}
        >
          {option.description}
        </Box>
      )}
      onChange={handleSelect}
      getOptionLabel={(option) => option.description || ""}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Please enter your location"
          value={value}
          disabled={!ready}
          onChange={(e) => setValue(e.target.value)}
        />
      )}
    />
  );
}
