import moment from "moment";
import { Installation_Status } from "../config";
export const COMMON_OFFSET = 15;
export const INPUT_DATE_FORMAT = "dd/MM/yyyy";
export const MIN_DATE = "01-01-2000";

const Transaction_History_Header = [
  "Payment ID",
  "Payment Date",
  "Status",
  "Customer Mobile",
  "Category",
  "Total Amount",
  "Payment Type",
  "Payment Mode",
  "Invoice",
  "Installation",
  "Settlement Amount",
  "Settlement Date",
];

export const TRANSACTION_HISTORY_HEADER_TITLES = Installation_Status
  ? Transaction_History_Header
  : Transaction_History_Header.filter((header) => header !== "Installation");

export const getHeaderList = (status) => {
  const headerList = status
    ? Transaction_History_Header
    : Transaction_History_Header.filter((header) => header !== "Installation");
  return headerList;
};

export const TRANSACTION_HISTORY_HEADER_TITLES_ADMIN = [
  "Payment ID",
  "Payment Date",
  "Status",
  "Store Name",
  "Payment Reference ID",
  "Retailer Name",
  "Customer Mobile",
  "Category",
  "Total Amount",
  "Payment Type",
  "Payment Mode",
  "Invoice",
  "Settlement Amount",
  "Settlement Date",
  "",
];

export const TRANSACTION_HISTORY_STATUS = [
  { value: "PAYMENT_COMPLETED", label: "Completed" },
  // { value: "SETTLEMENT_INITIATED", label: "Settlement Initiated" },
  { value: "SETTLEMENT_COMPLETED", label: "Settled" },
  { value: "INVOICE_UPLOADED", label: "Invoice Generated" },
];

export const transactionOrderStatusClasses = (status) => {
  if (status === "PAYMENT_COMPLETED") {
    return "orderStatusSuccess";
  } else if (status === "SETTLEMENT_COMPLETED") {
    return "orderStatusSecondary";
  } else if (status === "INVOICE_UPLOADED") {
    return "orderStatusUpload";
  }
};

export const OFFERS_HEADER_TITLES = [
  "Offers",
  "Start Date",
  "End Date",
  "Active/In-Active",
];

export const RETAILER_HEADER_TITLES = [
  "Store ID",
  "Store Name",
  "Address Line 1",
  "City",
  "State",
  "Pin code",
  "Services",
  "Categories",
  "Active",
];

export const RETAILER_DUMMY = {
  storeId: "11010101",
  storeName: "Store dummy",
  line_1: "N-63, new street, lal bunglow",
  locality: "",
  city: "surat",
  state: "gujrat",
  pincode: "395007",
  services: ["serve 1", "service 2", "service 3", "service 4", "service 5"],
  categoryName: ["Categories 1", "Categories 2", "Categories 3"],
  is_active: true,
};

export const getTransactionStatus = (status) => {
  if (status === "INVOICE_UPLOADED") {
    return "Invoice Generated";
  }
  const statusText = TRANSACTION_HISTORY_STATUS.find((s) => s.value === status);
  return statusText ? statusText.label : "-";
};

export const formatCurrency = (amount) => {
  if (!amount) {
    return "-";
  }

  return amount;
};

export const formatDate = (date) =>
  date && moment(date).isValid()
    ? moment(date).local().format("DD-MMM-YYYY hh:mm A")
    : "-";

export const getValidDate = (date) =>
  date && moment(date).isValid() ? date : undefined;

export const formatPaymentMode = (mode = "") =>
  mode.replace(/_/g, " ").split(" ").join(" ").toUpperCase();

export const updateStringWithoutSplitter = (str) => str.replace(/,/g, "");

// const storeNameDestructure = (settlementData) => {
//   const filterStorename = settlementData.map((settlement) => settlement.storeName);
//   return filterStorename.toString();
// };

export const formatCSV = (data, adminUser) => {
  let csvData = "";
  const headers = [
    "Payment ID",
    "Payment Date",
    "Status",
    "Store Name",
    "RazorPay ID",
    "Shopse ID",
    "Customer Mobile",
    "Category",
    "Model",
    "Payment Mode",
    "Gateway",
    "Payment Reference No.",
    "Order Amount",
    "Discount",
    "MDR",
    "GST",
    "Settlement Amount",
    "Settlement Date",
  ];
  csvData = `${headers.join(",")}\n`;
  csvData += data
    .map((order) =>
      headers
        .map((t) => {
          switch (t) {
            case "Payment ID":
              return order.orderId || "-";
            case "Payment Date":
              return moment(order.paymentDate).format("DD-MMM-YYYY hh:mm A");
            case "Status":
              return getTransactionStatus(order.orderStatus);
            case "Store Name":
              return (order.storeName && order.storeName) || "-";
            case "RazorPay ID":
              return order.razorpay_id || "_";
            case "Shopse ID":
              return order.shopse_id || "_";
            case "Customer Mobile":
              return order.customerMobile || "-";
            case "Category":
              return order.allCategories || "-";
            case "Model":
              return (
                (order.model_name &&
                  updateStringWithoutSplitter(order.model_name)) ||
                "-"
              );
            case "Payment Mode":
              return formatPaymentMode(order.paid_through);
            case "Gateway":
              return order.mode_of_payment || "-";
            case "Payment Reference No.":
              return order.paymentRefrence || "-"; // TODO: check with API
            case "Order Amount":
              return order.totalOrderAmount
                ? `${!adminUser ? "Rs." : ""} ${order.totalOrderAmount}`
                : "-";
            case "Discount":
              return order.discount_amount
                ? `${!adminUser ? "Rs." : ""} ${order.discount_amount}`
                : "-";
            case "MDR":
              return order.mdr_amount
                ? `${!adminUser ? "Rs. " : ""} ${order.mdr_amount}`
                : "-";
            case "GST": {
              const percentage_amount =
                order.gst_amount && !adminUser
                  ? `(${order.gstPercentage} %)`
                  : "";

              return order.gst_amount
                ? `${!adminUser ? "Rs. " : ""} ${
                    order.gst_amount
                  } ${percentage_amount}`
                : "-";
            }
            case "Settlement Amount":
              return order.settlementAmount
                ? `${!adminUser ? "Rs." : ""} ${order.settlementAmount}`
                : "-";
            case "Settlement Date":
              // return formatDate(order.settlementDate);
              return formatDate(`${order.settlementDate}Z`);
            default:
              return "";
          }
        })
        .join(",")
    )
    .join("\n");
  return csvData;
};

export const DEFAULT_TEXT =
  "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsu m dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet";

export const wp_CONTENT_CATEGORY_IDS = {
  OFFERS: 5,
  HELP: 7,
  TRAINING: 8,
};

// to format the currency
export const amonutFormatter = (number) => {
  if (number > 10000000) {
    return (number / 10000000).toString() + "Cr";
  } else if (number > 100000) {
    return (number / 100000).toString() + "L";
  } else if (number > 1000) {
    return (number / 1000).toString() + "K";
  } else {
    return number.toString();
  }
};
const defaultCurrency = "inr";
export const dataFormatter = (value = 0, format) => {
  switch (format) {
    case "currency":
      return Intl.NumberFormat("en-IN", {
        currency: defaultCurrency,
        minimumFractionDigits: 2,
        style: "currency",
      }).format(value);
    case "date-short":
      return moment(value).format("MMM D");
    case "date-full":
      return moment(value).format("MMMM DD YYYY");
    case "amount-short":
      return amonutFormatter(value);
    case "number":
      return Intl.NumberFormat("en-IN", {
        minimumFractionDigits: 0,
      }).format(value);
    default:
      return value;
  }
};
