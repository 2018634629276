import { useState } from "react";
import Api from "../../src/services/apiv2.service";

const useCreateInstallation = (refetchOrderHistory) => {
  const [createInstallationLoading, setCreateInstallationLoading] =
    useState(false);
  const createPostInstallation = async (requestData) => {
    setCreateInstallationLoading(true);
    let createInstallation_response;
    createInstallation_response = await Api.createInstallation(requestData);
    if (createInstallation_response.data.status === "SUCCESS") {
      setCreateInstallationLoading(false);
      refetchOrderHistory();
      return createInstallation_response;
    } else {
      setCreateInstallationLoading(false);
    }
  };

  return { createPostInstallation, createInstallationLoading };
};

export default useCreateInstallation;
