import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  "@global": {
    ".MuiDrawer-paper": {
      borderRight: "none",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#338F6C",
        },
      },
    },
  },
  palette: {
    primary: {
      light: "#122E01",
      main: "#338F6C",
      dark: "#527466",
      contrastText: "#000",
    },
    secondary: {
      main: "#19857b",
    },
    color: {
      white: "#fff",
      bg: "#F7F7F7",
    },
    error: {
      main: red.A400,
    },
    border: {
      light: "rgba(112, 112, 112, 0.37)",
      extraLight: "#EEEEEE",
    },
    text: {
      dark: "#707070",
      main: "#353535",
      light: "rgba(0, 0, 0, 0.7)",
      extraLight: "#838383",
    },
    background: {
      dark: "#F3F3F3",
    },
  },
  spacing: 6,
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

export default theme;
