import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import AppAlert from "../../components/AppAlert";
import AppDialog from "../../components/AppDialog";
import AppNoData from "../../components/AppNoData";
import AppPagination from "../../components/AppPagination";
import AppSearchInput from "../../components/AppSearchInput";
import Layout from "../../components/Layout";
import RetailerCard from "../../components/RetailerCard";
import RetailerDetails from "../../components/RetailerDetails";
import { RETAILER_HEADER_TITLES } from "../../helpers/constants";
import useRetailers from "./useRetailers.hook";
import StyledTableCell from "../../helpers/StyledTableCell";

const Retailers = () => {
  const {
    tableRef,
    retailers,
    totalCount,
    itemsPerRow,
    loading,
    page,
    setPage,
    error,
    setError,
    filters,
    handleFilterChange,
    open,
    setOpen,
    allCategories,
  } = useRetailers();

  return (
    <Layout pageTitle={"Retailer"}>
      <Box position="relative">
        <Grid container mb={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppSearchInput
              placeholder="Search Retailer"
              value={filters.queryString}
              onChange={(val) =>
                handleFilterChange("queryString", val || undefined)
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={9}
            display="flex"
            justifyContent="flex-end"
          >
            <Button variant="outlined" onClick={() => setOpen(true)}>
              Add New Retailer
            </Button>
          </Grid>
        </Grid>
        {loading ? (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <Card elevation={0}>
            <TableContainer
              ref={tableRef}
              style={{ maxHeight: "calc(100vh - 215px)" }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead className="Header" height="50">
                  <TableRow>
                    <StyledTableCell width="50" />
                    {RETAILER_HEADER_TITLES.map((title, i) => (
                      <StyledTableCell
                        key={i}
                        align={i === 0 ? "left" : "center"}
                        style={{ padding: 10 }}
                      >
                        <Typography color="white" fontWeight="bold">
                          {title}
                        </Typography>
                      </StyledTableCell>
                    ))}
                    <StyledTableCell width="200" />
                  </TableRow>
                </TableHead>
                <TableBody className="Body">
                  {(retailers.length &&
                    retailers.map((retailer, i) => (
                      <RetailerCard
                        key={i}
                        page={page}
                        details={retailer}
                        allCategories={allCategories}
                      />
                    ))) || (
                    <TableRow>
                      <StyledTableCell
                        colSpan={RETAILER_HEADER_TITLES.length}
                        style={{ textAlign: "center" }}
                      >
                        <AppNoData />
                      </StyledTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <AppPagination
              name="Retailers List Page"
              page={page}
              setPage={setPage}
              totalCount={totalCount}
              itemsPerRow={itemsPerRow}
            />
          </Card>
        )}
        <AppDialog
          title="Add Retailer"
          open={open}
          handleClose={() => setOpen(false)}
        >
          <RetailerDetails editable onCancel={() => setOpen(false)} />
        </AppDialog>
        <AppAlert error={error} close={() => setError()} />
      </Box>
    </Layout>
  );
};

export default Retailers;
