import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Badge, Box } from "@mui/material";
import useStyles from "./style";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import useAnalytics from "./useAnalytics.hook";
import { event, OfferPublisher } from "../../services/pub-sub";

export default function SidebarWithDropDown({
  Icon,
  route,
  isActive,
  badgeCount = false,
}) {
  const classes = useStyles();
  const location = useLocation();
  const [OfferCount, setOfferCount] = useState({});
  const [subnav, setSubnav] = useState(
    location.pathname.includes(route.name.toLocaleLowerCase())
  );
  const { logMenuClick } = useAnalytics();
  const showSubnav = () => setSubnav(!subnav);

  useEffect(() => {
    event.on("offerCount", (val) => {
      setOfferCount(val);
    });
  }, []);

  const handleRouteClick = (route) => {
    if (route.name === "Offers") {
      OfferPublisher.clearAllMessage();
    }

    logMenuClick(route.name, "menu");
    route?.children && showSubnav();
  };

  return (
    <>
      <Link
        to={route?.path ? route?.path : "#"}
        key={route.name}
        target={route.target}
        onClick={() => handleRouteClick(route)}
      >
        <Box
          className={clsx(
            classes.menuItem,
            isActive && classes.activeMenu,
            isActive && "active_menu",
            subnav && classes.activeMenu,
            subnav && "active_menu",
            route.name === "Logout" ? classes.logout_position : ""
          )}
        >
          <div className="menu_container">
            <span className="menu_icon">
              <Badge
                sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    backgroundColor: "red",
                  },
                }}
                badgeContent={
                  badgeCount ? OfferCount[route.name.toUpperCase()] : null
                }
              >
                <Icon />
              </Badge>
            </span>
            <span className="menu_title">{route.name}</span>
          </div>
        </Box>
      </Link>
      <div className={classes.subMenuContainer}>
        {subnav &&
          route.children?.map((item, index) => {
            return (
              <Link
                to={item.path}
                key={index}
                onClick={() => logMenuClick(item.name, "submenu")}
              >
                <Box className={clsx(classes.menuItem, classes.subMenuItem)}>
                  <div
                    className={clsx(
                      location.pathname === item.path ? classes.subnav : "",
                      "menu_container"
                    )}
                  >
                    <span
                      className={clsx(
                        classes.menuSubTitle,
                        location.pathname === item.path
                          ? classes.subMenuActive
                          : ""
                      )}
                    >
                      {item.name}
                    </span>
                  </div>
                </Box>
              </Link>
            );
          })}
      </div>
    </>
  );
}
