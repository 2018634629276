import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      //   width: 400,
      backgroundColor: "white",
      //   border: "2px solid #000",
      boxShadow: 24,
      borderRadius: "25px",
      overflow: "hidden",
      [theme.breakpoints.down("md")]: {
        borderRadius: "10px",
      },
      maxWidth: "90%",
      maxHeight: "90%",
    },
    headerContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "15px 25px",
      borderBottom: "1px solid #ccc",
      backgroundColor: "#F7F7F7",
      [theme.breakpoints.down("md")]: {
        padding: "5px 25px",
      },
    },
    header: {
      fontSize: "20px",
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
    },
    content: {
      //   padding: "25px",
      maxHeight: "80vh",
      overflow: "auto",
    },
  })
);

export default useStyles;
