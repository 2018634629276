import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  filterContainer: {
    position: "relative",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    borderBottom: `1px solid ${theme.palette.border.extraLight}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      display: "block",
    },
  },
  categoryContainer: {
    width: "70px",
    textAlign: "center",
    borderRadius: "50%",
  },
  topContainer: {
    backgroundColor: "#F6F9F7",
    alignItems: "center",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  transactionTitle: {
    fontSize: "11px",
    color: "#000000",
    padding: "6px",
    borderBottom: "1px solid #a7a8ab",
  },
  headerTitle: {
    fontSize: "11px",
    color: "#000000",
    padding: "1px",
  },
  transactionData: {
    fontSize: "11px !important",
    color: "#527466",
    fontWeight: "bold",
  },
  categoryIcon: {
    width: "50%",
  },
  orderStatusSuccess: {
    backgroundColor: "#DEF6D8",
    borderRadius: "10px",
    padding: "2px",
    color: "#4AD31B",
  },

  orderStatusSecondary: {
    backgroundColor: "#F7F3D9",
    borderRadius: "10px",
    padding: "2px",
    color: "#DDAE26 ",
  },
  orderStatusUpload: {
    backgroundColor: "#ddeafe",
    borderRadius: "10px",
    padding: "2px",
    color: "#2081e0",
  },
  orderStatus: {},
  transactionContainer: {},
  innerFilters: {
    padding: "0 10px",
    width: 180,
    [theme.breakpoints.down("md")]: {
      padding: "10px 0",
      width: "100%",
    },
  },

  searchContainer: {
    position: "relative",
    display: "flex",
    flex: 0.35,
    [theme.breakpoints.down("md")]: {
      flex: 0.65,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
  },
  iconButton: {
    borderTop: "1px solid rgba(0, 0, 0, 0.23)",
    borderRight: "1px solid rgba(0, 0, 0, 0.23)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "0px",
    padding: "4px",
    verticalAlign: "bottom",
  },
  center: {
    textAlign: "center",
  },
}));

export default useStyle;
