import React from "react";
import {
  TextField,
  Box,
  Modal,
  Typography,
  Button,
  FormControl,
  Stack,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Autocomplete,
} from "@mui/material";
import Loader from "../../../../components/Loader";
import useCategories from "./useCategories";
import { useFormik } from "formik";
import Api from "../../../../services/apiv2.service";
import "./installation_modal.scss";
import { toast } from "react-toastify";
import { validationSchema, initialValues, DATE_FORMAT } from "../../utils";
import { style } from "./style";

import { createFilterOptions } from "@mui/material/Autocomplete";
import moment from "moment";

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option,
});

export default function RequestInstallationModal({
  open,
  handleClose,
  refetchData,
}) {
  const {
    categoriesData,
    loading,
    setLoading,
    brandData,
    subcategoriesData,
    handlePincode,
    resetForm,
    handleSelect,
  } = useCategories(open, handleClose);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      // to set the invoice date and installation date by default
      values.invoiceDate = moment().format(DATE_FORMAT); // by default setting invoice date today
      values.mobile = values.mobile.toString();
      // values.appointmentDate = moment().add(1, "d").format(DATE_FORMAT); // by default setting installation date for tomorrow
      let res = await Api.postInstallationCreate(values);
      if (res.data.status === "SUCCESS") {
        toast.success(res.data.message);
        formik.resetForm();
        handleClose();
        refetchData();
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Request Failed");
      }
    },
  });

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              className="request-header"
              variant="h6"
              component="h2"
            >
              Installation Request
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {loading ? <Loader /> : null}
              <Grid
                container
                spacing={1}
                columnSpacing={2}
                className="margin-space"
              >
                <Grid item xs={12} md={6} container spacing={1} rowSpacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      id="name"
                      label="Customer Name*"
                      variant="outlined"
                      size="small"
                      fullWidth
                      margin="dense"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="mobile"
                      label="Phone No.*"
                      variant="outlined"
                      size="small"
                      type="number"
                      fullWidth
                      margin="dense"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.mobile && Boolean(formik.errors.mobile)
                      }
                      helperText={formik.touched.mobile && formik.errors.mobile}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    className="text-area"
                    id="address1"
                    label="Address*"
                    multiline
                    rows={3}
                    margin="dense"
                    value={formik.values.address1}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.address1 && Boolean(formik.errors.address1)
                    }
                    helperText={
                      formik.touched.address1 && formik.errors.address1
                    }
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="pincode"
                    label="Pincode*"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    inputProps={{ maxLength: 6, minLength: 6 }}
                    margin="dense"
                    value={formik.values.pincode}
                    onChange={(e) => handlePincode(formik, e.target.value)}
                    error={
                      formik.touched.pincode && Boolean(formik.errors.pincode)
                    }
                    helperText={formik.touched.pincode && formik.errors.pincode}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small" margin="dense">
                    <Autocomplete
                      size="small"
                      id="brand"
                      value={formik.values.brand}
                      filterOptions={filterOptions}
                      onChange={(e, selectedValue) =>
                        handleSelect(formik, "brand", e, selectedValue)
                      }
                      freeSolo
                      options={brandData.map((option) => option)}
                      renderInput={(params) => (
                        <TextField
                          value={formik.values.brand}
                          {...params}
                          label="Brand*"
                          error={
                            formik.touched.brand && Boolean(formik.errors.brand)
                          }
                          helperText={
                            formik.touched.brand && formik.errors.brand
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small" margin="dense">
                    <InputLabel id="Categories">Categories*</InputLabel>
                    <Select
                      labelId="Categories"
                      id="Categories"
                      label="Category*"
                      onChange={(e) => handleSelect(formik, "category", e)}
                      value={formik.values.category}
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.category)
                      }
                      helperText={
                        formik.touched.category && formik.errors.category
                      }
                      disabled={categoriesData?.length === 0}
                    >
                      {Object.keys(categoriesData)?.length > 0 &&
                        Object.keys(categoriesData).map((category) => {
                          return (
                            <MenuItem value={category} key={category}>
                              {category}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small" margin="dense">
                    <InputLabel id="subcategory">Sub Category*</InputLabel>
                    <Select
                      labelId="subcategory"
                      id="subcategory"
                      label="Sub Category*"
                      onChange={(e) => handleSelect(formik, "subcategory", e)}
                      value={formik.values.subcategory}
                      error={
                        formik.touched.subcategory &&
                        Boolean(formik.errors.subcategory)
                      }
                      helperText={
                        formik.touched.subcategory && formik.errors.subcategory
                      }
                      disabled={subcategoriesData?.length === 0}
                    >
                      {subcategoriesData?.length > 0 &&
                        subcategoriesData.map((data) => {
                          return (
                            <MenuItem value={data} key={data}>
                              {data}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Stack
                direction="row"
                justifyContent="end"
                sx={{ borderTop: "1px solid #ccc", mt: 3 }}
              >
                <Box mt={2}>
                  <Button
                    sx={{ mr: 2 }}
                    variant="outlined"
                    onClick={() => resetForm(formik)}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{ ml: 2 }}
                    variant="contained"
                    type="submit"
                    // disabled={!formik.isValid}
                  >
                    Submit
                  </Button>
                </Box>
              </Stack>
            </Typography>
          </Box>
        </form>
      </Modal>
    </>
  );
}
