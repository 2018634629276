import React from "react";
import ImagesView from "./ImagesView";

const LoadAllImages = ({ images, handleImageClick, activeTab }) => {
  return images?.map((image, inx) => {
    return (
      <ImagesView
        data={image}
        handleImageClick={handleImageClick}
        key={inx}
        activeTab={activeTab}
      />
    );
  });
};

export default LoadAllImages;
