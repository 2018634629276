import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useField } from "formik";
import "./style.scss";

const CustomSelect = ({
  id,
  label,
  placeholder,
  options,
  disabled,
  ...props
}) => {
  const [field, meta] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FormControl
      className="custom-select-container"
      size="small"
      fullWidth
      error={hasError}
    >
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        id={id}
        className="custom-select"
        label={label}
        placeholder={placeholder}
        size="small"
        error={hasError}
        disabled={disabled}
        {...field}
      >
        <MenuItem className="option" value={""}>
          Select
        </MenuItem>
        {options?.map((option, index) => (
          <MenuItem
            className="option"
            key={`custom-select-options-${index}-${option.value}`}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {hasError && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};

export default CustomSelect;
