import { useState } from "react";
import { isMobile } from "react-device-detect";

const useFilterStatus = () => {
  const [filterStatus, setFilterStatus] = useState(isMobile ? false : true);
  const handleFilterStatus = () => {
    setFilterStatus(!filterStatus);
  };
  return { filterStatus, handleFilterStatus };
};

export default useFilterStatus;
