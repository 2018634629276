const categoryMappingKeyValue = {
  TV1: "Television",
  TV2: "Television",
  WASHING_MACHINE: "Washing Machine",
  AIR_COOLER: "",
  AC: "Air Conditioner",
  REFRIGERATOR: "Refrigerator",
};

const brandMappingKeyValue = {
  SAMSUNG: "Samsung",
  HAIER: "Haier",
  GODREJ: "Godrej",
  WHIRLPOOL: "Whirlpool",
  VOLTAS_BEKO: "VoltasBeko",
  VOLTAS: "Voltas",
  KENT: "Kent",
  IFB: "IFB",
  LG: "LG",
};

function categoryMapping(type) {
  if (categoryMappingKeyValue[type]) {
    return categoryMappingKeyValue[type];
  } else {
    return false;
  }
}

function brandMapping(type) {
  if (brandMappingKeyValue[type]) {
    return brandMappingKeyValue[type];
  } else {
    return false;
  }
}

export { categoryMapping, brandMapping };
