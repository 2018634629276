/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Layout from "../../components/Layout";
import ChannelAndCampaign from "./ChannelAndCampaign";
import UploadData from "./UploadData";
import WhatsappView from "./whatsappView";
import PreviewImage from "./PreviewImage";
import "./css/style.css";
import { Box } from "@mui/material";
import useAnalytics from "./useAnalytics.createCampaign.hook";

export default function Promotion() {
  let { path, url } = useRouteMatch();
  const [selectedCampaignName, setSelectedCampaignName] = useState({});
  const [retailerInfo, setRetailerInfo] = useState(null);
  const [previewDataOnUpload, setPreviewDataOnUpload] = useState({});
  const { logCreateCampaignPageLoaded } = useAnalytics();

  useEffect(() => {
    logCreateCampaignPageLoaded();
  }, []);

  return (
    <Layout pageTitle={"Promotion"}>
      <Switch>
        <Route exact path={path}>
          <ChannelAndCampaign
            setSelectedCampaignName={setSelectedCampaignName}
            setRetailerInfo={setRetailerInfo}
          />
        </Route>
        <Route
          exact
          path={`${path}/:channel_name/:template_id`}
          render={
            (props) =>
              // {
              props?.location?.pathname.indexOf("whatsapp") > -1 ? (
                <WhatsappView
                  retailerInfo={retailerInfo}
                  campaignName={selectedCampaignName}
                  setPreviewDataOnUpload={setPreviewDataOnUpload}
                  {...props}
                />
              ) : (
                <UploadData
                  retailerInfo={retailerInfo}
                  campaignName={selectedCampaignName.templateName}
                  {...props}
                />
              )
            // }
          }
        />
        <Route
          path={`${url}/:upload`}
          render={(props) => (
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={0}>
              <Box gridColumn="span 9" className="whatsapp-upload-data-page">
                <UploadData
                  retailerInfo={retailerInfo}
                  campaignName={selectedCampaignName.templateName}
                  whatsappData={previewDataOnUpload.template}
                  whatsappImage={previewDataOnUpload.previewImage}
                  {...props}
                />
              </Box>
              <Box gridColumn="span 3" className="whatsapp-preview-image-page">
                {previewDataOnUpload?.template && (
                  <PreviewImage
                    previewImage={previewDataOnUpload.previewImage}
                    data={previewDataOnUpload.template}
                  />
                )}
              </Box>
            </Box>
          )}
        />
      </Switch>
    </Layout>
  );
}
