import React from "react";
import { Grid } from "@mui/material";
import LoginBanner from "./LoginBanner";
import LoginSystem from "./LoginSystem";
import { Helmet } from "react-helmet";
import ProPromo from "./ProPromo";

export default function SignIn() {
  const showAppPromo = true;
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GoRetail - Login</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {showAppPromo ? (
        <ProPromo />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <LoginBanner />
          </Grid>
          <Grid item xs={12} md={6}>
            <LoginSystem />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
