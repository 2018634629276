import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CircularProgress, Box } from "@mui/material";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import useHelpCard from "./useHelpCard";
import createMarkup from "../../../../helpers/createMarkup";
import DetailsModal from "../../../../components/DetailsModal";

const HelpCard = ({ post }) => {
  const { imageURL, loading } = useHelpCard(post.featured_media);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const showFullText = false;

  return (
    <>
      <Card sx={{ maxWidth: "100%" }}>
        {loading ? (
          <Box style={{ textAlign: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <div className="img-div">
            <CardMedia
              component="img"
              height="140"
              image={imageURL?.guid?.rendered}
              alt="green iguana"
            />
            <FitScreenIcon
              className="hidden_img"
              onClick={() => setOpen(!open)}
            />
          </div>
        )}
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            style={{ marginLeft: "40px" }}
          >
            {post.title.rendered}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <span
              className="wordpress-content"
              dangerouslySetInnerHTML={createMarkup(
                !showFullText ? post.excerpt.rendered : post.content.rendered
              )}
            />
          </Typography>
        </CardContent>
      </Card>
      <DetailsModal
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        title={post?.title?.rendered}
        content={post?.content?.rendered}
      />
    </>
  );
};

export default HelpCard;
