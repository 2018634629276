import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker.hook";

const useAnalytics = () => {
  const { logEvent } = useAnalyticsEventTracker(
    "CRM - Create Campaign Whatsapp"
  );

  const logCreateCampaignPageLoaded = () => {
    logEvent("CRM_UPLOAD_CUSTOMER_DETAILS_IN_SMS_PAGE_LANDING", "Page Landing");
  };

  const logCreateWhatsappPageLoaded = () => {
    logEvent("EDIT_TEMPLATE_PAGE_LANDING_WHATSAPP", "Page Landing");
  };

  const logChannelClick = (channel = "") => {
    logEvent(`SELECT_CHANNEL_${channel.toLocaleUpperCase()}`, `${channel} CTA`);
  };

  const logCampaignSelection = (campaign = "", channel = "") => {
    logEvent(
      `${campaign.split(" ").join("_").toLocaleUpperCase()}_${channel
        .split(" ")
        .join("_")
        .toLocaleUpperCase()}`,
      `Choose ${campaign} - ${channel} - CTA`
    );
  };

  const logContinueBtnClick = (channel = "") => {
    logEvent(
      `CONTINUE_IN_CREATE_CAMPAIGN_${channel
        .split(" ")
        .join("_")
        .toLocaleUpperCase()}`,
      `Continue CTA - ${channel}`
    );
  };

  const logSendAllCustomersBtnClick = (channel = "") => {
    logEvent(
      `SEND_MESSAGE_IN_UPLOAD_CUSTOMER_DETAILS_FOR_${channel
        .split(" ")
        .join("_")
        .toLocaleUpperCase()}`,
      `send message - ${channel}`
    );
  };

  const logDragAndDropClicked = (channel = "") => {
    logEvent(
      `DRAG_AND_DROP_FILE_IN_${channel
        .split(" ")
        .join("_")
        .toLocaleUpperCase()}`,
      `Drag and drop - ${channel}`
    );
  };

  const logFileUploadClicked = (channel = "") => {
    logEvent(
      `ADD_FILE_IN_${channel.split(" ").join("_").toLocaleUpperCase()}`,
      `Add excel/csv - ${channel}`
    );
  };

  const logDownloadSampleBtnClicked = (channel_name = "") => {
    logEvent(
      `DOWNLOAD_SAMPLE_FILE_FOR_${channel_name
        .split(" ")
        .join("_")
        .toLocaleUpperCase()}`,
      `download sample file - ${channel_name}`
    );
  };

  const logBackBtnClick = (channel_name = "") => {
    logEvent(
      `BACK_BUTTON_IN_UPLOAD_CUSTOMER_DETAILS_FOR_${channel_name
        .split(" ")
        .join("_")
        .toLocaleUpperCase()}`,
      `Back Button - CTA - ${channel_name}`
    );
  };

  const logDeleteBtnClick = (channel_name = "") => {
    logEvent(
      `DELETE_UPLOADED_FILE_IN_${channel_name
        .split(" ")
        .join("_")
        .toLocaleUpperCase()}`,
      `Delete Button - CTA - ${channel_name}`
    );
  };

  const logMessageSent = (channel_name = "") => {
    logEvent(
      `MESSAGE_SENT_CONFIRMATION_POPUP_${channel_name
        .split(" ")
        .join("_")
        .toLocaleUpperCase()}`,
      `Message Confirmation Popup - ${channel_name}`
    );
  };

  const logImageSelectioninWhatsApp = () => {
    logEvent(`SELECT_IMAGE_IN_WHATSAPP`, `Select Image`);
  };

  const logSaveBtnClickInWhatsApp = () => {
    logEvent(`IMAGE_SAVE_IN_WHATSAPP`, `Save Image`);
  };

  const logCancelBtnClickInWhatsApp = () => {
    logEvent(`IMAGE_CANCEL_IN_WHATSAPP`, `Cancel Image`);
  };

  const logCancelBtnClickInEditTemplateWhatsApp = () => {
    logEvent(
      `CANCEL_EDIT_TEMPLATE_IN_WHATSAPP`,
      `Cancel Button Click in Edit Template - WhatsApp`
    );
  };

  const logContinueBtnClickInEditTemplateWhatsApp = () => {
    logEvent(
      `CONTINUE_EDIT_TEMPLATE_IN_WHATSAPP`,
      `Continue Button Click in Edit Template - WhatsApp`
    );
  };

  return {
    logCreateCampaignPageLoaded,
    logChannelClick,
    logCampaignSelection,
    logContinueBtnClick,
    logSendAllCustomersBtnClick,
    logDragAndDropClicked,
    logFileUploadClicked,
    logDownloadSampleBtnClicked,
    logBackBtnClick,
    logDeleteBtnClick,
    logMessageSent,
    logCreateWhatsappPageLoaded,
    logImageSelectioninWhatsApp,
    logSaveBtnClickInWhatsApp,
    logCancelBtnClickInWhatsApp,
    logCancelBtnClickInEditTemplateWhatsApp,
    logContinueBtnClickInEditTemplateWhatsApp,
  };
};

export default useAnalytics;
