import React, { useState, useEffect } from "react";
import useStyle from "./style";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./style.css";

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  "@media screen and (max-width: 899px)": {
    width: "90%",
    top: "30%",
    overflow: "auto",
  },
  bgcolor: "background.paper",
  borderRadius: "1px",
  boxShadow: 24,
  p: 2,
};

export default function RaiseRequestInstallation({
  createInstallationLoading,
  open,
  modalTitle,
  // brandData,
  handleClose,
  createPostInstallation,
  defaultCategory,
  brandList,
  selectedBrand,
  setSelectedBrand,
  categoriesData,
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  subcategoriesData,
}) {
  const classes = useStyle();
  const [disableRaiseRequest, setDisableRaiseRequest] = useState(true);

  const handleRaiseRequestClose = () => {
    setSelectedBrand("");
    setSelectedCategory("");
    setSelectedSubCategory("");
    handleClose();
    setDisableRaiseRequest(!disableRaiseRequest);
  };

  const handleSubCategory = (e) => {
    setSelectedSubCategory(e.target.value);
  };

  useEffect(() => {
    if (selectedBrand && selectedCategory && selectedSubCategory) {
      setDisableRaiseRequest(false);
    }
  }, [selectedBrand, selectedCategory, selectedSubCategory]);

  const handleRaiseRequest = () => {
    if (selectedBrand && selectedCategory && selectedSubCategory) {
      createPostInstallation({
        brand: selectedBrand,
        category: selectedCategory,
        subcategory: selectedSubCategory,
        callback: handleRaiseRequestClose,
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleRaiseRequestClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Raise Request for {modalTitle}
          <span
            className={classes.closeButton}
            onClick={handleRaiseRequestClose}
          >
            X
          </span>
        </Typography>
        <Box>
          {createInstallationLoading && (
            <Box style={{ textAlign: "center" }}>
              <CircularProgress />
            </Box>
          )}
        </Box>
        <Box className="requestContainer">
          <FormControl fullWidth>
            <Autocomplete
              id="brand"
              label="brand"
              name="brand"
              defaultValue={selectedBrand}
              value={selectedBrand}
              onChange={(e, selectedValue) => {
                setSelectedBrand(selectedValue);
              }}
              disabled={createInstallationLoading}
              freeSolo
              options={brandList}
              renderOption={(props, option) => <li {...props}>{option}</li>}
              renderInput={(params) => <TextField {...params} label="Brand" />}
            />
          </FormControl>
        </Box>
        <Box className="requestContainer">
          <FormControl fullWidth>
            <Autocomplete
              id="category"
              label="category"
              name="category"
              value={selectedCategory}
              defaultValue={selectedCategory}
              onChange={(e, categoryValue) =>
                setSelectedCategory(categoryValue)
              }
              disabled={createInstallationLoading}
              freeSolo
              options={Object.keys(categoriesData)}
              autoSelect
              renderOption={(props, option) => <li {...props}>{option}</li>}
              renderInput={(params) => (
                <TextField {...params} label="Category" />
              )}
            />
          </FormControl>
        </Box>
        <Box className="requestContainer">
          <FormControl fullWidth>
            <InputLabel id="subcategory-select-label">Sub Category</InputLabel>
            <Select
              labelId="subcategory-select-label"
              id="subcategory-select"
              label="Sub Category"
              onChange={handleSubCategory}
              disabled={createInstallationLoading}
            >
              {subcategoriesData.map((data) => {
                return (
                  <MenuItem value={data} key={data}>
                    {data}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box className="requestContainer">
            <Button
              variant="contained"
              fullWidth
              onClick={handleRaiseRequest}
              disabled={createInstallationLoading || disableRaiseRequest}
            >
              Raise Request
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
