import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import React from "react";
import Options from "./Options";
import useAutoComplete from "./useAutoComplete";

const AutoComplete = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const textFieldRef = React.useRef(null);
  const {
    apiSuggestion = true,
    label,
    searchKey,
    isLoading,
    inputProps,
    isCatalogue = false,
    value,
  } = props;
  const {
    filteredList,
    showOptions,
    inputValue,
    inputRef,
    onInputChange,
    onSelectItem,
    setInputValue,
  } = useAutoComplete({ apiSuggestion, ...props });

  React.useEffect(() => {
    if (showOptions && inputValue) {
      setAnchorEl(inputRef.current);
    } else setAnchorEl(null);
  }, [showOptions, inputValue, inputRef]);

  React.useEffect(() => {
    setInputValue(value);
  }, [setInputValue, value]);

  return (
    <Box position={"relative"} ref={inputRef}>
      <TextField
        ref={textFieldRef}
        onChange={onInputChange}
        value={inputValue}
        label={label}
        variant={"outlined"}
        size="small"
        fullWidth
        margin="dense"
        // onFocus={() => !isCatalogue && setAnchorEl(inputRef.current)}
        {...inputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isLoading && <CircularProgress size={25} color="success" />}
            </InputAdornment>
          ),
        }}
      />
      <Options
        list={filteredList}
        searchKey={searchKey}
        onSelectItem={onSelectItem}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        isCatalogue={isCatalogue}
      />
    </Box>
  );
};

export default AutoComplete;
