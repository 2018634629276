import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker.hook";

const useAnalytics = (name = "") => {
  const { logEvent } = useAnalyticsEventTracker("Pagination");

  //  type will have "NEXT" or "PREV"
  const logPaginationClick = (type = "") => {
    logEvent(
      `${type.toUpperCase()}_PAGE_IN_${name
        .split(" ")
        .join("_")
        .toUpperCase()}`,
      `${type} Page CTA in ${name}`
    );
  };

  return {
    logPaginationClick,
  };
};

export default useAnalytics;
