import React, { useState } from "react";
import { Box, Button, CircularProgress, TextField, Grid } from "@mui/material";
import useStyles from "./style";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import { validationSchema, initialValues, maxDate18 } from "./utils/";
import AppDatePicker from "../AppDatePicker";
import moment from "moment";
import Api from "../../services/apiv2.service";
import Lottie from "react-lottie";
import successEligibility from "../../../src/assets/lottie/successful.json";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: successEligibility,
};

export default function EligibilityButton({ Icon, route }) {
  const classes = useStyles();
  const [activeModal, setActiveModal] = useState(false);
  const [apiRequestLoading, setApiRequestLoading] = useState(false);
  const [responseData, setResponseData] = useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setApiRequestLoading(true);
      const res = await Api.axioEligibility(values);
      if (res.data.status === "success") {
        setApiRequestLoading(false);
        setResponseData(res.data.data);
        formik.resetForm();
      }
    },
  });

  const handleClick = () => {
    setActiveModal(!activeModal);
  };

  const handleClose = () => {
    setActiveModal(!activeModal);
    setResponseData(false);
    formik.setFieldValue("dob", "");
    formik.resetForm();
  };

  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    "@media screen and (max-width: 899px)": {
      width: "90%",
      height: "500px",
      overflow: "auto",
    },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  const handleDOBPicker = (value) => {
    let dateVal = moment(value).format("YYYY-MM-DD");
    formik.setFieldValue("dob", dateVal);
  };

  return (
    <>
      <Box
        onClick={handleClick}
        className={clsx(
          classes.menuItem,
          activeModal ? `${classes.activeMenu} ",active_menu` : ""
        )}
      >
        <div className="menu_container">
          <span className="menu_icon">
            <Icon />
          </span>
          <span className="menu_title">{route.name}</span>
        </div>
      </Box>
      <Modal
        open={activeModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="h6">Eligibility Check</Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <Button onClick={handleClose}>X</Button>
            </Grid>
          </Grid>
          <hr />
          <Box>
            <form onSubmit={formik.handleSubmit}>
              <Box style={{ textAlign: "center" }}>
                {apiRequestLoading ? <CircularProgress color="success" /> : ""}
              </Box>
              <Box>
                {responseData ? (
                  <>
                    <Lottie options={defaultOptions} height={300} width={400} />
                    <Box style={{ textAlign: "center" }}>
                      {responseData?.decision ? (
                        <Typography variant="h5">
                          Approve Status - {responseData.decision}
                        </Typography>
                      ) : (
                        ""
                      )}
                      <Typography variant="h5">
                        Approved Limit - ₹ {responseData.approvedLimit}
                      </Typography>
                      <Box>
                        <p></p>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <TextField
                      id="amount"
                      label="Amount"
                      size="small"
                      fullWidth
                      margin="dense"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.amount && Boolean(formik.errors.amount)
                      }
                      helperText={formik.touched.amount && formik.errors.amount}
                    />
                    <TextField
                      id="pan"
                      label="PAN"
                      size="small"
                      fullWidth
                      margin="dense"
                      value={formik.values.pan}
                      onChange={formik.handleChange}
                      error={formik.touched.pan && Boolean(formik.errors.pan)}
                      helperText={formik.touched.pan && formik.errors.pan}
                      style={{ marginBottom: "10px" }}
                    />
                    <AppDatePicker
                      fullWidth
                      id="dob"
                      name="dob"
                      label="Date of Birth"
                      style={{ border: "1px solid red", marginTop: "10px" }}
                      value={formik.values.dob}
                      onChange={(value) => handleDOBPicker(value)}
                      borderError={
                        formik.touched.dob && formik.errors.dob ? true : false
                      }
                      // minDate={new Date(MIN_DATE_INVOICE_DATE)}
                      maxDate={maxDate18()}
                    />

                    {formik.touched.dob && formik.errors.dob ? (
                      <p className="custom-error">{formik?.errors?.dob}</p>
                    ) : null}
                    {/* <TextField id="outlined-required" label="Required" size="small" fullWidth margin="dense" /> */}
                    <TextField
                      id="name"
                      label="Name"
                      size="small"
                      fullWidth
                      margin="dense"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField
                      label="Phone No."
                      size="small"
                      fullWidth
                      margin="dense"
                      id="phone"
                      variant="outlined"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                    <TextField
                      label="Email"
                      size="small"
                      fullWidth
                      margin="dense"
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                    <Box sx={{ mt: 2 }}>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        disabled={apiRequestLoading}
                      >
                        Check Eligibility
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
