/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import { FieldArray } from "formik";
// import { isMobile } from "react-device-detect";
// import useSearchEditExist from "../../../../../hooks/useSearchEditExist";
import "./style.scss";

import CustomTableBody from "./CustomTableBody";

const ProductTable = ({
  values,
  setFieldValue,
  disableFields,
  totalAmount,
  errors,
  touched,
}) => {
  // const { checkEditExist } = useSearchEditExist();
  // let checkIfQuickCheckout = checkEditExist("qc");
  // let isEditExist = checkEditExist("edit");
  return (
    <Box
      className="product-table-container"
      style={{
        border: `${
          touched.products && errors?.products?.length > 0
            ? "1px solid #ff1744"
            : ""
        }`,
      }}
    >
      <Box className="header">Product Details</Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">No.</TableCell>

              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Brand</TableCell>
              <TableCell align="center">
                <div style={{ width: "100px" }}>HSN</div>
              </TableCell>
              <TableCell align="center">
                <div style={{ width: "50px" }}>GST</div>
              </TableCell>

              <TableCell align="center">
                <div style={{ width: "100px" }}>Model</div>
              </TableCell>
              <TableCell align="center">
                <div style={{ width: "150px" }}>Serial No. / IMEI No. </div>
              </TableCell>

              <TableCell align="center">Quantity</TableCell>
              <TableCell align="center">
                <div style={{ width: "100px" }}>
                  Total Price
                  <br /> <div className="gst-txt">* includes GST</div>
                </div>
              </TableCell>
              {/* <TableCell align="center">Actions</TableCell> */}
            </TableRow>
          </TableHead>

          <FieldArray
            name="products"
            render={(arrayHelpers) => (
              <CustomTableBody
                // index={index}
                arrayHelpers={arrayHelpers}
                values={values}
                setFieldValue={setFieldValue}
                disableFields={disableFields}
                totalAmount={totalAmount}
              />
            )}
          />
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProductTable;
