import * as Yup from "yup";
import { categoriesWithSerialNo } from "../../../constants/invoice";
import { CARD_LESS_EMI } from "../../../constants/payments";
import {
  mobileRegExp,
  nameRegExp,
  pincodeRegExp,
  alphaNumRegex,
  numberRegex,
} from "../../../constants/regex";

const generateInvoiceSchema = Yup.object({
  firstName: Yup.string()
    .matches(nameRegExp, "Only Alphabets allowed!")
    .min(1, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .matches(nameRegExp, "Only Alphabets allowed!")
    .max(50, "Too Long!"),
  email: Yup.string().email("Invalid Email!"),
  mobile: Yup.string()
    .matches(mobileRegExp, "Invalid Mobile Number!")
    .required("Required"),
  address1: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  address2: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
  area: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
  pincode: Yup.string()
    .matches(pincodeRegExp, "Invalid Pincode!")
    .required("Required"),
  state: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
  district: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
  invoiceDate: Yup.string().required("Required"),
  signature: Yup.string(),
  type: Yup.string().required("Required"),
  mop: Yup.string().when("type", {
    is: (type) => type === "OTHERS",
    then: Yup.string().required("Required!"),
  }),
  paymentRefId: Yup.string().when("type", {
    is: (type) => type === "OTHERS",
    then: Yup.string().matches(alphaNumRegex, "Invalid!").required("Required!"),
  }),
  paidThrough: Yup.string(),
  receivedAmount: Yup.number()
    .typeError("Only numbers allowed!")
    .min("1", "cannot be 0")
    .max("500000", "Upto 5Lakhs allowed!")
    .when("paidThrough", (paidThrough) => {
      if (paidThrough === CARD_LESS_EMI) {
        return Yup.number()
          .typeError("Only numbers allowed!")
          .min("1", "cannot be 0")
          .max("500000", "Upto 5Lakhs allowed!");
      }
    }),
  emiAmount: Yup.number()
    .typeError("Only numbers allowed!")
    .min(1, "cannot be 0")
    .max(500000, "Upto 5Lakhs allowed!")
    .when("paidThrough", (paidThrough) => {
      if (paidThrough === CARD_LESS_EMI) {
        return Yup.number()
          .typeError("Only numbers allowed!")
          .min("1", "cannot be 0")
          .max("500000", "Upto 5Lakhs allowed!");
      }
    }),
  tenure: Yup.string()
    .matches(numberRegex, "Invalid!")
    .min(1, "cannot be < 0")
    .max(999, "Too Long!")
    .when("paidThrough", (paidThrough) => {
      if (paidThrough === CARD_LESS_EMI) {
        return Yup.number()
          .typeError("Only numbers allowed!")
          .min("1", "cannot be 0")
          .max("500000", "Upto 5Lakhs allowed!");
      }
    }),
  provider: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .when("paidThrough", (paidThrough) => {
      if (paidThrough === CARD_LESS_EMI) {
        return Yup.string().min(2, "Too Short!").max(50, "Too Long!");
      }
    }),
  termsAndConditions: Yup.string().max(
    400,
    "Terms & Conditions should be less than 400 characters!"
  ),
  signatureUrl: Yup.string(),
  products: Yup.array().of(
    Yup.object().shape({
      category: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
      brand: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
      model: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),

      serialNo: Yup.string()
        .matches(alphaNumRegex, "Invalid!")
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required")
        .when("category", (category) => {
          if (categoriesWithSerialNo.includes(category)) {
            return Yup.string()
              .matches(alphaNumRegex, "Invalid!")
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Required!");
          }
        }),
      finalPrice: Yup.number()
        .typeError("Only numbers allowed!")
        .min(1, "Price cannot be 0")
        .max(500000, "Upto 5Lakhs allowed!")
        .required("Required"),
      gst: Yup.string().required("Required"),
      hsn: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
      quantity: Yup.number()
        .typeError("Invalid!")
        .min(1, "cannot be < 0")
        .max(999, "Too Long!")
        .required("Required"),
      title: Yup.string(),
    })
  ),
});

export default generateInvoiceSchema;
