import React from "react";
import { getToken } from "../../../src/helpers/common-utils";

function parseJwt() {
  const token = getToken();
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  let value = JSON.parse(jsonPayload);
  return value.storePhone;
}

const CustomInput = (props) => {
  const [localValue, setLocalValue] = React.useState(props.value);
  React.useEffect(() => {
    props.onChange({ target: { name: props.name, value: localValue } });
    // eslint-disable-next-line
  }, [localValue]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setLocalValue(value);
  };

  return <input {...props} value={localValue} onChange={handleInputChange} />;
};

const CreateVariableInputBoxView = ({
  listOfVariables,
  inputFields,
  handleFormChange,
}) => {
  const disableFields = ["storename"];
  return (
    <>
      {listOfVariables?.length > 0 &&
        listOfVariables.map((variable, i) => {
          let val;
          if (variable === "storename") {
            val = inputFields[variable];
          } else if (variable === "contact") {
            val = parseJwt();
          } else {
            val = inputFields[variable];
          }
          return (
            <div key={`-${i}`} className="variable-container-body">
              <div>
                <label className="label-text"> {variable} </label>
                <label className="label-asterik"> * </label>
              </div>
              <CustomInput
                name={variable}
                onChange={handleFormChange}
                disabled={disableFields.indexOf(variable) > -1 ? true : false}
                value={val}
                type="text"
                className="input-box"
              />
            </div>
          );
        })}
    </>
  );
};

export default CreateVariableInputBoxView;
