import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CustomQRCode from "../../../components/CustomQRCode";
import EmiIcon from "../../../assets/images/icons/emi.png";
import CreditCardIcon from "../../../assets/images/icons/credit-card.png";
import CreditCard2Icon from "../../../assets/images/icons/credit-card-2.png";
import UpiIcon from "../../../assets/images/icons/upi.png";
import Modal from "@mui/material/Modal";
import { Typography, CircularProgress, Grid, Button } from "@mui/material";
import Api from "../../../services/api.service";
import { endpoints } from "../../../services/api.service";
import Lottie from "react-lottie";
import successAnimationData from "../../../assets/lottie/successful.json";
import errorAnimationData from "../../../assets/lottie/error.json";
import { isMobile } from "react-device-detect";

const style = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "700px",
  "@media screen and (max-width: 899px)": {
    top: "",
    left: "50%",
    width: "100%",
    overflow: "auto",
    height: "95vh",
  },
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
};

export default function CollectPaymentModal({ showModal, paymentData }) {
  const [open, setOpen] = React.useState(false);
  const [verifyPayment, setVerifyPayment] = useState(null);

  const handleClose = () => setOpen(false);

  const getSuccessPayment = () => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: successAnimationData,
      // rendererSettings: {
      //   preserveAspectRatio: "xMidYMid slice",
      // },
    };
    return (
      <div className={"successContainer"}>
        <Lottie
          options={defaultOptions}
          height={isMobile ? "100%" : 400}
          width={isMobile ? "100%" : 400}
        />
        <div
          className={"redirectionTxt"}
          style={{ fontWeight: "bold", fontSize: "20px", padding: "20px" }}
        >
          Payment Success, Please wait Redirecting...
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (showModal) {
      setOpen(true);
    }
  }, [showModal]);

  useEffect(() => {
    let intervalId;
    async function handleVerify({ generatedPaymentId }) {
      try {
        let data = await Api.get(
          `${endpoints.verifyPayment}?paymentId=${generatedPaymentId}&cp=1`
        );
        if (data.status === "PAYMENT_COMPLETE") {
          clearInterval(intervalId);
          setVerifyPayment(data);
          setTimeout(() => {
            if (data.mop === "CARDLESS_EMI") {
              window.location.href = `/generate-invoice?orderId=${data.orderId}&cp=1&qc=1`;
            } else {
              window.location.href = "/transaction-history";
            }
          }, 3000);
        } else if (data.status === "PAYMENT_FAILED") {
          clearInterval(intervalId);
          setVerifyPayment(data);
        }
      } catch (error) {
        setVerifyPayment({ status: "PAYMENT_FAILED" });
        console.log(error);
      }
    }

    if (paymentData) {
      intervalId = setInterval(() => {
        handleVerify(paymentData);
      }, 5000);
    }
  }, [paymentData]);

  const getErrorPayment = () => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: errorAnimationData,
      // rendererSettings: {
      //   preserveAspectRatio: "xMidYMid slice",
      // },
    };
    return (
      <div className={"successContainer"}>
        <Lottie
          options={defaultOptions}
          height={isMobile ? "100%" : 400}
          width={isMobile ? "100%" : 400}
        />
        <div
          className={"redirectionTxt"}
          style={{ fontWeight: "bold", fontSize: "20px", padding: "20px" }}
        >
          Payment Failed
        </div>
      </div>
    );
  };

  const getPaymentLink = (link) => {
    return (
      <div style={{ width: "90%", margin: "20px auto" }}>
        <div style={{ fontWeight: "bold" }}>Or</div>
        <div style={{ fontWeight: "bold" }}>Copy the Link...</div>
        <div className="payment-link">{link}</div>
      </div>
    );
  };

  const allowedPaymentModes = [
    {
      icon: EmiIcon,
      label: "EMI",
    },
    {
      icon: CreditCardIcon,
      label: "Credit Card",
    },
    {
      icon: CreditCard2Icon,
      label: "Debit Card",
    },
    {
      icon: UpiIcon,
      label: "UPI EMI",
    },
  ];

  const getContent = (paymentData, paymentLink) => {
    return (
      <>
        <div>
          {paymentData?.qrImageLink === false ? (
            <div style={{ marginTop: "20px" }}>
              {paymentLink ? (
                <CustomQRCode value={paymentLink} />
              ) : (
                "Unable to Generate QR Code."
              )}
              {paymentLink
                ? getPaymentLink(paymentLink)
                : "No Link generated..."}
              <Box style={{ textAlign: "center", fontWeight: "bold" }}>
                <Typography style={{ fontWeight: "bold" }}>
                  Pay with any payment method
                </Typography>
              </Box>
              <div className={"payment_container"}>
                {allowedPaymentModes.map((mode, index) => (
                  <div key={`payment-mode-${index}`} className="paymentMode">
                    <img
                      className="payment_icon"
                      src={mode.icon}
                      alt={mode.label}
                    />
                    <div className="payment_label">{mode.label}</div>
                  </div>
                ))}
              </div>
              <Box style={{ textAlign: "center", marginBottom: "20px" }}>
                <p style={{ fontWeight: "bold", marginBottom: "10px" }}>
                  Auto verifying...
                </p>
                <CircularProgress color="success" />
              </Box>
            </div>
          ) : (
            <div>
              <img
                src={paymentLink}
                alt="qr_code"
                width="80%"
                height="500px"
                style={{ objectFit: "cover" }}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  function autoVerify(paymentData) {
    return (
      <div>
        {paymentData?.qrImageLink === true ? (
          <Box style={{ textAlign: "center", marginBottom: "20px" }}>
            <p style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Auto verifying...
            </p>
            <CircularProgress color="success" />
          </Box>
        ) : null}
      </div>
    );
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          style={{
            padding: "10px",
            backgroundColor: "#F7F7F7",
            borderRadius: "5px",
          }}
        >
          <Grid container>
            <Grid item xs={10}>
              <p>Collect Payment</p>
            </Grid>
            <Grid item xs={2}>
              <Button onClick={handleClose}>X</Button>
            </Grid>
          </Grid>
        </Box>
        <Box style={{ textAlign: "center" }}>
          {verifyPayment?.status === "PAYMENT_COMPLETE"
            ? null
            : verifyPayment?.status === "PAYMENT_FAILED"
            ? null
            : autoVerify(paymentData)}
        </Box>
        <Box style={{ textAlign: "center", height: "700px", overflow: "auto" }}>
          {verifyPayment?.status === "PAYMENT_COMPLETE"
            ? getSuccessPayment()
            : verifyPayment?.status === "PAYMENT_FAILED"
            ? getErrorPayment()
            : getContent(paymentData, paymentData?.paymentLink)}
        </Box>
      </Box>
    </Modal>
  );
}
