import React from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  FormHelperText,
  Box,
  Card,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useFeature } from "@growthbook/growthbook-react";

import Layout from "../../components/Layout";
import { FieldArray, Form, Formik, getIn } from "formik";
import * as Yup from "yup";
import "./style/quickCheckout.css";
import useCategoriesAndBrand from "./hooks/useCategoriesAndBrand";
// import CustomAutoComplete from "./components/CustomAutoComplete";
import useCreatePayment from "./hooks/useCreatePayment";
import CollectPaymentModal from "./components/CollectPaymentModal";
import SearchCatalogue from "../../components/SearchCatalogue";
import SelectedProduct from "./components/SelectedProduct";
import { dataFormatter } from "../../helpers/constants";
import { Add, Remove } from "@mui/icons-material";
import AutoComplete from "../../components/SearchCatalogue/AutoComplete";

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const numRegex = /^[1-9]+\d*$/;
const validationSchema = Yup.object().shape({
  totalAmount: Yup.string().label("Total Amount").required(),
  mobile: Yup.string()
    .required("Phone Number is a required field")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "too short")
    .max(10, "too long"),
  mop: Yup.string().label("Payment Method").required(),
  products: Yup.array().of(
    Yup.object().shape({
      category: Yup.string().required("Category is required"),
      brand: Yup.string().required("Brand is required"),
      unitPrice: Yup.string()
        .required("Price/Unit is required")
        .matches(numRegex, "Enter the valid amount"),
    })
  ),
});

export default function QuickCheckout() {
  const { categories, filterBrandsForCategory, brandsData } =
    useCategoriesAndBrand();
  const {
    createPaymentApi,
    showModal,
    paymentData,
    loading,
    showOthers,
    setProductDetails,
    removeProductCard,
    onQuantityUpdate,
    onPriceChange,
  } = useCreatePayment();

  const handleSelectPaymentMethodValue = (setFieldValue, e, val) => {
    setFieldValue("mop", e.target.value);
  };

  // show qrcode

  const isEnabled = useFeature("goretail_upiqr").on;

  return (
    <Layout pageTitle="Collect Payment">
      <p className="collect-title">Collect Payment</p>
      <Formik
        enableReinitialize
        initialValues={{
          totalAmount: 0,
          mobile: "",
          mop: "",
          products: [],
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          createPaymentApi(Object.assign({}, values));
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
          validateField,
        }) => (
          <Form noValidate autoComplete="off">
            <div className="form-container">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="number"
                    name="mobile"
                    required
                    id="outlined-required"
                    label="Mobile Number"
                    fullWidth
                    size="small"
                    value={values.mobile}
                    error={touched.mobile && Boolean(errors.mobile)}
                    helperText={touched.mobile && errors.mobile}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="payment-method">Payment Method*</InputLabel>
                    <Select
                      labelId="payment-label"
                      id="payment-select"
                      label="Payment Method*"
                      name="mop"
                      error={touched.mop && Boolean(errors.mop)}
                      helperText={touched.mop && errors.mop}
                      onChange={(e, val) =>
                        handleSelectPaymentMethodValue(setFieldValue, e, val)
                      }
                    >
                      <MenuItem value={isEnabled ? "UPI_QR" : "UPI"}>
                        UPI
                      </MenuItem>
                      <MenuItem value="CARDLESS_EMI">Cardless EMI</MenuItem>
                      <MenuItem value="CREDIT_CARD_EMI">
                        Credit Card EMI
                      </MenuItem>
                      <MenuItem value="DEBIT_CARD_EMI">Debit card EMI</MenuItem>
                      <MenuItem value="CREDIT_CARD">Credit Card</MenuItem>
                      <MenuItem value="DEBIT_CARD">Debit Card</MenuItem>
                      <MenuItem value="NET_BANKING">NetBanking</MenuItem>
                    </Select>
                    {touched.mop && errors.mop ? (
                      <FormHelperText
                        sx={{ color: "#ff1744", marginLeft: "16px !important" }}
                      >
                        {touched.mop && errors.mop}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              </Grid>
              <Box sx={{ my: 3, p: 3 }} bgcolor={"#F6F9F7"}>
                <SearchCatalogue
                  label={"Add Product"}
                  searchKey={"modelName"}
                  clearOnSelect={true}
                  onSelectProduct={(e) =>
                    setProductDetails(e, setFieldValue, values)
                  }
                />
                {values.products.length > 0 && (
                  <Box>
                    <h4 className="product-details-title">Product detail</h4>
                    <div className="product-detail-container">
                      <FieldArray name="products">
                        {() => (
                          <>
                            <div>
                              {values.products.map((p, index) => {
                                const category = `products[${index}].category`;
                                const brand = `products[${index}].brand`;
                                const unitPrice = `products[${index}].unitPrice`;

                                const touchedCategory = getIn(
                                  touched,
                                  category
                                );
                                const errorCategory = getIn(errors, category);
                                const touchedBrand = getIn(touched, brand);
                                const errorBrand = getIn(errors, brand);

                                const touchedUnitPrice = getIn(
                                  touched,
                                  unitPrice
                                );
                                const errorUnitPrice = getIn(errors, unitPrice);

                                return (
                                  showOthers &&
                                  !p.productCatalogueId && (
                                    <Card sx={{ mb: 2, p: 2 }}>
                                      <Grid
                                        key={index}
                                        container
                                        spacing={1}
                                        style={{
                                          paddingTop: "10px",
                                          paddingBottom: "10px",
                                        }}
                                        alignItems={"center"}
                                      >
                                        <Grid item xs={12} md={3}>
                                          <FormControl
                                            fullWidth
                                            size="small"
                                            margin="dense"
                                          >
                                            <InputLabel id="category">
                                              Category*
                                            </InputLabel>
                                            <Select
                                              labelId="category-label"
                                              id="category-select"
                                              label="Category*"
                                              name={category}
                                              fullWidth
                                              size="small"
                                              value={p.category}
                                              error={
                                                touchedCategory &&
                                                Boolean(errorCategory)
                                              }
                                              helperText={
                                                touchedCategory && errorCategory
                                              }
                                              onChange={(e) =>
                                                filterBrandsForCategory(
                                                  e,
                                                  handleChange,
                                                  category,
                                                  setFieldValue
                                                )
                                              }
                                            >
                                              {categories.map((data) => {
                                                return (
                                                  <MenuItem value={data.value}>
                                                    {data.name}
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                            {touchedCategory &&
                                            Boolean(errorCategory) ? (
                                              <FormHelperText
                                                sx={{
                                                  color: "#ff1744",
                                                  marginLeft: "16px !important",
                                                }}
                                              >
                                                {touchedCategory &&
                                                  errorCategory}
                                              </FormHelperText>
                                            ) : null}
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                          <AutoComplete
                                            name={`search-brands`}
                                            list={brandsData}
                                            value={p.brand}
                                            label={"Brand"}
                                            apiSuggestion={false}
                                            onChange={(val) => {
                                              setFieldValue(
                                                `products[${index}].brand`,
                                                val
                                              );
                                            }}
                                            onValueSelect={(val) => {
                                              setFieldValue(
                                                `products[${index}].brand`,
                                                val
                                              );
                                            }}
                                            inputProps={{
                                              required: true,
                                              error: Boolean(
                                                touchedBrand && errorBrand
                                              ),
                                              helperText:
                                                touchedBrand && errorBrand
                                                  ? errorBrand
                                                  : "",
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                          <TextField
                                            margin="dense"
                                            type="number"
                                            name="unitPrice"
                                            value={p.unitPrice}
                                            required
                                            label="Price/Unit"
                                            fullWidth
                                            size="small"
                                            error={
                                              touchedUnitPrice &&
                                              Boolean(errorUnitPrice)
                                            }
                                            helperText={
                                              touchedUnitPrice && errorUnitPrice
                                            }
                                            onChange={(e) => {
                                              onPriceChange(
                                                e.target.value,
                                                setFieldValue,
                                                index,
                                                values
                                              );
                                            }}
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  ₹
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          md={2}
                                          position={"relative"}
                                        >
                                          <Box className="qc__qty_box">
                                            <small className="qc__qty_label">
                                              Quantity
                                            </small>
                                            <Box
                                              display="flex"
                                              alignItems={"center"}
                                              justifyContent={"space-around"}
                                            >
                                              <IconButton
                                                color="warning"
                                                sx={{ ml: 2 }}
                                                onClick={() =>
                                                  onQuantityUpdate(
                                                    -1,
                                                    setFieldValue,
                                                    index,
                                                    values,
                                                    false
                                                  )
                                                }
                                                disabled={p.quantity < 2}
                                              >
                                                <Remove />
                                              </IconButton>
                                              <Box sx={{ px: 2 }}>
                                                {p.quantity}
                                              </Box>
                                              <IconButton
                                                color="primary"
                                                onClick={() =>
                                                  onQuantityUpdate(
                                                    +1,
                                                    setFieldValue,
                                                    index,
                                                    values,
                                                    false
                                                  )
                                                }
                                              >
                                                <Add />
                                              </IconButton>
                                            </Box>
                                          </Box>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          md={1}
                                          justifyContent="flex-end"
                                          display={"flex"}
                                        >
                                          <Button
                                            variant="outlined"
                                            color="warning"
                                            onClick={() => {
                                              removeProductCard(
                                                index,
                                                values,
                                                setFieldValue
                                              );
                                            }}
                                          >
                                            X
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </Card>
                                  )
                                );
                              })}
                            </div>

                            {/* to show the selected product */}
                            {values?.products?.length > 0 &&
                              values?.products.map((product, i) => {
                                const unitPrice = `products[${i}].unitPrice`;
                                const touchedUnitPrice = getIn(
                                  touched,
                                  unitPrice
                                );
                                const errorUnitPrice = getIn(errors, unitPrice);
                                if (product.productCatalogueId) {
                                  return (
                                    <SelectedProduct
                                      touched={touchedUnitPrice}
                                      errors={errorUnitPrice}
                                      key={product.productCatalogueId}
                                      product={product}
                                      onRemove={() =>
                                        removeProductCard(
                                          i,
                                          values,
                                          setFieldValue
                                        )
                                      }
                                      onAddOrRemove={(e) =>
                                        onQuantityUpdate(
                                          e,
                                          setFieldValue,
                                          i,
                                          values,
                                          true
                                        )
                                      }
                                      onPriceChange={(e) =>
                                        onPriceChange(
                                          e,
                                          setFieldValue,
                                          i,
                                          values
                                        )
                                      }
                                    />
                                  );
                                } else return null;
                              })}
                          </>
                        )}
                      </FieldArray>
                    </div>
                  </Box>
                )}
              </Box>
              <Box display={"flex"} justifyContent="flex-end">
                <Box>
                  Amount to Collect:{" "}
                  {dataFormatter(values.totalAmount, "currency")}
                </Box>
              </Box>
            </div>

            <div style={{ textAlign: "right", marginTop: "20px" }}>
              <Button variant="contained" type="submit" disabled={loading}>
                {loading && <CircularProgress size={14} />} Collect Payment
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <CollectPaymentModal showModal={showModal} paymentData={paymentData} />
    </Layout>
  );
}
