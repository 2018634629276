//  Action.js

import { toast } from "react-toastify";
import Api from "../../../services/api.service";
import { endpoints } from "../../../services/api.service";
import {
  CREATE_PAYMENT_LINK_ERROR,
  CREATE_PAYMENT_LINK_LOADING,
  CREATE_PAYMENT_LINK_SUCCESS,
  VERIFY_PAYMENT_LINK_LOADING,
  VERIFY_PAYMENT_LINK_SUCCESS,
  VERIFY_PAYMENT_LINK_ERROR,
  RESET_PAYMENT_LINK,
} from "./constants";
import { usePayments } from "./contex";

const usePaymentsActions = () => {
  const { dispatch } = usePayments();

  const createPaymentLink = async (payload) => {
    dispatch(createPaymentLinkLoading());
    try {
      let data = await Api.post(endpoints.createPaymentLink, payload);
      dispatch(createPaymentLinkSuccess(data));
    } catch (error) {
      toast.error("Something went wrong, Unable to Generate payment link...");
      dispatch(createPaymentLinkError());
    }
  };

  const createPaymentLinkLoading = () => {
    return {
      type: CREATE_PAYMENT_LINK_LOADING,
    };
  };

  const createPaymentLinkSuccess = (paymentDetails) => {
    return {
      type: CREATE_PAYMENT_LINK_SUCCESS,
      data: paymentDetails,
    };
  };

  const createPaymentLinkError = (error) => {
    return {
      type: CREATE_PAYMENT_LINK_ERROR,
      error,
    };
  };

  const verifyPaymentAction = async (paymentId = "", params = {}) => {
    dispatch(verifyPaymentLoading());
    try {
      let data = await Api.get(endpoints.verifyPayment, {
        paymentId,
        ...params,
      });
      dispatch(verifyPaymentSuccess(data));
    } catch (error) {
      dispatch(verifyPaymentError());
    }
  };

  const verifyPaymentLoading = () => {
    return {
      type: VERIFY_PAYMENT_LINK_LOADING,
    };
  };

  const verifyPaymentSuccess = (paymentDetails) => {
    return {
      type: VERIFY_PAYMENT_LINK_SUCCESS,
      data: paymentDetails,
    };
  };

  const verifyPaymentError = (error) => {
    return {
      type: VERIFY_PAYMENT_LINK_ERROR,
      error,
    };
  };

  const resetPaymentLinkdetails = () => {
    dispatch({
      type: RESET_PAYMENT_LINK,
    });
  };

  return {
    createPaymentLink,
    verifyPaymentAction,
    resetPaymentLinkdetails,
  };
};

export default usePaymentsActions;
