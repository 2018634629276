import { wp_CONTENT_CATEGORY_IDS } from "../../helpers/constants";
import Api from "../../services/apiv2.service";
import { OfferPublisher } from "../../services/pub-sub";

const updateCount = (counts) => {
  Object.keys(counts).forEach((KEY) => {
    const postLength = localStorage.getItem(KEY);
    if (postLength) {
      const totalLength = Number(counts[KEY]) - Number(postLength);
      if (totalLength > 0)
        OfferPublisher.offerCount({ ...counts, [KEY]: totalLength });
    } else {
      let totalPost = Number(counts[KEY]);
      localStorage.setItem(KEY, Number(totalPost));
      OfferPublisher.offerCount({ ...counts, [KEY]: totalPost });
    }
  });
};

const OfferPooling = () => {
  const categoryIds = Object.values(wp_CONTENT_CATEGORY_IDS).join(",");
  const offersUpdate = async () => {
    let counts = {
      OFFERS: 0,
      HELP: 0,
    };
    if (
      window.location.pathname !== "/offers" &&
      window.location.pathname !== "/help" &&
      window.location.pathname !== "/sign-in"
    ) {
      try {
        const resp = await Api.getPostUpdates(categoryIds);
        let resData = resp?.data;
        if (resData?.length > 0) {
          for (let i = 0; i < resData.length; i++) {
            switch (resData[i].categories[0]) {
              case wp_CONTENT_CATEGORY_IDS.OFFERS:
                counts["OFFERS"] += 1;
                break;

              case wp_CONTENT_CATEGORY_IDS.HELP:
                counts["HELP"] += 1;
                break;

              default:
                break;
            }
          }
        }
        updateCount(counts);
      } catch (e) {
        console.log(e);
      }
    }
  };

  setInterval(offersUpdate, 600000);
  return "";
};

export default OfferPooling;
