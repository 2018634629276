import { Button } from "@mui/material";
import clsx from "clsx";
import React from "react";
import useStyles from "./InvoiceCreatedStyle";

const InvoiceCreated = ({ message, onClose, invoiceDetails }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.container}>{message}</div>

      <div className={classes.btnContainer}>
        <Button
          variant={"contained"}
          color="inherit"
          className={clsx(classes.btn, classes.btnCancel)}
          onClick={onClose}
        >
          close
        </Button>
        <a
          href={invoiceDetails?.invoiceLink}
          target="_blank"
          download
          rel="noreferrer"
        >
          <Button
            className={classes.btn}
            variant={"contained"}
            color="secondary"
            // onClick={onDownload}
            autoFocus
          >
            Download
          </Button>
        </a>
      </div>
    </div>
  );
};
export default InvoiceCreated;
