import { Box, Chip, Typography } from "@mui/material"
import React from "react"
import AppDropDown from "../AppDropDown"
import useStyle from "./styles"
import useTags from "./useTags.hook"

const AppTags = ({
  tags: data,
  editable = false,
  style,
  options,
  onShowMore = false,
}) => {
  const classes = useStyle()
  const { tags, onChangeTags, onDeleteTags, displayShowMore } = useTags(
    data,
    onShowMore
  )

  if (!tags.length && !editable) {
    return <div style={{ textAlign: "center" }}>-</div>
  }
  return (
    <Box className={classes.container} style={style}>
      {editable && (
        <AppDropDown
          options={options.map((s) => s.trim())}
          value={tags}
          onChange={onChangeTags}
          SelectProps={{ multiple: true }}
          name="tags"
          style={{ marginBottom: 10 }}
        />
      )}
      {tags.map((tag, index) => (
        <Chip
          key={index}
          label={tag}
          style={{ margin: 3 }}
          onDelete={editable ? () => onDeleteTags() : null}
        />
      ))}
      {displayShowMore && (
        <Typography className={classes.showMore} onClick={onShowMore}>
          Show More
        </Typography>
      )}
    </Box>
  )
}

export default AppTags
