import React from "react";
import { Button, Modal } from "@mui/material";
import ImageEditor from "./imageEditor";
import useAnalytics from "./useAnalytics.createCampaign.hook";

function EditImageModal({
  images,
  editImage,
  setEditImage,
  handlePrevieImageClick,
}) {
  const { editImageUrl, editImageId, openModal } = editImage;
  const { logCancelBtnClickInWhatsApp } = useAnalytics();
  return (
    <Modal
      open={openModal}
      onClose={() => setEditImage({ ...editImage, openModal: false })}
      style={{
        width: "70%",
      }}
    >
      <div className="modal-container">
        <div
          className="image-edit-option"
          style={{ justifyContent: "space-between" }}
        >
          <ImageEditor
            imageUrl={editImageUrl}
            imageId={editImageId}
            images={images}
            handlePrevieImageClick={handlePrevieImageClick}
          />
          <div className="modal-buttons">
            <Button
              onClick={() => {
                logCancelBtnClickInWhatsApp();
                setEditImage({ ...editImage, openModal: false });
              }}
              className="modal-cancel-button"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EditImageModal;
