import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Card,
  Collapse,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React, { cloneElement, useState } from "react";
import useAnalytics from "../../screens/profile/useAnalytics.hook";
import useStyles from "./style";

const ProfileCard = ({ name = "", children, collapseStatus = false }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(collapseStatus);
  const { logCardClick } = useAnalytics();
  return (
    <Box m={2} className={classes.profileBox}>
      <Card elevation={0} variant="outlined" className={classes.card}>
        <Box px={2} py={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontWeight="bold" color="text.main">
              {name}
            </Typography>
            <Divider />
            <Box display="flex">
              <IconButton
                aria-label="open"
                onClick={() => {
                  setOpen(!open);
                  logCardClick(
                    name,
                    name.split(" ").join("_").toLocaleUpperCase()
                  );
                }}
                style={{ color: "black" }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Collapse in={open} timeout="auto">
          <Box
            borderTop={1}
            borderColor="border.light"
            className={classes.childrenContainer}
          >
            {cloneElement(children)}
          </Box>
        </Collapse>
      </Card>
    </Box>
  );
};

export default ProfileCard;
