const TEMP_WHATSAPP_DEFAULT_IMAGE = "https://assets.gostor.com/8.png";

export const createWhatsAppForm = (
  selectedFile,
  channelName,
  header,
  template,
  footer,
  whatsappImage,
  sendingToAllCustomer
) => {
  let formData;
  if (sendingToAllCustomer) {
    formData = {
      channel: channelName,
      template: template,
      header: header,
      footer: footer,
      media_url: whatsappImage || TEMP_WHATSAPP_DEFAULT_IMAGE,
    };
  } else {
    formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("channel", channelName);
    formData.append("template", template);
    formData.append("header", header);
    formData.append("footer", footer);
    formData.append("media_url", whatsappImage || TEMP_WHATSAPP_DEFAULT_IMAGE);
  }

  return formData;
};

export const createSMSForm = (
  selectedFile,
  channelName,
  templateId,
  retailerNumber,
  storeName
) => {
  const formData = new FormData();
  formData.append("file", selectedFile);
  formData.append("channel", channelName);
  formData.append("templateId", templateId);
  formData.append("retailerNumber", retailerNumber);
  formData.append("storeName", storeName);

  return formData;
};
