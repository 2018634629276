import { CardContent } from "@mui/material";
import React from "react";

const ChannelCard = ({ Icon, title, data, channelLabel, onHandleChange }) => {
  const { channel_name } = data;

  const handleChange = (channelLabel) => {
    if (channel_name !== channelLabel) {
      onHandleChange(channelLabel);
    }
  };

  return (
    <div onClick={() => handleChange(channelLabel)}>
      <CardContent>
        <div className="promotion_card_container">
          <div className="promotoin_card_content crm-page">
            <img
              src={Icon}
              alt="not found"
              width="56px"
              height="56px"
              style={{ marginBottom: "12px" }}
            />
            <div className="channel-title-text">{title}</div>
          </div>
        </div>
      </CardContent>
    </div>
  );
};

export default ChannelCard;
