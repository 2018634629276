import { useState } from "react";

const useInvoiceUpload = (fetchHistory) => {
  const [activeOrderId, setOrderActiveId] = useState(null);
  const [openInvoiceModal, setOpenInvoiceModal] = useState();
  const handleInvoiceModalOpen = (activeOrderId) => {
    setOrderActiveId(activeOrderId);
    setOpenInvoiceModal(true);
  };
  const handleInvoiceModalClose = (type) => {
    setOrderActiveId(null);
    setOpenInvoiceModal(false);
    if (type === "success") {
      fetchHistory();
    }
  };

  return {
    handleInvoiceModalOpen,
    handleInvoiceModalClose,
    openInvoiceModal,
    activeOrderId,
  };
};

export default useInvoiceUpload;
