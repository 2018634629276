const initialState = {
  retailerDetails: null,
  customerDetails: null,
  products: [],
  grandTotal: 0,
  taxableAmount: 0,
  additionalCharges: [],
  totalAmount: 0,
  percentage: 0,
  discountAmount: 0,
  balanceAmount: 0,
  receivedAmount: 0,
  paymentType: "",
  mop: "",
  paymentRefId: "",
  fullyPaid: true,
  isRoundOff: false,
  roundOff: 0,
  invoiceDate: Date.now(),
  invoiceDetails: {},
  invoiceData: {},
  discounts: [],
  resetting: false,
  errors: {},
};

export default initialState;
