import React from "react";
import { Grid, Typography } from "@mui/material";
import ProImageDesign from "./ProImageDesign";
import useStyle from "../style";
import { isMobile } from "react-device-detect";
// import LoginSystem from "./LoginSystem";
import { Helmet } from "react-helmet";
import { googlePlay, iosAppStore } from "../../../assets/images";

export default function ProPromo() {
  const classes = useStyle(isMobile);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GoRetail - Login</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ProImageDesign />
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.loginSystemContainer}>
            <div className={classes.loginBGColor}>
              <div className={classes.downloadLogo}>
                <div className={classes.loginForm}>
                  <div className={classes.alignDowloadLogo}>
                    <Typography
                      fontSize={25}
                      fontWeight={500}
                      sx={{
                        display: { sm: "block", md: "block" },
                        fontSize: { xs: "15px", md: "20px" },
                      }}
                    >
                      Please subscribe to Arzooo Pro on the Arzooo app to
                      continue using our services.
                    </Typography>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Grid item xs={12} md={12}>
                      <a href="https://play.google.com/store/apps/details?id=com.arzooonative">
                        <img src={googlePlay} width={200} alt="andriod" />
                      </a>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <a href="https://apps.apple.com/in/app/arzooo/id1497133088">
                        <img src={iosAppStore} width={200} alt="ios" />
                      </a>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
