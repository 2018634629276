const prodConfig = {
  apiGopayUrl: process.env.REACT_APP_API_GOPAY_URL,
  apiPosUrl:
    window.location.origin === "https://gopos-test.netlify.app"
      ? process.env.REACT_APP_GORETAIL_CORE
      : process.env.REACT_APP_GORETAIL_CORE,
  retailUpdates: "https://retail-updates.gostor.com/",
  apiRetailerServiceUrl: "https://retailer-service.gostor.com/",
};

export const growthbook_url =
  "https://cdn.growthbook.io/api/features/key_prod_db29d8bdca862ef8";

export default prodConfig;

export const Installation_Status = true;
