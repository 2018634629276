import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import {
  replaceDoubleBraces,
  eachKeyEmptyCheck,
} from "../../helpers/common-utils";
import PreviewImage from "./PreviewImage";
import LoadAllImages from "./LoadAllImages";
import { imageTypes, imagesTabName } from "./message-constants";
import ImageTypesTab from "./ImageTypesTab";
import CreateVariableInputBoxView from "./CreateVariableInputBoxView";
import "./css/whatsapp.scss";

const WhatsappTemplateView = (props) => {
  const {
    data,
    previewImage,
    setPreviewDataOnUpload,
    activeTab,
    setActiveTab,
    images,
    handleImageClick,
    setDisableContinueButton,
  } = props;
  const { variables, category } = data;
  const [inputFields, setInputFields] = useState({ ...variables });

  const [template, SetTemplate] = useState(data);

  const { LANDSCAPE, POTRAIT } = imageTypes;
  const { landscapeTab, potraitTab } = imagesTabName;

  const allowOnly = (imgOrientationType) => {
    return (image) => {
      return image.type === imgOrientationType;
    };
  };

  const potraitImagesData = images?.filter(allowOnly(POTRAIT));
  const landscapeImagesData = images?.filter(allowOnly(LANDSCAPE));
  const listOfVariables = Object.keys(variables);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setInputFields({ ...inputFields, [name]: value });
  };

  useEffect(() => {
    SetTemplate(replaceDoubleBraces(data, inputFields));
    // eslint-disable-next-line
  }, [inputFields]);

  useEffect(() => {
    setPreviewDataOnUpload({
      previewImage,
      template,
    });
  }, [previewImage, setPreviewDataOnUpload, template]);

  useEffect(() => {
    const result = eachKeyEmptyCheck(inputFields);
    setDisableContinueButton(result);
  }, [inputFields, setDisableContinueButton]);

  return (
    <>
      <Box gridColumn="span 9" className="edit-template-left-container">
        <div className="image-container">
          <div className="tabs-container">
            {landscapeImagesData?.length > 0 && (
              <ImageTypesTab
                tabName={landscapeTab}
                defaultClassName="landscape-tab"
                currentActiveTab={LANDSCAPE}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
              />
            )}
            {potraitImagesData?.length > 0 && (
              <ImageTypesTab
                tabName={potraitTab}
                defaultClassName="potrait-tab"
                currentActiveTab={POTRAIT}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
              />
            )}
          </div>
          <div className="images-body">
            {activeTab === LANDSCAPE ? (
              <LoadAllImages
                images={landscapeImagesData}
                handleImageClick={handleImageClick}
                activeTab={activeTab}
              />
            ) : (
              <LoadAllImages
                images={potraitImagesData}
                handleImageClick={handleImageClick}
                activeTab={activeTab}
              />
            )}
          </div>
        </div>
        <div className="whatsapp-wrapper">
          <div className="promotion-body">
            <div className="body-container">
              <h3 className="body-header-text">
                {" "}
                Selected Template - {category}{" "}
              </h3>
              <div className="body-container-data">
                <p> {template.header} </p>
                <p> {template.template} </p>
                <p> {template.footer} </p>
              </div>
            </div>

            <div className="variable-container">
              <h3 className="variable-header-text"> Variables </h3>
              <CreateVariableInputBoxView
                listOfVariables={listOfVariables}
                inputFields={inputFields}
                handleFormChange={handleFormChange}
              />
            </div>
          </div>
        </div>
      </Box>
      <Box gridColumn="span 3" className="edit-template-right-container">
        <PreviewImage previewImage={previewImage} data={template} />
      </Box>
    </>
  );
};

export default WhatsappTemplateView;
