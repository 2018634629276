import React from "react";
import { Button } from "@mui/material";
import clsx from "clsx";

import AppModal from "../index";
import useStyles from "./style";

const Confirmation = ({
  showConfirmation,
  message,
  submitBtnTxt = "submit",
  cancelBtnTxt = "Cancel",
  onSubmit = () => {},
  onCancel = () => {},
  onClose = () => {},
}) => {
  const classes = useStyles();
  return (
    <AppModal
      title={"Confirmation"}
      showModal={showConfirmation}
      handleClose={() => {
        onClose();
        onCancel();
      }}
    >
      <div className={classes.container}>{message}</div>

      <div className={classes.btnContainer}>
        <Button
          variant={"contained"}
          color="inherit"
          className={clsx(classes.btn, classes.btnCancel)}
          onClick={onCancel}
        >
          {cancelBtnTxt}
        </Button>
        <Button
          className={classes.btn}
          variant={"contained"}
          color="secondary"
          onClick={onSubmit}
          autoFocus
        >
          {submitBtnTxt}
        </Button>
      </div>
    </AppModal>
  );
};

export default Confirmation;
