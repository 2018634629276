export const categoriesWithSerialNo = [
  "TV1",
  "TV2",
  "AC",
  "REFRIGERATOR",
  "WASHING_MACHINE",
  "LAPTOP",
  "MOBILE",
];

export const tenures = [
  {
    label: "3 Months",
    value: 3,
  },
  {
    label: "6 Months",
    value: 6,
  },
  {
    label: "9 Months",
    value: 9,
  },
  {
    label: "12 Months",
    value: 12,
  },
];
