import { paymentModes } from "../constants/payments";

export const getPaymentModeLabel = (paymentModeValue = "") => {
  let peymentMode = paymentModes.filter(
    (paymentMode) => paymentMode.value === paymentModeValue
  );
  peymentMode = peymentMode.length > 0 ? peymentMode[0] : {};
  return peymentMode?.label;
};

export const getPaymentModes = () => {
  return paymentModes.filter((paymentMode) => paymentMode.show && paymentMode);
};
