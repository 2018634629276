import * as React from "react";
import useOutsideClick from "./useOutsideClick";

const useAutoComplete = ({
  apiSuggestion,
  list,
  onValueSelect,
  onChange = () => {},
  searchKey,
  clearOnSelect,
  noDataFound,
  value = "",
  isCatalogue,
}) => {
  const [filteredList, setFilteredList] = React.useState(list);
  const [showOptions, setShowOptions] = React.useState(false);
  // const [showOthers, setShowOthers] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(value);
  const inputRef = React.useRef(null);
  React.useEffect(() => {
    setShowOptions(list.length > 0 ? true : noDataFound);
    setFilteredList(list && list.length > 0 ? list : []);
  }, [list, noDataFound]);

  const onInputChange = (e) => {
    const val = e.target.value.trim();
    setInputValue(e.target.value);
    if (!isCatalogue) onChange(val);
    if (val === "") return;
    if (isCatalogue) onChange(val);
    if (!apiSuggestion) {
      const filteredOptions = list.filter((item) => {
        if (searchKey) {
          return item[searchKey].toLowerCase().includes(val.toLowerCase());
        }
        return item.toLowerCase().includes(val.toLowerCase());
      });
      setFilteredList(filteredOptions);
      setShowOptions(true);
    }
  };
  const onSelectItem = (e) => {
    onValueSelect(e);
    setInputValue(clearOnSelect ? "" : searchKey ? e[searchKey] : e);
    setFilteredList([]);
    setShowOptions(false);
  };

  /**
   * to close the upi suggestion on clicking outside
   *  */
  useOutsideClick(inputRef, () => {
    if (showOptions) {
      setShowOptions(false);
      setFilteredList([]);
    }
  });
  return {
    filteredList,
    showOptions,
    inputValue,
    inputRef,
    onInputChange,
    onSelectItem,
    setInputValue,
  };
};

export default useAutoComplete;
