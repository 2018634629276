import "./editorStyles.css";
import { Button } from "@mui/material";
import React from "react";
import { useImageEditor } from "./useImageEditor.hook";
// import { ToolBar } from "./toolBar";

export default function ImageEditor({
  images,
  imageId,
  imageUrl,
  handlePrevieImageClick,
}) {
  const {
    // editor,
    containerRef,
    // addNewTextDrawing,
    handleClick,
  } = useImageEditor(images, imageId, imageUrl, handlePrevieImageClick);

  return (
    <>
      <div className="template-header-text">
        <span className="header-bar"></span>
        <span className="header-message"> Edit Image </span>
      </div>
      {/* <ToolBar editor={editor} addNewTextDrawing={addNewTextDrawing} /> */}
      <div className="canvas-container" ref={containerRef} />
      <Button onClick={() => handleClick()} className="modal-save-button">
        Save
      </Button>
    </>
  );
}
