import { MenuItem, TextField, Typography } from "@mui/material";
import React from "react";

const AppDropDown = ({
  placeholder,
  value,
  onChange,
  helperText,
  options = [],
  SelectProps = {},
  style,
  allowDefaultOption,
  ...rest
}) => (
  <>
    <TextField
      select
      id="outlined-select-gender"
      label={!value && placeholder}
      value={value}
      defaultValue=""
      onChange={(e) => onChange(e.target.value)}
      InputLabelProps={{ shrink: false }}
      fullWidth
      SelectProps={{
        MenuProps: {
          style: { width: "100%" },
        },
        ...SelectProps,
      }}
      style={style}
      variant="outlined"
      size="small"
      {...rest}
    >
      {allowDefaultOption && (
        <MenuItem value={undefined}>
          <em>{placeholder}</em>
        </MenuItem>
      )}
      {options.map((op, i) => {
        let option = op;
        if (typeof option === "string") {
          option = { label: option, value: option };
        }
        return (
          <MenuItem key={i} value={option.value}>
            {option.label}
          </MenuItem>
        );
      })}
    </TextField>
    <Typography color="primary" fontSize={12} m={1}>
      {helperText}
    </Typography>
  </>
);

export default AppDropDown;
