import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Api from "../../services/apiv2.service";
import { categoryMapping, brandMapping } from "./utils/category-mapping";

const useRaiseRequest = (fetchHistory) => {
  const [raiseRequestOpen, setRaiseRequestOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [createInstallationLoading, setCreateInstallationLoading] =
    useState(false);
  const [activeOrderId, setActiveOrderId] = useState();
  const [activeProductId, setActiveProductId] = useState();
  const [activeCategory, setActiveCategory] = useState();
  const [defaultCategory, setDefaultCategory] = useState(null);

  const [brandData, setBrandData] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subcategoriesData, setSubCategoriesData] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const handleCloseRaiseRequest = (type) => {
    setModalTitle(null);
    setRaiseRequestOpen(false);
    setSelectedBrand(null);
    setDefaultCategory(null);
  };

  async function getCatAndSubCat(brand) {
    setCategoriesData([]);
    setSubCategoriesData([]);
    const params = {
      brand: brand,
    };
    const res = await Api.installationGetCatAndSubCat(params);
    if (res?.data?.status === "SUCCESS") {
      return res?.data?.additionalInfo[0]?.categoryXSubcategory;
    }
  }

  function createSubCat(brandCatSubCat, product = {}) {
    const subCategories = {};
    brandCatSubCat &&
      brandCatSubCat.forEach((item) => {
        subCategories[item?.category] = item?.subcategories;
      });
    setCategoriesData(subCategories);
    let categoryData = categoryMapping(product?.category || activeCategory);
    if (!categoryData) {
      const filteredCat = Object.keys(subCategories).filter(
        (subCat) => subCat.toLowerCase() === product?.category?.toLowerCase()
      );
      if (filteredCat.length > 0) {
        categoryData = filteredCat[0];
      }
    }
    if (categoryData) {
      setSelectedCategory(categoryData);
      setSubCategoriesData(
        subCategories[categoryData] ? subCategories[categoryData] : []
      );
    }
    if (Object.keys(subCategories).length === 1) {
      setSelectedSubCategory(brandCatSubCat[0]?.subcategories);
    }
  }

  async function getCategoriesSubCat(brandData, product) {
    const brandCatSubCat = await getCatAndSubCat(brandData);
    createSubCat(brandCatSubCat, product);
  }

  useEffect(() => {
    if (selectedCategory) {
      setSubCategoriesData(
        categoriesData[selectedCategory] ? categoriesData[selectedCategory] : []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedBrand) {
      setSelectedCategory(null);
      getCategoriesSubCat(selectedBrand);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrand]);

  const handleOpenRaiseRequest = async (product, orderId) => {
    setCreateInstallationLoading(true);
    setActiveOrderId(orderId);
    setActiveCategory(product.category);
    setRaiseRequestOpen(!raiseRequestOpen);
    setActiveProductId(product.productId);
    let brandMappedData = brandMapping(product.brand);
    if (!brandMappedData) {
      const res = await Api.installationBrands();
      if (res?.data?.status === "SUCCESS") {
        const brandsList = res?.data?.additionalInfo?.brands;
        setBrandData(brandsList);
        setModalTitle(product?.brand);
        const filterBrand = brandsList.filter(
          (item) => item.toLowerCase() === product.brand.toLowerCase()
        );
        if (filterBrand.length > 0) {
          brandMappedData = filterBrand[0];
        }
      }
    }
    if (brandMappedData) {
      setSelectedBrand(brandMappedData);
      setSelectedCategory(null);
      getCategoriesSubCat(brandMappedData, product);
    }
    setCreateInstallationLoading(false);
  };

  const createPostInstallation = async (requestData) => {
    setCreateInstallationLoading(true);
    requestData["orderId"] = activeOrderId;
    requestData["goWorldCategory"] = activeCategory;
    requestData["productId"] = activeProductId;
    let createInstallation_response;
    let callBack = requestData.callback;
    delete requestData["callback"];
    createInstallation_response = await Api.createInstallation(requestData);
    if (createInstallation_response.data.status === "SUCCESS") {
      setCreateInstallationLoading(false);
      callBack();
      setRaiseRequestOpen(!raiseRequestOpen);
      toast.success("Installation successfully created");

      fetchHistory();

      return createInstallation_response;
    } else {
      setRaiseRequestOpen(!raiseRequestOpen);
      toast.error("Installation Request Failed");
      setCreateInstallationLoading(false);
    }

    setActiveOrderId();
    setActiveCategory();
  };

  return {
    raiseRequestOpen,
    handleCloseRaiseRequest,
    modalTitle,
    setRaiseRequestOpen,
    handleOpenRaiseRequest,
    createInstallationLoading,
    createPostInstallation,
    defaultCategory,
    brandList: brandData,
    selectedBrand,
    setSelectedBrand,
    categoriesData,
    selectedCategory,
    setSelectedCategory,
    subcategoriesData,
    selectedSubCategory,
    setSelectedSubCategory,
  };
};

export default useRaiseRequest;
