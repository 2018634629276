import { useState } from "react";

const useConfirmationHook = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  const openModal = (data) => {
    setData(data);
    setOpen(true);
  };

  const closeModal = () => {
    setData(null);
    setOpen(false);
  };

  return {
    open,
    openModal,
    closeModal,
    data,
  };
};

export default useConfirmationHook;
