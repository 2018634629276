import React from "react";
import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import useStyle from "./style";
import { InvoicePatternStringLocations } from "./constants";
import { useInvoicePattern } from "./hook/useInvoicePattern";

export default function InvoicingSettings({ invoicingPattern }) {
  const classes = useStyle();
  const {
    generateSampleInvoiceNumber,
    updateFormData,
    stringComponent,
    startFrom,
    stringLocation,
    loading,
    saveInvoicingPattern,
  } = useInvoicePattern(invoicingPattern);
  return (
    <Box>
      <div className={classes.bankDetails}>
        <div className={classes.header}>
          Preview:
          <div className={classes.headerCaption}>
            (An Example of what these settings will generate)
          </div>
        </div>
        <div className={classes.tableCell} align="left">
          {generateSampleInvoiceNumber()}
        </div>
        <div className={classes.divider}></div>
        <div className={classes.header}>
          Abbreviation*:
          <div className={classes.headerCaption}>
            (Ex: GoStor Electronics can have a value GSE)
          </div>
          <div className={classes.headerCaption}>(Minimum 4 characters)</div>
        </div>
        <div className={`${classes.tableCell} ${classes.paddingFields}`}>
          <TextField
            type="text"
            disabled={loading}
            className={classes.fullWidth}
            name="totalAmount"
            value={stringComponent}
            id="outlined-required"
            label="Abbreviation"
            fullWidth
            size="small"
            required
            onChange={updateFormData("stringComponent")}
            placeholder="Ex: GSE"
          />
        </div>
        <div className={classes.divider}></div>
        <div className={classes.header}>
          Text Position*:
          <div className={classes.headerCaption}>
            (Where would you like the abbreviation be placed)
          </div>
        </div>
        <div className={`${classes.tableCell} ${classes.paddingFields}`}>
          <FormControl fullWidth size="small">
            <InputLabel id="payment-method">Text Position</InputLabel>
            <Select
              labelId="text-position"
              disabled={loading}
              id="text-position"
              label="Text Position"
              required
              onChange={updateFormData("stringLocation")}
              className={classes.fullWidth}
              value={stringLocation}
            >
              <MenuItem>Please Select</MenuItem>
              {InvoicePatternStringLocations.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.divider}></div>
        <div className={classes.header}>
          Start From*:
          <div className={classes.headerCaption}>
            (Numeric sequence you would like the invoices to start)
          </div>
        </div>
        <div className={classes.tableCell}>
          <Input
            disabled={loading}
            value={startFrom}
            onChange={updateFormData("startFrom")}
            type="number"
            placeholder="507"
            className={classes.fullWidth}
          />
        </div>
      </div>
      <Button
        variant={"contained"}
        color="secondary"
        disabled={loading}
        onClick={saveInvoicingPattern}
        className={classes.saveButton}
      >
        Save Changes
      </Button>
    </Box>
  );
}
