import {
  Person,
  Dashboard,
  PeopleAlt,
  DescriptionOutlined,
  Payment,
  HistoryToggleOff,
  BorderColor,
  Logout,
  GppMaybe,
  SettingsInputComponentOutlined,
  CloudDownload,
  LocalOffer,
  // HelpCenterOutlined,
} from "@mui/icons-material";
import { isMobile } from "react-device-detect";

const ALLROUTES = [
  {
    path: "/collect-payment",
    name: "Collect Payment",
    featureName: "collect_payment",
    IconComponent: Payment,
    enabled: true,
  },
  {
    path: "/transaction-history",
    name: "Order History",
    featureName: "order_history",
    IconComponent: DescriptionOutlined,
    enabled: true,
  },

  {
    path: "/profile",
    name: "Profile",
    featureName: "profile",
    IconComponent: Person,
    enabled: true,
  },
  {
    path: "#",
    name: "CRM",
    featureName: "crm",
    IconComponent: Payment,
    enabled: true,
    children: [
      {
        path: "/crm/promotion",
        name: "Create Campaign",
        IconComponent: BorderColor,
      },
      {
        path: "/crm/campaign-history",
        name: "Campaign History",
        IconComponent: HistoryToggleOff,
      },
      {
        path: "/crm/customer",
        name: "Customer",
        IconComponent: PeopleAlt,
      },
    ],
  },
  {
    path: "",
    type: "Modal",
    name: "Eligibility Check",
    featureName: "eligibility_check",
    IconComponent: GppMaybe,
    enabled: false,
  },
  {
    path: "/bulk-download",
    name: "Historic invoice",
    featureName: "history_invoice",
    IconComponent: CloudDownload,
    enabled: true,
  },
  {
    path: "/offers",
    name: "Offers",
    featureName: "offer",
    IconComponent: LocalOffer,
    enabled: true,
    badgeCount: true,
  },
  {
    path: "/installation",
    name: "Installation",
    featureName: "installationservice",
    IconComponent: SettingsInputComponentOutlined,
    enabled: false,
  },
  // {
  //   path: "/help",
  //   name: "Help",
  //   IconComponent: HelpCenterOutlined,
  //   enabled: true,
  //   badgeCount: true,
  // },
  {
    path: "/logout",
    name: "Logout",
    IconComponent: Logout,
    enabled: true,
  },
];

export const AdminRoutes = [
  {
    path: "/retailers",
    name: "Retailers",
    IconComponent: Dashboard,
    target: "_self",
  },
  {
    path: "/crm/customer",
    name: "Customer",
    IconComponent: PeopleAlt,
    target: "_self",
  },

  {
    path: "/transaction-history",
    name: "Order History",
    IconComponent: DescriptionOutlined,
    target: "_self",
  },
  {
    path: "/logout",
    name: "Logout",
    IconComponent: Logout,
  },
];

const disableForMobile = [""];

let ROUTES = ALLROUTES;

if (isMobile) {
  ROUTES = ALLROUTES.filter(
    (route) => disableForMobile.indexOf(route.name) < 0
  );
}

export { ROUTES };

// export const getRoutes = () => {
//   let isGostore = getGostorLink();
//   if (isGostore) {
//     if (ROUTES.some((item) => item.name === "Gostor")) {
//       return ROUTES;
//     } else {
//       let index = ROUTES.length - 1;
//       ROUTES.splice(index, 0, {
//         // path: `${getGostorLink()}`,
//         name: "Gostor",
//         IconComponent: Storefront,
//         target: "_self",
//         handleOnClick: handleGoStoreLink,
//       });
//       return ROUTES;
//     }
//   } else {
//     return ROUTES;
//   }
// };
