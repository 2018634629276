import React from "react";
import Layout from "../../components/Layout";
import GenerateInvoiceWrapper from "./GenerateInvoice/GenerateInvoiceWrapper";
import { PaymentsProvider } from "../../components/Payments/store/contex";
import { GenerateInvoiceProvider } from "./store/contex";

const GenerateInvoiceV3 = () => {
  return (
    <Layout pageTitle="Generate Invoice">
      <GenerateInvoiceProvider>
        <PaymentsProvider>
          <GenerateInvoiceWrapper />
        </PaymentsProvider>
      </GenerateInvoiceProvider>
    </Layout>
  );
};

export default GenerateInvoiceV3;
