import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker.hook";

const useAnalytics = () => {
  const { logEvent } = useAnalyticsEventTracker("Profile");

  const logPageLoaded = () => {
    logEvent("PROFILE_DETAILS_PAGE_LANDING", "Profile Page Landing");
  };
  const logCardClick = (name, value) => {
    logEvent(`${value}_IN_PROFILE_PAGE`, `${name} CTA`);
  };

  return {
    logPageLoaded,
    logCardClick,
  };
};

export default useAnalytics;
