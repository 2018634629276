/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import Api, { endpoints } from "../../../../../services/api.service";
import useApi from "../../../../../services/useApi";

const useCustomTableRow = (index, product, setFieldValue) => {
  const [hsnGstDetails, setHsnGst] = useState(null);
  const {
    execute: getHsnGstDetailsApi,
    response: hsnGstResponse,
    error: hsnGstError,
  } = useApi(Api.get);

  const getHsnGstDetails = (params) => {
    getHsnGstDetailsApi(endpoints.getHsnGstDetails, params);
  };

  const handleCategoryChange = (value) => {
    setFieldValue(`products.${index}.brand`, "");
    setFieldValue(`products.${index}.hsn`, "");
    setFieldValue(`products.${index}.gst`, "");
  };

  useEffect(() => {
    if (hsnGstResponse) setHsnGst(hsnGstResponse);
  }, [hsnGstResponse, hsnGstError]);

  useEffect(() => {
    if (product?.category) {
      getHsnGstDetails({ category: product.category });
    }
  }, [product?.category]);

  useEffect(() => {
    if (hsnGstDetails?.hsn_code || hsnGstDetails?.gst_perc) {
      if (hsnGstDetails?.hsn_code?.length === 1)
        setFieldValue(`products.${index}.hsn`, hsnGstDetails.hsn_code[0]);

      if (hsnGstDetails?.gst_perc?.length === 1)
        setFieldValue(`products.${index}.gst`, hsnGstDetails.gst_perc[0]);
    }
  }, [hsnGstDetails]);

  return {
    hsnGstDetails,
    getHsnGstDetails,
    handleCategoryChange,
  };
};

export default useCustomTableRow;
