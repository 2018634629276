import { useState, useEffect } from "react";
import Api from "../../../../services/apiv2.service";
import moment from "moment";
import { toast } from "react-toastify";

const useCategories = (open, handleClose) => {
  const [loading, setLoading] = useState(true);
  const [brandData, setBrandData] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subcategoriesData, setSubCategoriesData] = useState([]);

  async function getCatAndSubCat(brand) {
    setCategoriesData([]);
    setSubCategoriesData([]);
    const params = {
      brand: brand,
    };
    const res = await Api.installationGetCatAndSubCat(params);
    if (res?.data?.status === "SUCCESS") {
      return res?.data?.additionalInfo[0].categoryXSubcategory;
    }
  }

  function createSubCat(brandCatSubCat) {
    const subCategories = {};
    brandCatSubCat.forEach((item) => {
      subCategories[item?.category] = item?.subcategories;
    });
    setCategoriesData(subCategories);
    if (Object.keys(subCategories).length === 1) {
      setSubCategoriesData(brandCatSubCat[0]?.subcategories);
    }
  }

  async function getCategoriesSubCat(brandData) {
    const brandCatSubCat = await getCatAndSubCat(brandData);
    createSubCat(brandCatSubCat);
  }

  useEffect(() => {
    if (selectedCategory) {
      setSubCategoriesData(categoriesData[selectedCategory]);
    } else {
      setSubCategoriesData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedBrand) {
      setSelectedCategory(null);
      getCategoriesSubCat(selectedBrand);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrand]);

  const getBrandList = async () => {
    let res = await Api.installationBrands();

    if (res.data.status === "SUCCESS") {
      setBrandData(res?.data?.additionalInfo?.brands);
      setLoading(false);
    } else {
      toast.error("Filed to load Brand List");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getBrandList();
    }
    // eslint-disable-next-line
  }, [open]);

  const handleSelect = async (formik, type, e, selectedValue = false) => {
    formik.setFieldValue(type, e.target.value);
    if (type === "category") {
      setSelectedCategory(e.target.value);
    }

    if (type === "brand") {
      if (selectedValue) {
        formik.setFieldValue("brand", selectedValue);
        formik.setFieldValue("category", null);
        formik.setFieldValue("subcategory", null);
        setSelectedBrand(selectedValue);
      } else {
        formik.setFieldValue("brand", "");
        clearCategoryData();
      }
    }
  };

  const resetForm = (formik) => {
    formik.resetForm();
    setSelectedBrand(null);
    clearCategoryData();
    handleClose();
  };

  const handleDatePicker = (formik, type, value) => {
    let dateVal = moment(value).format("YYYY-MM-DD");
    formik.setFieldValue(type, dateVal);
  };

  const clearCategoryData = () => {
    setCategoriesData([]);
    setSubCategoriesData([]);
  };

  const handlePincode = async (formik, pincode) => {
    formik.setFieldValue("pincode", pincode);
    if (pincode.length === 6) {
      setLoading(true);
      const resp = await Api.pincodeValidation(`?pincode=${pincode}`);
      if (resp.data.status === "error") {
        formik.setFieldValue("pincode", "");
        formik.setFieldError("pincode", "Not Valid Pincode", false);
        formik.setTouched({ pincode: true }, true);
      }
      setLoading(false);
    }
  };

  return {
    loading,
    categoriesData,
    setLoading,
    brandData,
    subcategoriesData,
    handlePincode,
    clearCategoryData,
    resetForm,
    handleDatePicker,
    handleSelect,
  };
};

export default useCategories;
