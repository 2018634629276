import moment from "moment";
import { useState } from "react";
import { getValidDate } from "../../helpers/constants";

const useTransactionHistoryCardHook = (
  orderId,
  price,
  totalAmount,
  date,
  onEditSettlement,
  orderDate
) => {
  const [edit, setEdit] = useState(false);
  const [settledAmount, setSettledAmount] = useState();
  const [settledDateTime, setSettledDateTime] = useState();
  const [error, setError] = useState({});

  const validateSettlementData = () => {
    let priceError;
    let dateError;
    if (!settledAmount) {
      priceError = "Amount Required";
    } else if (settledAmount <= 0) {
      priceError = "Settlement amount should be grater than 0";
    } else if (settledAmount >= totalAmount) {
      priceError = "Settlement amount should be less than total amount";
    }
    if (!settledDateTime) {
      dateError = "Date Required";
    } else if (
      !(
        moment(settledDateTime).isValid() &&
        moment(settledDateTime).isBetween(orderDate, moment())
      )
    ) {
      dateError =
        "Date should be greater than order date and or equal to present date";
    }
    const errors = { priceError, dateError };
    setError(errors);
    return !Object.values(errors).filter(Boolean).length;
  };

  const handleEditSettlementData = () => {
    if (validateSettlementData()) {
      const data = {
        orderId,
        settlementAmount: parseFloat(settledAmount),
        settlementDateTime: settledDateTime,
        onComplete: () => setEdit(false),
      };
      onEditSettlement(data);
    }
  };

  const editSettlementData = () => {
    setError({});
    setSettledAmount(price);
    const defaultDate = getValidDate(date);
    setSettledDateTime(defaultDate);
    setEdit(true);
  };

  return {
    edit,
    setEdit,
    error,
    handleEditSettlementData,
    editSettlementData,
    settledAmount,
    setSettledAmount,
    settledDateTime,
    setSettledDateTime,
  };
};

export default useTransactionHistoryCardHook;
