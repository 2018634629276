/* eslint-disable no-empty-pattern */
import React, { useImperativeHandle, forwardRef } from "react";

import useConfirmationHook from "./useConfirmation.hook";

import ConfirmationPopup from "../../../../../components/AppModal/Confirmation";

const Confirmation = (
  {
    submitBtnTxt,
    cancelBtnTxt,
    onSubmit,
    onCancel,
    isCollectPayment,
    isEdit = false,
    invoiceDetails,
    oldInvoiceData,
  },
  ref
) => {
  const { open, openModal, closeModal, logSubmitBtnClick, logCloseBtnClick } =
    useConfirmationHook();

  useImperativeHandle(
    ref,
    () => ({
      openConfirmationModal: openModal,
      closeConfirmationModal: () => {
        onCancel && onCancel();
        closeModal();
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!open) return null;

  let message = "";

  const getTotal = (products, multiplyQty = false) => {
    return products?.reduce(
      (accumulator, current) =>
        accumulator +
        (multiplyQty
          ? Number(current.finalPrice) * Number(current.quantity)
          : Number(current.finalPrice)),
      0
    );
  };

  if (
    isEdit &&
    invoiceDetails?.type === "ONLINE" &&
    Number(getTotal(invoiceDetails?.products, true)) -
      Number(getTotal(oldInvoiceData?.products)) <=
      0
  ) {
    message = "Are you sure you want to modify?";
    submitBtnTxt = "Modify";
  } else if (invoiceDetails?.type === "ONLINE" && !isCollectPayment) {
    message = "Are you sure you want to proceed to payments?";
    submitBtnTxt = "Proceed";
  } else if (isEdit) {
    message = "Are you sure you want to modify?";
    submitBtnTxt = "Modify";
  } else {
    message = "Are you sure you want to submit?";
    submitBtnTxt = "Submit";
  }

  return (
    <ConfirmationPopup
      showConfirmation={true}
      message={message}
      submitBtnTxt={submitBtnTxt}
      onSubmit={() => {
        logSubmitBtnClick();
        onSubmit();
      }}
      cancelBtnTxt={cancelBtnTxt}
      onCancel={closeModal}
      onClose={logCloseBtnClick}
    />
  );
};

export default forwardRef(Confirmation);
