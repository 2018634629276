//  Action.js

import { toast } from "react-toastify";
import { appendParams } from "../../../helpers/common-utils";
import Api from "../../../services/api.service";
import { endpoints } from "../../../services/api.service";
import {
  GENERATE_INVOICE_ERROR,
  GENERATE_INVOICE_LOADING,
  GENERATE_INVOICE_SUCCESS,
  GET_INVOICE_DETAILS_ERROR,
  GET_INVOICE_DETAILS_LOADING,
  GET_INVOICE_DETAILS_SUCCESS,
  RESETTING,
  RESET_INVOICE_DETAILS,
  STORE_ERRORS,
  STORE_INVOICE_DATE,
} from "./constants";
import { useGenerateInvoiceContex } from "./contex";

const useInvoiceDetails = () => {
  const { dispatch } = useGenerateInvoiceContex();

  const storeInvoiceDate = (date) => {
    dispatch({
      type: STORE_INVOICE_DATE,
      date,
    });
  };

  const generateInvoiceAction = async ({ params = {}, payload }) => {
    dispatch(generateInvoiceLoading());
    try {
      let data;
      if (params?.cp === "1") {
        data = await Api.post(
          appendParams(endpoints.generateInvoiceCollectPayment, params),
          payload
        );
      } else {
        data = await Api.post(
          appendParams(endpoints.generateInvoice, params),
          payload
        );
      }
      dispatch(generateInvoiceSuccess(data));
    } catch (error) {
      toast.error(error.message);
      dispatch(generateInvoiceError(error));
    }
  };

  const modifyInvoiceAction = async ({ params = {}, payload }) => {
    dispatch(generateInvoiceLoading());
    try {
      let data = await Api.put(
        appendParams(endpoints.modifyInvoice, params),
        payload
      );
      dispatch(generateInvoiceSuccess(data));
    } catch (error) {
      toast.error(error.message);
      dispatch(generateInvoiceError(error));
    }
  };

  const generateInvoiceLoading = () => {
    return {
      type: GENERATE_INVOICE_LOADING,
    };
  };

  const generateInvoiceSuccess = (data) => {
    return {
      type: GENERATE_INVOICE_SUCCESS,
      data: data,
    };
  };

  const generateInvoiceError = (error) => {
    return {
      type: GENERATE_INVOICE_ERROR,
      error,
    };
  };

  const resetInvoiceDetails = () => {
    dispatch({
      type: RESETTING,
    });
    setTimeout(
      () =>
        dispatch({
          type: RESET_INVOICE_DETAILS,
        }),
      1000
    );
  };

  const storeErrors = (errors) => {
    dispatch({
      type: STORE_ERRORS,
      errors,
    });
  };

  const getOrderDetailsAction = async (params) => {
    dispatch(getInvoiceDetailsLoading());
    try {
      let data;
      data = await Api.get(appendParams(endpoints.getInvoiceData, params));
      dispatch(getInvoiceDetailsSuccess(data));
    } catch (error) {
      dispatch(getInvoiceDetailsError(error));
    }
  };

  const getInvoiceDetailsLoading = () => {
    return {
      type: GET_INVOICE_DETAILS_LOADING,
    };
  };

  const getInvoiceDetailsSuccess = (data) => {
    return {
      type: GET_INVOICE_DETAILS_SUCCESS,
      data: data,
    };
  };

  const getInvoiceDetailsError = (error) => {
    return {
      type: GET_INVOICE_DETAILS_ERROR,
      error,
    };
  };

  return {
    storeInvoiceDate,
    generateInvoiceAction,
    modifyInvoiceAction,
    resetInvoiceDetails,
    storeErrors,
    getOrderDetailsAction,
  };
};

export default useInvoiceDetails;
