import { Grid, Box, CircularProgress } from "@mui/material";
import React from "react";
import Layout from "../../components/Layout";
import OfferCard from "./components/OfferCard";
import useOffer from "./useOffer";
import "./style.css";
import SectionHeader from "../../components/SectionHeader";

export default function Offers() {
  const { respData, loadingPost } = useOffer();
  return (
    <Layout pageTitle="Offers">
      <SectionHeader heading="Whats New In Offers!" />
      {loadingPost ? (
        <Box style={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={4}>
          {respData?.length > 0
            ? respData.map((post, index) => {
                return (
                  <Grid key={post.id} item xs="12" md="4">
                    <OfferCard post={post} />
                  </Grid>
                );
              })
            : "No New Offers"}
        </Grid>
      )}
    </Layout>
  );
}
