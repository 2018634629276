import React from "react";
import { Box, Table, TableBody, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import useStyle from "./style";
import "./style/style.css";

export default function PersonalProfile({ personalProfile }) {
  const classes = useStyle();
  return (
    <Box style={{ overflow: "auto" }}>
      <Table
        aria-label="Profile details"
        className={classes.tableContianer}
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableBody className={classes.tableBody}>
          <TableRow>
            <TableCell className={classes.tableTitle}>Store Name:</TableCell>
            <TableCell className={classes.tableCell}>
              {personalProfile?.store_name || "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell scope="row">Address:</TableCell>
            <TableCell className={classes.tableCell}>{`${
              personalProfile?.address_line_1 || ""
            } ${personalProfile?.address_line_2 || "-"}`}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>PIN:</TableCell>
            <TableCell className={classes.tableCell}>
              {personalProfile?.pincode || "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>City/District:</TableCell>
            <TableCell className={classes.tableCell}>
              {personalProfile?.city || "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>State:</TableCell>
            <TableCell className={classes.tableCell}>
              {personalProfile?.state || "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Phone Number:</TableCell>
            <TableCell className={classes.tableCell}>
              {personalProfile?.phone_number || "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Email:</TableCell>
            <TableCell className={classes.tableCell}>
              {personalProfile?.email || "-"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
