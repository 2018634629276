/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import { useField } from "formik";
import "./style.scss";

const CustomInput = ({
  className = "",
  label,
  disabled,
  isLoading = false,
  startingText,
  endingText,
  autoComplete = "none",
  error,
  showCharCount,
  maxCharCount,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [parentSize, setParentSize] = useState(0);
  const [charCount, setCharCount] = useState(0);
  const parentRef = useRef(null);
  let errorMessage = (meta.touched && meta.error) || error;

  useEffect(() => {
    const { clientHeight, clientWidth } = parentRef.current;

    setParentSize(Math.min(clientHeight, clientWidth));
  }, []);

  useEffect(() => {
    if (showCharCount) {
      let newCharCount = String(field?.value || "").length;
      setCharCount(newCharCount);
    }
  }, [field?.value]);

  return (
    <div className="input-container">
      {showCharCount && (
        <div className="char-count">
          {charCount} / {maxCharCount}
        </div>
      )}
      <TextField
        ref={parentRef}
        className={"custom-input " + className}
        fullWidth
        size="small"
        label={label}
        {...field}
        disabled={disabled}
        error={errorMessage ? true : false}
        helperText={errorMessage}
        {...props}
        InputProps={{
          autoComplete: autoComplete,
          startAdornment: startingText && (
            <InputAdornment className="adornament-txt" position="start">
              {startingText}
            </InputAdornment>
          ),
          endAdornment: endingText ? (
            <InputAdornment position="end">{endingText}</InputAdornment>
          ) : (
            isLoading && (
              <InputAdornment position="end">
                <CircularProgress size={0.6 * parentSize} />
              </InputAdornment>
            )
          ),
        }}
      />
    </div>
  );
};

export default CustomInput;
