import { makeStyles } from "@mui/styles"

const useStyle = makeStyles((theme) => ({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    display: "flex",
  },
  tag: {
    background: theme.palette.background.dark,
    padding: "5px 10px",
    borderRadius: 50,
    marginRight: 5,
    marginBottom: 5,
    display: "flex",
    alignItems: "center",
  },
  showMore: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    marginTop: "10px !important",
    textDecorationLine: "underline",
    color: theme.palette.primary.dark,
    cursor: "pointer",
  },
}))

export default useStyle
