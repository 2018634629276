import React from "react";
import CustomQRCode from "../CustomQRCode";

import EmiIcon from "../../assets/images/icons/emi.png";
import CreditCardIcon from "../../assets/images/icons/credit-card.png";
import CreditCard2Icon from "../../assets/images/icons/credit-card-2.png";
import UpiIcon from "../../assets/images/icons/upi.png";
import useStyles from "./style";
import { formatCurrency, strFormatter } from "../../helpers/common-utils";
import { Button, CircularProgress, Fade } from "@mui/material";
import clsx from "clsx";
import Lottie from "react-lottie";
import successAnimationData from "../../assets/lottie/successful.json";
import errorAnimationData from "../../assets/lottie/error.json";
import { paymentStatuses } from "../../constants/payments";

const CollectPayment = ({
  verifyPayment,
  data,
  isOnline,
  showNetworkMsg,
  showVerifyBtn,
  checkPaymentStatus,
}) => {
  const allowedPaymentModes = [
    {
      icon: EmiIcon,
      label: "EMI",
    },
    {
      icon: CreditCardIcon,
      label: "Credit Card",
    },
    {
      icon: CreditCard2Icon,
      label: "Debit Card",
    },
    {
      icon: UpiIcon,
      label: "UPI EMI",
    },
  ];

  const classes = useStyles();

  const getPaymentLink = (link) => {
    return (
      <div className={classes.paymentLinkContainer}>
        <div className={classes.orTxt}>Or</div>
        <div className={classes.copyLinkTxt}>Copy the Link...</div>
        <div className={classes.paymentLink}>{link}</div>
      </div>
    );
  };

  const autoVerifying = () => {
    return (
      <div className={classes.paymentLinkContainer}>
        {showVerifyBtn ? (
          <div className={classes.networkError}>
            <div className={classes.networkErrorTxt}>
              {showVerifyBtn && !isOnline ? (
                <div style={{ color: "red" }}>
                  Please click on 'Check Status' once you are back online
                </div>
              ) : showVerifyBtn && isOnline ? (
                "You are back online. Please click on 'Check Status' to verify payments"
              ) : (
                ""
              )}
            </div>

            {showVerifyBtn && isOnline && (
              <Button
                variant={"contained"}
                color="inherit"
                className={clsx(classes.btn, classes.btnCancel)}
                onClick={() => checkPaymentStatus(data.generatedPaymentId)}
                disable={!isOnline}
              >
                Check Payment Status
              </Button>
            )}
          </div>
        ) : (
          <>
            <div className={classes.orTxt}>Auto verifying...</div>
            <CircularProgress />
          </>
        )}
      </div>
    );
  };

  const getSuccessPayment = () => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: successAnimationData,
      // rendererSettings: {
      //   preserveAspectRatio: "xMidYMid slice",
      // },
    };

    return (
      <div className={classes.successContainer}>
        <Lottie options={defaultOptions} height={400} width={400} />
        <div className={classes.redirectionTxt}>
          Payment Success, Please wait Redirecting...
        </div>
      </div>
    );
  };

  const getErrorPayment = () => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: errorAnimationData,
      // rendererSettings: {
      //   preserveAspectRatio: "xMidYMid slice",
      // },
    };

    return (
      <div className={classes.successContainer}>
        <Lottie options={defaultOptions} height={400} width={400} />
        <div className={classes.redirectionTxt}>
          Payment failed, Try reinitiating...
        </div>
      </div>
    );
  };

  const getBalance = ({ totalAmount = 0, amountPaid = 0 }) => {
    return Number(totalAmount) - Number(amountPaid);
  };

  const getContent = () => {
    return (
      <div className={classes.content}>
        <div className={classes.qrContainer}>
          {data?.paymentLink ? (
            <CustomQRCode value={data.paymentLink} />
          ) : (
            "Unable to Generate QR Code."
          )}

          {data?.paymentLink
            ? getPaymentLink(data.paymentLink)
            : "No Link generated..."}

          <div className={classes.title}>Pay with any payment method</div>
          <div className={classes.paymentContainer}>
            {allowedPaymentModes.map((mode, index) => (
              <div
                key={`payment-mode-${index}`}
                className={classes.paymentMode}
              >
                <img
                  className={classes.icon}
                  src={mode.icon}
                  alt={mode.label}
                />
                <div className={classes.label}>{mode.label}</div>
              </div>
            ))}
          </div>

          {autoVerifying()}
        </div>

        <div className={classes.productContainer}>
          <div className={classes.productTitle}>Product</div>
          <div className={classes.prductsList}>
            {data?.payload?.products?.map((product, index) => {
              return (
                <div className={classes.productTile}>
                  {product.icon && (
                    <div className={classes.productImg}>
                      <img
                        className={classes.img}
                        src={product.icon}
                        alt={`category-${index}`}
                      />
                    </div>
                  )}
                  <div className={classes.field}>
                    <span className={classes.fieldLabel}>Category:</span>{" "}
                    <span
                      className={clsx(classes.fieldValue, classes.toCapitalize)}
                    >
                      {strFormatter(product.category)}
                    </span>
                  </div>
                  <div className={classes.field}>
                    <span className={classes.fieldLabel}>Brand:</span>{" "}
                    <span className={classes.fieldValue}>
                      {strFormatter(product.brand)}
                    </span>
                  </div>
                  <div className={classes.field}>
                    <span className={classes.fieldLabel}>Model:</span>{" "}
                    <span className={classes.fieldValue}>{product.model}</span>
                  </div>
                  <div className={classes.field}>
                    <span className={classes.fieldLabel}>Quantity:</span>{" "}
                    <span className={classes.fieldValue}>
                      {product.quantity}
                    </span>
                  </div>
                  <div className={classes.field}>
                    <span className={classes.fieldLabel}>Sale Price:</span>{" "}
                    <span className={classes.fieldValue}>
                      {formatCurrency(
                        Number(product.finalPrice) / Number(product.quantity)
                      )}
                    </span>
                  </div>
                  <div className={classes.field}>
                    <span className={classes.fieldLabel}>Total Price:</span>{" "}
                    <span className={classes.fieldValue}>
                      {formatCurrency(product.finalPrice)}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className={classes.amountCalculations}>
            {data?.payload?.amountPaid && (
              <div className={classes.amountField}>
                <b>Amount Paid:</b> {formatCurrency(data?.payload?.amountPaid)}
              </div>
            )}

            <div className={classes.amountField}>
              <b>Balance Amount:</b> {formatCurrency(getBalance(data?.payload))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={classes.container}
      style={{ marginTop: showNetworkMsg ? "35px" : "0px" }}
    >
      {showNetworkMsg && (
        <Fade in={showNetworkMsg ? true : false}>
          <div
            className={classes.warning}
            style={{ background: isOnline ? "green" : "#ae3434" }}
          >
            {!isOnline
              ? "You are offline, please check network connection! Please don't refresh we will update the status soon system is online!"
              : "Back Online!"}
          </div>
        </Fade>
      )}

      {verifyPayment?.status === paymentStatuses.PAYMENT_COMPLETE
        ? getSuccessPayment()
        : verifyPayment?.status === paymentStatuses.PAYMENT_FAILED
        ? getErrorPayment()
        : getContent()}

      {/* {!verifyPayment?.isPaymentCompleted && (
        <div className={classes.btnContainer}>
          <Button
            variant={"contained"}
            color="inherit"
            className={clsx(classes.btn, classes.btnCancel)}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      )} */}
    </div>
  );
};

export default CollectPayment;
