import SearchIcon from "@mui/icons-material/Search"
import { Box, IconButton, InputBase } from "@mui/material"
import React from "react"

const AppSearchInput = ({ placeholder, ...rest }) => (
  <Box display="flex" {...rest}>
    <Box
      border={1}
      borderColor="border.light"
      borderRadius="5px 0 0 5px"
      width="100%"
      px={3}
      py={0.5}
    >
      <InputBase
        placeholder={placeholder}
        inputProps={{ "aria-label": "search offers" }}
        fullWidth
      />
    </Box>
    <Box bgcolor="primary.main" borderRadius="0 5px 5px 0" display="flex">
      <IconButton type="submit" aria-label="search">
        <SearchIcon />
      </IconButton>
    </Box>
  </Box>
)

export default AppSearchInput
