/* eslint-disable no-unused-vars */
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Collapse,
  IconButton,
} from "@mui/material";
import React, { useRef } from "react";
import AppAlert from "../../components/AppAlert";
import AppConfirmationDialog from "../../components/AppConfirmationDialog";
import AppDatePicker from "../../components/AppDatePicker";
import AppDropDown from "../../components/AppDropDown";
import AppInput from "../../components/AppInput";
import AppNoData from "../../components/AppNoData";
import AppPagination from "../../components/AppPagination";
import TransactionHistoryCard from "../../components/TransactionHistoryCard";
import { isAdmin } from "../../helpers/common-utils";
import {
  MIN_DATE,
  TRANSACTION_HISTORY_HEADER_TITLES,
  TRANSACTION_HISTORY_HEADER_TITLES_ADMIN,
  TRANSACTION_HISTORY_STATUS,
  formatCurrency,
  formatDate,
  getHeaderList,
} from "../../helpers/constants";
import useStyle from "./styles";
import useTransactionHistory from "./useTransactions.hook";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Layout from "../../components/Layout";
import StyledTableCell from "../../helpers/StyledTableCell";
import { isMobile, BrowserView, MobileView } from "react-device-detect";
import useFilterStatus from "./useFilterStatus";
import TransactionCard from "./TransactionCard";
import ClearIcon from "@mui/icons-material/Clear";
import { RootTable } from "../../helpers/RootTable";
import useAnalytics from "./useAnalytics.hook";
import UploadInvoice from "../../components/UploadInvoice";
import useInvoiceUpload from "./useInvoiceUpload";
import RaiseRequestInstallation from "./components/RaiseRequestInstallation";
import useRaiseRequest from "./useRaiseRequest";
import { useFeature } from "@growthbook/growthbook-react";

const TransactionHistory = () => {
  const classes = useStyle();
  const {
    tableRef,
    transactionHistory,
    totalCount,
    loading,
    page,
    setPage,
    error,
    setError,
    filters,
    handleFilterChange,
    downloadCSVData,
    downloading,
    itemsPerRow,
    storeName = "-",
    showConfirmation,
    askConfirmation,
    settlementData = {},
    setSettlementData,
    handleEditSettlement,
    editLoading,
    isInValidDate,
    fetchHistory,
  } = useTransactionHistory();
  const { filterStatus, handleFilterStatus } = useFilterStatus();
  const { logSearchbarClick, logDatePickerClicked, logStatusDropdownClick } =
    useAnalytics();

  const {
    handleInvoiceModalOpen,
    handleInvoiceModalClose,
    openInvoiceModal,
    activeOrderId,
  } = useInvoiceUpload(fetchHistory);
  const {
    raiseRequestOpen,
    handleCloseRaiseRequest,
    modalTitle,
    handleOpenRaiseRequest,
    completeData,
    createPostInstallation,
    createInstallationLoading,
    defaultCategory,
    brandList,
    selectedBrand,
    setSelectedBrand,
    categoriesData,
    selectedCategory,
    setSelectedCategory,
    selectedSubCategory,
    setSelectedSubCategory,
    subcategoriesData,
  } = useRaiseRequest(fetchHistory);
  const installationFeature = useFeature("installationservice").on;
  const adminUser = isAdmin();
  const headerTitles = adminUser
    ? TRANSACTION_HISTORY_HEADER_TITLES_ADMIN
    : getHeaderList(installationFeature);
  const pageTitle = adminUser ? "Go Pay Orders" : `Order History`;

  const refetchOrderHistory = () => {
    fetchHistory();
  };

  return (
    <Layout pageTitle={pageTitle}>
      <Box>
        <Box className={classes.filterContainer}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography color="primary" fontSize={19} fontWeight="bold">
              Total Orders : {totalCount}
            </Typography>
            {isMobile && (
              <Typography color="primary">
                <FilterAltOutlinedIcon onClick={handleFilterStatus} />
              </Typography>
            )}
          </Box>

          <Box
            position="absolute"
            width={140}
            height={3}
            bottom={0}
            sx={{ backgroundColor: "black" }}
          />
        </Box>
        <Collapse in={filterStatus}>
          <Box className={classes.filterContainer}>
            <Box className={classes.searchContainer}>
              <Box position="absolute" top={10} left={15}>
                <SearchIcon />
              </Box>
              <AppInput
                placeholder={
                  adminUser
                    ? "Payment ID / Retailer Name / Phone Number"
                    : "Payment ID / Phone Number"
                }
                width="50%"
                value={filters.queryString}
                onChange={(val) =>
                  handleFilterChange("queryString", val || undefined)
                }
                inputProps={{ style: { paddingLeft: 50 } }}
                onClick={logSearchbarClick}
              />
            </Box>
            <Box
              flexWrap="wrap"
              justifyContent="flex-end"
              flex={1}
              sx={{ display: { xs: "block", md: "flex" } }}
            >
              <Box className={classes.innerFilters} style={{ display: "flex" }}>
                <AppDatePicker
                  name="startDate"
                  label="Start Date"
                  value={filters.startDate ? filters.startDate : null}
                  onChange={(val) => handleFilterChange("startDate", val)}
                  onClick={() => logDatePickerClicked("Start Date")}
                  error={isInValidDate(filters.startDate)}
                  helperText={
                    isInValidDate(filters.startDate) && "Invalid Date"
                  }
                  minDate={new Date(MIN_DATE)}
                  maxDate={new Date()}
                />
                <IconButton
                  onClick={() => handleFilterChange("startDate", null)}
                  sx={{ display: { xs: "inline", sm: "none" } }}
                  className={classes.iconButton}
                >
                  <ClearIcon />
                </IconButton>
              </Box>
              <Box className={classes.innerFilters} style={{ display: "flex" }}>
                <AppDatePicker
                  name="endDate"
                  label="End Date"
                  value={filters.endDate}
                  onChange={(val) => handleFilterChange("endDate", val)}
                  onClick={() => logDatePickerClicked("End Date")}
                  error={isInValidDate(filters.endDate, filters.startDate)}
                  helperText={
                    isInValidDate(filters.endDate, filters.startDate) &&
                    "Invalid Date"
                  }
                  minDate={filters.startDate || new Date(MIN_DATE)}
                  maxDate={new Date()}
                />
                <IconButton
                  onClick={() => handleFilterChange("endDate", null)}
                  sx={{ display: { xs: "inline", sm: "none" } }}
                  className={classes.iconButton}
                >
                  <ClearIcon />
                </IconButton>
              </Box>
              <Box className={classes.innerFilters}>
                <AppDropDown
                  name="orderStatus"
                  placeholder="Status"
                  value={filters.orderStatus}
                  onChange={(val) => handleFilterChange("orderStatus", val)}
                  onClick={logStatusDropdownClick}
                  options={TRANSACTION_HISTORY_STATUS}
                  allowDefaultOption
                />
              </Box>
              <Box>
                <Button onClick={downloadCSVData} variant="outlined">
                  {downloading ? (
                    <CircularProgress size={15} ml={1} />
                  ) : (
                    "Download Report"
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </Collapse>
      </Box>
      <Box>
        {loading ? (
          <Box textAlign="center">
            <CircularProgress style={{ marginTop: "20px" }} />
          </Box>
        ) : (
          <>
            <BrowserView>
              <Paper elevation={0}>
                <TableContainer
                  ref={tableRef}
                  sx={{
                    maxHeight: {
                      xs: "calc(100vh - 150px)",
                      md: "calc(100vh - 300px)",
                    },
                  }}
                >
                  <RootTable>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead height="50">
                        <TableRow>
                          {headerTitles.map((title, i) => (
                            <StyledTableCell key={i} align="center">
                              <Typography
                                sx={{ fontSize: 13 }}
                                color="white"
                                fontWeight="bold"
                              >
                                {title}
                              </Typography>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {/* desktop view */}
                      <TableBody className="Body">
                        {transactionHistory.length ? (
                          transactionHistory.map((data, i) => (
                            <TransactionHistoryCard
                              key={i}
                              {...data}
                              refetchOrderHistory={refetchOrderHistory}
                              handleInvoiceModalOpen={handleInvoiceModalOpen}
                              handleRaiseRequestOpen={handleOpenRaiseRequest}
                              adminUser={adminUser}
                              installationFeature={installationFeature}
                              onEditSettlement={(values) => {
                                showConfirmation(true);
                                setSettlementData(values);
                              }}
                            />
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={TRANSACTION_HISTORY_HEADER_TITLES.length}
                              className={classes.center}
                            >
                              <AppNoData />
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </RootTable>
                </TableContainer>
                <AppPagination
                  name="Transaction History Page"
                  page={page}
                  setPage={setPage}
                  totalCount={totalCount}
                  itemsPerRow={itemsPerRow}
                />
              </Paper>
            </BrowserView>
            <MobileView>
              {/* mobile view */}
              {transactionHistory.length ? (
                transactionHistory.map((data, i) => (
                  <TransactionCard
                    key={i}
                    data={data}
                    refetchOrderHistory={refetchOrderHistory}
                    handleRaiseRequestOpen={handleOpenRaiseRequest}
                    handleInvoiceModalOpen={handleInvoiceModalOpen}
                    installationFeature={installationFeature}
                    adminUser={adminUser}
                    onEditSettlement={(values) => {
                      showConfirmation(true);
                      setSettlementData(values);
                    }}
                  />
                ))
              ) : (
                <p>No data</p>
              )}
              <AppPagination
                name="Transaction History Page"
                page={page}
                setPage={setPage}
                totalCount={totalCount}
                itemsPerRow={itemsPerRow}
              />
            </MobileView>
          </>
        )}
      </Box>
      <AppAlert error={error} close={() => setError()} />
      <AppConfirmationDialog
        open={askConfirmation}
        handleClose={() => {
          setSettlementData();
          showConfirmation(false);
        }}
        onSuccess={handleEditSettlement}
        title="Are you Sure you want to edit the settlement details?"
        description={`Settlement Amount: ${formatCurrency(
          settlementData.settlementAmount
        )}\nSettlement Date: ${formatDate(settlementData.settlementDateTime)}`}
        loading={editLoading}
      />

      {/* Invoice Upload */}
      <UploadInvoice
        activeOrderId={activeOrderId}
        open={openInvoiceModal}
        handleClose={handleInvoiceModalClose}
      />
      <RaiseRequestInstallation
        createInstallationLoading={createInstallationLoading}
        createPostInstallation={createPostInstallation}
        modalTitle={modalTitle}
        defaultCategory={defaultCategory}
        brandList={brandList}
        completeData={completeData}
        open={raiseRequestOpen}
        selectedBrand={selectedBrand}
        setSelectedBrand={setSelectedBrand}
        handleClose={handleCloseRaiseRequest}
        categoriesData={categoriesData}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        selectedSubCategory={selectedSubCategory}
        setSelectedSubCategory={setSelectedSubCategory}
        subcategoriesData={subcategoriesData}
      />
    </Layout>
  );
};

export default TransactionHistory;
