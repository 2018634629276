export const formatDate = (date) => {
  const newDate = new Date(date);
  const yyyy = newDate.getFullYear();
  let mm = newDate.getMonth() + 1; // Months start at 0!
  let dd = newDate.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedNewDate = dd + "/" + mm + "/" + yyyy;

  return formattedNewDate;
};

export const getDateFormat = (date) => {
  const dateObj = new Date(date);

  // Create a formatter using the "sv-SE" locale
  const dateFormatter = Intl.DateTimeFormat("sv-SE");

  // Use the formatter to format the date

  return dateFormatter.format(dateObj); //  yyyy-mm-dd
};
