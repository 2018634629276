// useApi.js
import { useEffect, useState } from "react";

const useApi = (api) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const execute = async (url, data, options) => {
    setIsLoading(true);
    setIsLoading(true);
    try {
      const response = await api(url, data, options);
      setIsLoading(false);
      setResponse(response);
      setError(null);
    } catch (error) {
      setIsLoading(false);
      setResponse(null);
      setError(error);
    }
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setResponse(null);
      setError(null);
    };
  }, []);

  return { execute, isLoading, response, error };
};

export default useApi;
