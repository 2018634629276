export const promotionPageMessage = {
  whatsappSuccessfulMessage:
    "Your message has been sent to Customer successfully.",
};

export const WHATSAPP_CHANNEL_NAME = "whatsapp";
export const SMS_CHANNEL_NAME = "sms";

export const imageTypes = {
  LANDSCAPE: "Landscape",
  POTRAIT: "Potrait",
};

export const imagesTabName = {
  landscapeTab: "Landscape",
  potraitTab: "Potrait",
};

export const redirectMessagePostCRM = {
  smsRedirectMsg: "Please wait while we redirect you to Campaign History",
  whatsappRedirectMsg: "Please wait while we redirect you.",
};

export const channelTitles = {
  smsTitle: "SMS Broadcast",
  whatsappTitle: "WhatsApp Broadcast ",
};
