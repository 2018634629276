import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    // TODO: (refactor) - remove all global/scss components with make style
    "@global": {
      ".MuiDialogTitle-root": {
        height: 75,
        backgroundColor: `${palette.background.dark} !important`,
        padding: "0 25px",
        display: "flex",
        alignItems: "center",
      },
      ".MuiDialogContent-root": {
        padding: 0,
      },
    },
  })
);

const GlobalCss = () => {
  useStyles();
  return null;
};

export default GlobalCss;
