import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: "25px",
      minWidth: "400px",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px !important",
        width: "100%",
        minWidth: "300px",
      },
    },
    btnContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "20px 25px",
      backgroundColor: theme.palette.color.bg,
      [theme.breakpoints.down("md")]: {
        justifyContent: "space-between !important",
      },
    },
    btn: {
      [theme.breakpoints.down("md")]: {
        fontSize: "12px !important",
        width: "150px !important",
      },
    },
    btnCancel: {
      marginRight: "20px !important",
    },
  })
);

export default useStyles;
