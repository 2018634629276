import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CloudUpload, DeleteOutlineOutlined } from "@mui/icons-material";
import {
  Button,
  Grid,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import useStyles from "./style";
import "./uploadInvoice.css";
import useInvoiceUpload from "./useInvoiceUpload";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  "@media screen and (max-width: 899px)": {
    width: "90%",
  },
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};

const UploadInvoice = ({ open, handleClose, activeOrderId }) => {
  const classes = useStyles();
  const fileUploadRef = useRef();
  const {
    dragActive,
    handleDragOver,
    handleDragLeave,
    changeHandler,
    handleSubmit,
    enableUploadButton,
    selectedFile,
    fileSizeInKBorMB,
    handleDeleteFiles,
    handleInvoiceModalClose,
    uploadProgress,
    invalidFileError,
  } = useInvoiceUpload(handleClose);

  return (
    <Modal
      open={open}
      onClose={handleInvoiceModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Upload Invoice{" "}
          <span
            className={classes.closeButton}
            onClick={handleInvoiceModalClose}
          >
            X
          </span>
        </Typography>

        <div className={classes.fileUploadContainer}>
          {!selectedFile && (
            <div>
              <div
                className={`image-upload-wrap ${dragActive}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
              >
                <input
                  className="file-upload-input"
                  type="file"
                  ref={fileUploadRef}
                  accept="application/pdf,image/png,image/jpg,image/jpeg"
                  onChange={changeHandler}
                />
                <div className="drag-text">
                  <CloudUpload fontSize="large" color="primary" />
                  <h3>Drag and drop a file or click here to select file</h3>
                </div>
              </div>
              <div style={{ textAlign: "center", color: "red" }}>
                <p>{invalidFileError && invalidFileError}</p>
              </div>
            </div>
          )}
          <div>
            {selectedFile && (
              <div className={classes.uploadedFilesContainer}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={10}>
                        <Typography>
                          <span style={{ fontWeight: "bold" }}>
                            File Name :
                          </span>{" "}
                          {selectedFile?.name}
                        </Typography>
                        <Typography>
                          <span style={{ fontWeight: "bold" }}>
                            File Size :
                          </span>{" "}
                          {fileSizeInKBorMB(selectedFile?.size)}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: "right" }}>
                        <DeleteOutlineOutlined
                          onClick={handleDeleteFiles}
                          className="delete_icon"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </div>
            )}
          </div>
          <div>
            {uploadProgress && (
              <Box textAlign="center">
                <CircularProgress color="success" />
                <p>Uploading....</p>
              </Box>
            )}
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                fullWidth
                style={{ marginTop: "17px" }}
                onClick={() => handleSubmit(activeOrderId)}
                disabled={enableUploadButton || uploadProgress}
              >
                Upload
              </Button>
            </Grid>
          </Grid>
        </div>
      </Box>
    </Modal>
  );
};

export default UploadInvoice;
