import { create } from "apisauce";
import config from "../config";
import getRefreshToken from "./getRefreshToken";
window.cacheResp = {};

const VERSION = "v1";

const typeJSON = "application/json";

const LOCALSTORAGE_CACHEKEY = "api_cache";
const api = create({
  //   baseURL: config.apiUrl,
  headers: {
    Accept: typeJSON,
    "Content-Type": typeJSON,
  },
});

const apiURL = {
  gopay: config.apiGopayUrl,
  pos: config.apiPosUrl,
  retailUpdates: config.retailUpdates,
  retailerService: config.apiRetailerServiceUrl,
};

const calls = {};

function cacheResponse(method, cacheKey, resp, useStorage) {
  if (method === "get") {
    window.cacheResp[cacheKey] = { resp, setAt: new Date() };
    useStorage &&
      localStorage.setItem(
        LOCALSTORAGE_CACHEKEY,
        JSON.stringify(window.cacheResp)
      );
  }
}
const hydrateCache = () => {
  try {
    const temp = localStorage.getItem(LOCALSTORAGE_CACHEKEY);
    window.cacheResp = JSON.parse(temp) || {};
  } catch (e) {}
};
const endpoints = {
  updateInvoicingPattern: {
    point: `${apiURL["pos"]}invoice/pattern`,
    method: "patch",
    attachToken: true,
  },
  getGostorURL: {
    point: `${apiURL["pos"]}user/gostor-redirect`,
    method: "get",
    useStorage: true,
    attachToken: true,
  },
  activityTracker: {
    point: `${apiURL["pos"]}user-metrics`,
    method: "post",
    attachToken: true,
  },
  whatsappTemplates: {
    point: `${apiURL["pos"]}whatsapp/template/${VERSION}`,
    method: "get",
    attachToken: true,
  },
  whatsappTemplatesEdit: {
    point: `${apiURL["pos"]}whatsapp/template/${VERSION}`,
    method: "get",
    attachToken: true,
  },
  smsTemplates: {
    point: `${apiURL["pos"]}sms/sms-templates`,
    method: "get",
    attachToken: true,
  },
  whatsappUploadImage: {
    point: `${apiURL["pos"]}whatsapp/template/upload`,
    method: "post",
    attachToken: true,
    multipart: true,
  },
  postPromotionCampaign: {
    point: `${apiURL["pos"]}sms/trigger-campaign`,
    method: "post",
    attachToken: true,
    multipart: true,
  },
  whatsappPostPromotionCampaign: {
    point: `${apiURL["pos"]}whatsapp/template`,
    method: "post",
    attachToken: true,
    multipart: true,
  },
  whatsappSendCampaignAllCustomers: {
    point: `${apiURL["pos"]}whatsapp/template/send`,
    method: "post",
    attachToken: true,
  },
  getCampaignHistory: {
    point: `${apiURL["pos"]}sms/campaign-history`,
    method: "get",
    attachToken: true,
  },
  getOrderCategoryDetails: {
    point: `${apiURL["pos"]}order/order_details`,
    method: "get",
    attachToken: true,
  },
  uploadInvoice: {
    point: `${apiURL["pos"]}invoice/upload/invoice-pdf?upload=true`,
    method: "post",
    attachToken: true,
  },
  axioEligibility: {
    point: `${apiURL["pos"]}eligibility-check`,
    method: "post",
    attachToken: true,
  },
  getCategoriesAndBrand: {
    point: `${apiURL["pos"]}category/categories-and-brands`,
    method: "get",
    attachToken: true,
  },
  createInstallation: {
    point: `${apiURL["pos"]}installation/create`,
    method: "post",
    attachToken: true,
  },
  getInstallationDetails: {
    point: `${apiURL["pos"]}installation/tickets`,
    method: "get",
    attachToken: true,
  },
  postInstallationCreate: {
    point: `${apiURL["pos"]}installation/create?manual=true`,
    method: "post",
    attachToken: true,
  },
  getDownloadList: {
    point: `${apiURL["pos"]}invoice/download/get-bulk`,
    method: "get",
    attachToken: true,
  },
  createBulkRequest: {
    point: `${apiURL["pos"]}invoice/download/bulk`,
    method: "post",
    attachToken: true,
  },
  installationBrands: {
    point: `${apiURL["pos"]}installation/brands`,
    method: "get",
    attachToken: true,
    TTL: 24 * 60,
    useStorage: true,
    useCache: true,
  },
  getPostUpdates: {
    point: `${apiURL["retailUpdates"]}wp-json/wp/v2/posts?_fields=id,excerpt,title,link,content,categories,featured_media&per_page=100&categories=`,
    method: "get",
  },
  getFeaturedImage: {
    point: `${apiURL["retailUpdates"]}wp-json/wp/v2/media/`,
    method: "get",
  },
  pincodeValidation: {
    point: `${apiURL["pos"]}identity/v2/address`,
    method: "get",
    attachToken: true,
  },
  // Catalogue APIs
  getCatalogue: {
    point: `${apiURL["pos"]}catalogue/search?text=`,
    method: "get",
    attachToken: true,
  },
  geProductDetails: {
    point: `${apiURL["pos"]}catalogue/product-detail`,
    method: "post",
    attachToken: true,
  },
  installationGetCatAndSubCat: {
    point: `${apiURL["pos"]}installation/brand-details`,
    method: "get",
    attachToken: true,
    TTL: 24 * 60,
    useStorage: true,
    useCache: true,
  },
  getNearbyStores: {
    point: `${apiURL["retailerService"]}retailers/nearby_stores`,
    method: "get",
  },
};

calls.preparePostHeaders = (headers) => {
  return { headers: { ...headers } };
};

const returnApiCall = (ep) => {
  const {
    method,
    point: endPt,
    attachToken = false,
    multipart = false,
    TTL: CACHE_TTL_MINS = 2,
    useStorage,
    useCache,
  } = ep;
  return async (payload, ep = "", token, uploadProgress) => {
    let ePt = endPt; // copying to localscope was needed because of assign by reference behaviour of js
    ePt = ep ? endPt + ep : endPt;
    // debugger;
    if (typeof payload != "object") {
      ePt = `${endPt}${payload ? payload : ""}`;
      payload = null;
    }

    let header = undefined;
    // added temp token for avoid otp blocker
    if (attachToken) {
      const token = localStorage.getItem("API_TOKEN") || "";

      header = calls.preparePostHeaders({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      });
    }

    if (multipart) {
      header = calls.preparePostHeaders({
        ...(header.headers || {}),
        "Content-type": "multipart/form-data",
      });
    }
    if (uploadProgress) {
      let config = { onUploadProgress: uploadProgress }; // axis config onUploadProgress
      header = { ...header, ...config };
    }

    // todo: add cache first strategy
    // creating an in browser memory cache, so the cache will be cleared when user refreshes the page
    const cacheKey = `${ePt}-${JSON.stringify(payload || {})}`;
    if (window.cacheResp[cacheKey]) {
      const ttlTime = new Date(window.cacheResp[cacheKey].setAt).getTime();
      const timeDifference = new Date().getTime() - ttlTime;
      const minutes = Math.floor(timeDifference / 1000 / 60);
      if (minutes < CACHE_TTL_MINS) {
        console.log("Using cache", ePt);
        return window.cacheResp[cacheKey].resp;
      }
      console.log("Cache Expired", ePt);
    }
    let resp = await api[method](ePt, payload, header);
    if (resp?.data?.statusCode === 401) {
      let res = await getRefreshToken();
      if (res) {
        header["headers"]["Authorization"] = `Bearer ${res}`;
        resp = await api[method](ePt, payload, header);
      }
    }
    if (useCache && resp.status === 200 && resp.data.status === "success") {
      // store cache only when API response is successful
      cacheResponse(method, cacheKey, resp, useStorage);
    }

    return resp;
  };
};

const points = Object.keys(endpoints);
for (let i = 0; i < points.length; i++) {
  let key = points[i];
  let ep = endpoints[key];
  calls[key] = returnApiCall(ep);
}
hydrateCache();
window.apiCalls = calls;
export default calls;
