/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
import React, { useImperativeHandle, forwardRef } from "react";
import useConfirmationHook from "./useConfirmation.hook";
import AppModal from "../../../../components/AppModal";
import InvoiceCreated from "./InvoiceCreated";

const InvoiceCreatedModal = ({ onClose }, ref) => {
  const {
    open,
    openModal,
    closeModal,
    data: invoiceDetails,
  } = useConfirmationHook();

  useImperativeHandle(
    ref,
    () => ({
      openInvoiceModal: openModal,
      closeInvoiceModal: closeModal,
    }),
    []
  );

  const handleClose = () => {
    closeModal();
    onClose && onClose();
  };

  if (!open) return null;

  return (
    <AppModal
      title={"Successful"}
      showModal={open}
      handleClose={handleClose}
      closeOnBackgroundClick={false}
      showCrossBtn={false}
    >
      <InvoiceCreated
        message={`Invoice Created Successfully. Invoice Number: ${invoiceDetails?.invoiceNo}`}
        onClose={handleClose}
        invoiceDetails={invoiceDetails}
      />
    </AppModal>
  );
};

export default forwardRef(InvoiceCreatedModal);
