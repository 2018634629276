const useTags = (data, onShowMore) => {
  let tags = data || []
  let displayShowMore = false

  if (typeof tags === "string") {
    tags = data.split(",").map((s) => s.trim())
  }
  if (onShowMore) {
    displayShowMore = tags.length > 2
    tags = tags.slice(0, 2)
  }
  const onChangeTags = () => {
    // const {
    //   target: { value },
    // } = e
  }

  const onDeleteTags = () => {
    // TODO: delete tags when editable
  }
  return {
    tags,
    onChangeTags,
    onDeleteTags,
    displayShowMore,
  }
}

export default useTags
