import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import Api from "../../services/apiv2.service";
import {
  promotionPageMessage,
  WHATSAPP_CHANNEL_NAME,
  SMS_CHANNEL_NAME,
} from "./message-constants";
import { createWhatsAppForm, createSMSForm } from "./promotion-utils";
import useAnalytics from "./useAnalytics.createCampaign.hook";

const USE_TOKEN = true;

const useUpload = (
  channel_name = "",
  template_id,
  campaignName,
  retailerInfo,
  whatsappData,
  whatsappImage
) => {
  let history = useHistory();
  const [dragActive, setDragActive] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [confirmToSendAllCustomer, setConfirmToSendAllCustomer] =
    useState(false);
  const [sendingToAllCustomer, setSendingToAllCustomer] = useState(false);
  const {
    logSendAllCustomersBtnClick,
    logDragAndDropClicked,
    logFileUploadClicked,
    logBackBtnClick,
    logDeleteBtnClick,
    logMessageSent,
  } = useAnalytics();
  const { upload = "" } = useParams();
  channel_name = channel_name || upload;

  const handleNotificationClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setNotificationOpen(false);
      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
        channel_name !== SMS_CHANNEL_NAME
          ? history.push("/crm/promotion")
          : history.push("/crm/campaign-history");
      }, 3000);
    }
  };

  const handleShowLoader = () => {
    setShowLoader(!showLoader);
  };

  if (!campaignName && !whatsappData) {
    history.push("/crm/campaign-history");
  }

  const handleBack = () => {
    logBackBtnClick(channel_name);
    history.goBack();
  };

  const handleFileClick = (fileUploadRef) => {
    logFileUploadClicked(channel_name);
    fileUploadRef.current.click();
  };

  const handleDragOver = () => {
    setDragActive("image-dropping");
  };
  const handleDragLeave = () => {
    logDragAndDropClicked(channel_name);
    setDragActive("");
  };

  const changeHandler = (event) => {
    logFileUploadClicked(channel_name);
    setSelectedFile(event.target.files[0]);
  };

  const fileSizeInKBorMB = (totalBytes) => {
    if (totalBytes < 1000000) {
      return Math.floor(totalBytes / 1000) + "KB";
    } else {
      return Math.floor(totalBytes / 1000000) + "MB";
    }
  };

  const handleDeleteFiles = () => {
    logDeleteBtnClick(channel_name);
    setSelectedFile("");
  };

  const handleConfirmAllCustomersClick = () => {
    logSendAllCustomersBtnClick(channel_name);
    setConfirmToSendAllCustomer(!confirmToSendAllCustomer);
  };

  const handleSendingToAllCustomerClick = () => {
    setSendingToAllCustomer(true);
    setConfirmToSendAllCustomer(!confirmToSendAllCustomer);
  };

  const handleSubmit = async (sendAllCustomers = false) => {
    if (channel_name === SMS_CHANNEL_NAME) {
      const { retailerNumber, storeName } = retailerInfo;
      const smsFormData = createSMSForm(
        selectedFile,
        channel_name,
        template_id,
        retailerNumber,
        storeName
      );
      setNotificationOpen(true);
      const resp = await Api.postPromotionCampaign(smsFormData, "", USE_TOKEN);
      if (resp.data.status === "success") {
        logMessageSent(channel_name);
        setSuccessMsg(
          `${campaignName} sent to ${resp.data.rows} Customers successfully`
        );
      }
    } else {
      const { header, template, footer } = whatsappData;
      if (sendAllCustomers) setConfirmToSendAllCustomer(false);
      const whatsappFormData = createWhatsAppForm(
        selectedFile,
        WHATSAPP_CHANNEL_NAME,
        header,
        template,
        footer,
        whatsappImage,
        // sendingToAllCustomer
        sendAllCustomers
      );

      setNotificationOpen(true);
      const resp = sendAllCustomers
        ? await Api.whatsappSendCampaignAllCustomers(
            whatsappFormData,
            "",
            USE_TOKEN
          )
        : await Api.whatsappPostPromotionCampaign(
            whatsappFormData,
            "",
            USE_TOKEN
          );
      if (resp?.data?.status === "success") {
        logMessageSent(channel_name);
        setSuccessMsg(promotionPageMessage.whatsappSuccessfulMessage);
      }
    }
  };

  return {
    dragActive,
    selectedFile,
    handleFileClick,
    handleDragOver,
    handleDragLeave,
    changeHandler,
    fileSizeInKBorMB,
    handleDeleteFiles,
    handleSubmit,
    notificationOpen,
    handleNotificationClose,
    successMsg,
    handleBack,
    showLoader,
    handleShowLoader,
    confirmToSendAllCustomer,
    handleConfirmAllCustomersClick,
    sendingToAllCustomer,
    handleSendingToAllCustomerClick,
  };
};

export default useUpload;
