import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Grid, Button, Box } from "@mui/material";
import { Link, useRouteMatch } from "react-router-dom";
import useChannelAndCampaign from "./useChannelAndCampaign.hook";
import useStyles from "./css/style";
import whatsappImage from "./images/whatsApp.svg";
import smsImage from "./images/sms.svg";
import CampaignView from "./components/CampaignView";
import ChannelView from "./components/ChannelView";
import {
  WHATSAPP_CHANNEL_NAME,
  SMS_CHANNEL_NAME,
  channelTitles,
} from "./message-constants";
import useAnalytics from "./useAnalytics.createCampaign.hook";

export default function ChannelAndCampaign({
  setSelectedCampaignName,
  setRetailerInfo,
}) {
  let { url } = useRouteMatch();
  const classes = useStyles();
  const {
    disableContinueButton,
    templateSelectionData,
    handleCampaignSelection,
    handleChannelSelection,
    loadingData,
    templates,
  } = useChannelAndCampaign(setSelectedCampaignName, setRetailerInfo);
  const { smsTitle, whatsappTitle } = channelTitles;
  const { logContinueBtnClick } = useAnalytics();

  return (
    <>
      <div className="promotion_container">
        <div className="channel_container">
          <h3 className="channel_title">Select Channel</h3>
        </div>
        <div className="promtion_card_container">
          <Box className={classes.promotionContainer}>
            <Grid container spacing={2}>
              <ChannelView
                image={whatsappImage}
                label={WHATSAPP_CHANNEL_NAME}
                title={whatsappTitle}
                templateSelectionData={templateSelectionData}
                handleChannelSelection={handleChannelSelection}
              />
              <ChannelView
                image={smsImage}
                label={SMS_CHANNEL_NAME}
                title={smsTitle}
                templateSelectionData={templateSelectionData}
                handleChannelSelection={handleChannelSelection}
              />
            </Grid>
          </Box>
        </div>
      </div>
      {templateSelectionData?.channel_name === SMS_CHANNEL_NAME && (
        <CampaignView
          loadingData={loadingData}
          templates={templates}
          templateSelectionData={templateSelectionData}
          handleCampaignSelection={handleCampaignSelection}
          channelName={SMS_CHANNEL_NAME}
        />
      )}
      {templateSelectionData?.channel_name === WHATSAPP_CHANNEL_NAME && (
        <CampaignView
          loadingData={loadingData}
          templates={templates}
          templateSelectionData={templateSelectionData}
          handleCampaignSelection={handleCampaignSelection}
          channelName={WHATSAPP_CHANNEL_NAME}
        />
      )}
      <div
        style={{
          textAlign: "right",
          marginTop: "10px",
        }}
      >
        <Button
          variant="contained"
          endIcon={<ChevronRightIcon />}
          component={Link}
          to={`${url}/${templateSelectionData.channel_name}/${templateSelectionData.campaign_id}`}
          disabled={disableContinueButton}
          onClick={() =>
            logContinueBtnClick(templateSelectionData.channel_name)
          }
        >
          Continue
        </Button>
      </div>
    </>
  );
}
