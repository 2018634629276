import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RootTable } from "../../../../helpers/RootTable";
import StyledTableCell from "../../../../helpers/StyledTableCell";
import { withStyles } from "@mui/styles";
import AppNoData from "../../../../components/AppNoData";
import moment from "moment";

const CustomTableCell = withStyles(() => ({
  root: {
    minWidth: 150,
    textAlign: "center !important",
    fontSize: "14px",
    lineHeight: "1.5rem !important",
    fontWeight: "500 !important",
    padding: "6.5px !important",
    color: "#527466",
  },
}))(TableCell);

const headerTitles = [
  "Installation Id",
  "Created At",
  "Date of Installation",
  "Customer Name",
  "Phone No.",
  "Category",
  "Brand",
  "Product",
  "Status",
];

export default function InstallationBrowserView({ installationData }) {
  const tableRef = React.useRef();

  return (
    <div style={{ marginTop: "10px" }}>
      <Paper elevation={0}>
        <TableContainer
          ref={tableRef}
          sx={{
            maxHeight: {
              xs: "calc(100vh - 150px)",
              md: "calc(100vh - 300px)",
            },
          }}
        >
          <RootTable>
            <Table stickyHeader aria-label="sticky table">
              <TableHead height="50">
                <TableRow>
                  {headerTitles.map((title, i) => (
                    <StyledTableCell key={i} align="center">
                      <Typography
                        sx={{ fontSize: 13 }}
                        color="white"
                        fontWeight="bold"
                      >
                        {title}
                      </Typography>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="Body">
                {installationData.additionalInfo &&
                installationData.additionalInfo.tickets.length ? (
                  installationData.additionalInfo.tickets.map((data, i) => {
                    return (
                      <TableRow key={data._id}>
                        <CustomTableCell>{data.requestId}</CustomTableCell>
                        <CustomTableCell>
                          {moment(data.createdAt).local().format("DD-MMM-YYYY")}
                        </CustomTableCell>
                        <CustomTableCell>
                          {moment(data.appointmentDate)
                            .local()
                            .format("DD-MMM-YYYY")}
                        </CustomTableCell>
                        <CustomTableCell>{data.customerName}</CustomTableCell>
                        <CustomTableCell>{data.customerPhone}</CustomTableCell>
                        <CustomTableCell>{data.category}</CustomTableCell>
                        <CustomTableCell>{data.brand}</CustomTableCell>
                        <CustomTableCell>{data.product}</CustomTableCell>
                        <CustomTableCell>{data.status}</CustomTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={headerTitles.length}>
                      <AppNoData />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </RootTable>
        </TableContainer>
      </Paper>
    </div>
  );
}
