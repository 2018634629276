import React from "react";
import { Card, Grid } from "@mui/material";
import ChannelCard from "../../../components/ChannelCard";

const ChannelView = ({
  image,
  label,
  title,
  templateSelectionData,
  handleChannelSelection,
}) => {
  return (
    <Grid item xs={12} sm={6}>
      <Card
        variant="outlined"
        className={`channel-card ${
          templateSelectionData.channel_name === label ? "selected" : ""
        }`}
      >
        <ChannelCard
          Icon={image}
          channelLabel={label}
          title={title}
          data={templateSelectionData}
          onHandleChange={handleChannelSelection}
        />
      </Card>
    </Grid>
  );
};

export default ChannelView;
