import React from "react";
import { imageTypes } from "./message-constants";

const OPEN_EDIT_IMAGE_MODAL = true;

const ImagesView = (props) => {
  const { data, handleImageClick, activeTab } = props;
  const { _id, url } = data;
  const { LANDSCAPE } = imageTypes;

  return (
    <>
      <div
        className="images-view"
        style={{
          backgroundImage: `url(${url})`,
          width: `${activeTab === LANDSCAPE ? "25%" : "15%"}`,
        }}
        onClick={() => handleImageClick(_id, url, OPEN_EDIT_IMAGE_MODAL)}
      ></div>
    </>
  );
};

export default ImagesView;
