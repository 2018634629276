import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker.hook";

const useAnalytics = () => {
  const { logEvent } = useAnalyticsEventTracker("Order History Page");

  const logPageLoaded = () => {
    logEvent("ORDER_HISTORY_PAGE_LANDING", "Order History Page Loaded");
  };

  const logSearchbarClick = () => {
    logEvent("SEARCHBAR_CLICK", "Click on search bar");
  };

  const logDatePickerClicked = (type = "") => {
    logEvent(
      `${type.split(" ").join("_").toUpperCase()}_CLICK_OH`,
      `Click on ${type}`
    );
  };

  const logStatusDropdownClick = () => {
    logEvent("STATUS_FILTER_OH", "Status Filter CTA");
  };

  const logOrderReportBtnClick = () => {
    logEvent("REPORT_DOWNLOAD_OH", "Order Report CTA");
  };

  const logCreateInvoiceBtnClick = () => {
    logEvent("CREATE_INVOICE_OH", "Create Invoice CTA");
  };

  const logEditInvoiceBtnClick = () => {
    logEvent("EDIT_INVOICE_OH", "Edit Invoice CTA");
  };

  const logViewInvoiceBtnClick = () => {
    logEvent("VIEW_INVOICE_IN_OH", "View Invoice CTA");
  };

  const logCategoryBtnClick = () => {
    logEvent("CATEGORY_OH", "Category CTA");
  };

  const logCreateInvoiceCategoryBtnClick = () => {
    logEvent(
      "CREATE_INVOICE_IN_CATEGORY_POPUP_OH",
      "Create Invoice CTA inside category page"
    );
  };

  const logEditInvoiceCategoryBtnClick = () => {
    logEvent(
      "CLOSE_BUTTON_IN_CATEGORY_POPUP_OH",
      "Close Popup CTA inside category page"
    );
  };

  const logViewInvoiceCategoryBtnClick = () => {
    logEvent(
      "VIEW_INVOICE_IN_CATEGORY_POPUP_OH",
      "View Invoice CTA inside category page"
    );
  };

  const logCloseInvoiceCategoryBtnClick = () => {
    logEvent(
      "CLOSE_BUTTON_IN_CATEGORY_POPUP_OH",
      "Close Popup CTA inside category page"
    );
  };

  return {
    logPageLoaded,
    logSearchbarClick,
    logDatePickerClicked,
    logStatusDropdownClick,
    logOrderReportBtnClick,
    logCreateInvoiceBtnClick,
    logEditInvoiceBtnClick,
    logViewInvoiceBtnClick,
    logCategoryBtnClick,
    logCreateInvoiceCategoryBtnClick,
    logEditInvoiceCategoryBtnClick,
    logViewInvoiceCategoryBtnClick,
    logCloseInvoiceCategoryBtnClick,
  };
};

export default useAnalytics;
