import { useState, useEffect } from "react";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import Api from "../../../services/apiv2.service";
import useAnalytics from "../useAnalytics.createCampaign.hook";

const useWhatsappView = () => {
  const [editImage, setEditImage] = useState({});
  const [previewImage, setPreviewImage] = useState("");
  const [activeTab, setActiveTab] = useState("Landscape");
  const [templateData, setTemplateData] = useState({});
  const [disableContinueButton, setDisableContinueButton] = useState();
  const [images, setImages] = useState([]);

  const history = useHistory();
  const { url } = useRouteMatch();
  const { template_id } = useParams();
  const { logImageSelectioninWhatsApp } = useAnalytics();

  const handleImageClick = (id, imgUrl, showModal) => {
    logImageSelectioninWhatsApp();
    setEditImage({
      editImageId: id,
      editImageUrl: imgUrl,
      openModal: showModal,
    });
  };

  const handlePrevieImageClick = (previewImgUrl) => {
    setPreviewImage(previewImgUrl);
    setEditImage({ ...editImage, openModal: false });
  };

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const fetchTemplateEdit = async () => {
      let res = await Api.whatsappTemplatesEdit(`?id=${template_id}`);
      if (res?.data?.status === "success") {
        // res.data.data.variables.storename = getStoreName();
        setTemplateData(res.data.data);
        setImages(res.data.data?.images);
      }
    };
    fetchTemplateEdit();
  }, [template_id]);

  return {
    templateData,
    handleBack,
    previewImage,
    activeTab,
    setActiveTab,
    handleImageClick,
    editImage,
    setEditImage,
    handlePrevieImageClick,
    url,
    disableContinueButton,
    setDisableContinueButton,
    images,
  };
};

export default useWhatsappView;
