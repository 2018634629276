import { useState } from "react";

const useDrawer = () => {
  const [drawerActive, setDrawerActive] = useState(false);
  const [activeOrderId, setActiveOrderId] = useState(null);

  const handleDrawerActiveStatus = (orderId) => {
    setActiveOrderId(orderId);
    setDrawerActive(!drawerActive);
  };

  return { drawerActive, handleDrawerActiveStatus, activeOrderId };
};

export default useDrawer;
