import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { isAdmin, isLoggedInUser } from "./helpers/common-utils";
// import GenerateInvoice from "./screens/generate-invoice";
import GenerateInvoiceV3 from "./screens/generate-invoice-v3";
import Offers from "./screens/Offer";
import Profile from "./screens/profile";
import Retailers from "./screens/retailers";
import SignIn from "./screens/sign-in/SignIn";
import SignUpPage from "./screens/sign-up";
import TransactionHistory from "./screens/transaction-history";
import CustomerDetails from "./screens/CustomerDetails";
import CollectPayment from "./screens/CollectPayment";
import Promotion from "./screens/Promotion";
import CampaignHistory from "./screens/CampaignHistory";
import Logout from "./screens/Logout";
import Installation from "./screens/Installation";
import BulkDownload from "./screens/BulkDownload";
import Help from "./screens/Help";
import QuickCheckout from "./screens/QuickCheckout";
// import { useFeature } from "@growthbook/growthbook-react";

import { Installation_Status } from "./config";
import StoreLocator from "./screens/StoreLocator";

const AppRoutes = ({ location }) => {
  // const isEnabled = useFeature("goretail_quickcheckout").on;

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isLoggedInUser() ? <Component {...props} /> : <Redirect to="/sign-in" />
      }
    />
  );

  const PublicRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => <Component {...props} />} />
  );

  const AdminPrivateRoute = ({ component: Component, ...rest }) => {
    const admin = isLoggedInUser() && isAdmin();
    return (
      <Route
        {...rest}
        render={(props) =>
          admin ? <Component {...props} /> : <Redirect to="/sign-in" />
        }
      />
    );
  };

  return (
    <Switch location={location}>
      <PrivateRoute
        path="/transaction-history"
        component={TransactionHistory}
      />
      <AdminPrivateRoute path="/retailers" component={Retailers} />
      <PrivateRoute path="/offers" component={Offers} />
      <PrivateRoute path="/profile" component={Profile} />
      {/* <PrivateRoute path="/generate-invoice" component={GenerateInvoice} /> */}
      <PrivateRoute path="/generate-invoice" component={GenerateInvoiceV3} />
      <PrivateRoute path="/crm/customer" component={CustomerDetails} />
      <PrivateRoute path="/crm/promotion" component={Promotion} />
      <PrivateRoute path="/crm/campaign-history" component={CampaignHistory} />
      <PrivateRoute path="/bulk-download" component={BulkDownload} />
      <PrivateRoute path="/quick-checkout" component={QuickCheckout} />
      <PrivateRoute path="/collect-payment" component={CollectPayment} />
      <PrivateRoute path="/help" component={Help} />
      <PrivateRoute path="/logout" component={Logout} />
      {Installation_Status && (
        <PrivateRoute path="/installation" component={Installation} />
      )}

      {/* <PrivateRoute path="/settings" component={Settings} /> */}
      {/* <PrivateRoute path="/support" component={Support} /> */}
      <PublicRoute path="/sign-in" component={SignIn} />
      <PublicRoute path="/sign-up" component={SignUpPage} />
      <PublicRoute path="/store-locator" component={StoreLocator} />
      <Redirect to="/sign-in" />
    </Switch>
  );
};

export default AppRoutes;
