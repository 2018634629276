import React from "react";
import { FormControl, Grid, Hidden, TextField } from "@mui/material";
import { Box } from "@mui/system";
import {
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import "./style.scss";
import { useField } from "formik";
import { isValidDate } from "../../../../../helpers/common-utils";
import useSearchEditExist from "../../../../../hooks/useSearchEditExist";

const InvoiceDate = ({ storeInvoiceDate, formErrors, setFormErrors }) => {
  const [field, meta] = useField("invoiceDate");
  const { checkEditExist } = useSearchEditExist();
  let disableDatePicker = false;

  let isEditExist = checkEditExist("edit");

  if (isEditExist) {
    disableDatePicker = true;
  }

  const handleChange = (invoiceDate) => {
    storeInvoiceDate(invoiceDate);

    if (invoiceDate) {
      if (!isValidDate(invoiceDate)) {
        setFormErrors("invoiceDate", "Invalid Date!");
      } else if (new Date(invoiceDate) > new Date()) {
        setFormErrors("invoiceDate", "Future dates not allowed!");
      } else {
        setFormErrors("invoiceDate", "");
      }
    }
  };

  let errorMessage = (meta.touched && meta.error) || formErrors.invoiceDate;

  return (
    <Grid className={"invoice-details-container"}>
      <h3 className={"header"}>Invoice Details</h3>

      <Box className={"invoiceDateField"}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormControl variant="standard" fullWidth>
            <Hidden mdDown>
              <DesktopDatePicker
                label="Purchase Date *"
                inputFormat="dd/MM/yyyy"
                onChange={handleChange}
                disabled={disableDatePicker}
                value={field.value} //"01/01/2010"}
                maxDate={new Date()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="invoiceDate"
                    className="custom-input"
                    size="small"
                    error={errorMessage}
                    disabled={true}
                    helperText={errorMessage}
                  />
                )}
              />
            </Hidden>

            <Hidden mdUp>
              <MobileDatePicker
                label="Purchase Date *"
                inputFormat="dd/MM/yyyy"
                onChange={handleChange}
                disabled={disableDatePicker}
                value={field.value} //"01/01/2010"}
                maxDate={new Date()}
                // {...field}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="invoiceDate"
                    className="custom-input"
                    size="small"
                    error={errorMessage}
                    helperText={errorMessage}
                  />
                )}
              />
            </Hidden>
          </FormControl>
        </LocalizationProvider>
      </Box>
    </Grid>
  );
};

export default InvoiceDate;
