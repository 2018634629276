import { getDateFormat } from "./date";

export const isAdmin = () => !!localStorage.getItem("IS_ADMIN");

export const getToken = () => localStorage.getItem("API_TOKEN");

export const getRefreshAccessToken = () =>
  localStorage.getItem("REFRESH_TOKEN");

export const isLoggedInUser = () => !!localStorage.getItem("API_TOKEN");

export const getGostorLink = () => localStorage.getItem("GOSTORE_URL");

export const getStoreName = () => localStorage.getItem("storeName");

export const handleGoStoreLink = () => {
  window.open(getGostorLink(), "_blank");
};

export const replaceItemAtIndex = (arr, index, newValue) => {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
};

export const removeItemAtIndex = (arr, index) => {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export const decrementQty = (product) => {
  let quantity = product.quantity > 1 ? --product.quantity : product.quantity;
  let totalPrice = Number(quantity) * Number(product.salesPrice);
  let finalPrice = totalPrice + (totalPrice * Number(product.gst)) / 100;
  return {
    ...product,
    quantity,
    totalPrice,
    finalPrice,
  };
};

export const incrementQty = (product) => {
  let quantity = product.quantity >= 1 ? ++product.quantity : product.quantity;
  let totalPrice = Number(quantity) * Number(product.salesPrice);
  let finalPrice = totalPrice + (totalPrice * Number(product.gst)) / 100;
  return {
    ...product,
    quantity,
    totalPrice,
    finalPrice,
  };
};

export const getExtras = ({
  products,
  receivedAmount = 0,
  additionalCharges = [],
  discounts = [],
  isRoundOff = false,
}) => {
  let grandTotal = 0,
    taxableAmount = 0,
    totalAmount = 0,
    balanceAmount = 0,
    roundOff = 0;

  products.map((product) => {
    taxableAmount += product.totalPrice;
    totalAmount += product.finalPrice;
    return null;
  });

  grandTotal = totalAmount;

  let sumOfAdditionalCharges = additionalCharges.reduce(
    (accumulator, current) => accumulator + Number(current.amount),
    0
  );

  //  TODO: Discount should be provide on totalPrice exlusing additional chanrges or not.
  let productDiscount = 0;
  let tempDiscount = 0;
  let tempTotalPrice = totalAmount;
  discounts?.map((disc) => {
    if (disc.type === "PERCENT") {
      tempDiscount = (tempTotalPrice * (disc.percent || 0)) / 100;
    } else if (disc.type === "FLAT") {
      tempDiscount = disc.amount || 0;
    }
    productDiscount = Number(productDiscount) + Number(tempDiscount || 0);
    tempTotalPrice -= tempDiscount;
    tempDiscount = 0;

    return null;
  });

  totalAmount = tempTotalPrice || totalAmount;

  totalAmount += sumOfAdditionalCharges;

  if (isRoundOff) {
    let temp = Math.ceil(totalAmount);
    roundOff = temp - totalAmount;
    totalAmount = temp;
  }

  balanceAmount = totalAmount - receivedAmount;

  return {
    grandTotal,
    taxableAmount,
    totalAmount,
    balanceAmount,
    roundOff,
  };
};

export const formatCurrency = (value = 0, fractions = 2) => {
  return Number(value).toLocaleString("en-IN", {
    maximumFractionDigits: fractions,
    style: "currency",
    currency: "INR",
  });
};

export const formatDate = (date) => {
  const today = new Date(date);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return mm + "/" + dd + "/" + yyyy;
};

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};

export const toTitleCase = (str = "") => {
  if (str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (s) {
        return s.charAt(0).toUpperCase() + s.substring(1);
      })
      .join(" ");
  } else {
    return "NA";
  }
};

export const limitNumber = (number, limit = 0) => {
  return Number(number).toFixed(limit);
};

export const pincodeValidate = (pincode) => {
  if (pincode) {
    return String(pincode);
  } else {
    return "NA";
  }
};

export const getFormatRetailerDetails = (retailerDetails) => {
  console.log(retailerDetails.businessDetails.state);
  // debugger;
  if (retailerDetails && retailerDetails?.businessDetails) {
    let data = {
      retailerId: String(retailerDetails.retailerId),
      firstName: retailerDetails.bankDetails.firstname,
      lastName: retailerDetails.bankDetails.lastname,
      address1: retailerDetails.businessDetails.address_line_1,
      address2: retailerDetails.businessDetails.address_line_2,
      mobile: retailerDetails.businessDetails.phone_number,
      gstin: retailerDetails.kyc.gstin,
      pincode: pincodeValidate(retailerDetails.businessDetails.pincode),
      city: toTitleCase(retailerDetails.businessDetails.city),
      district: toTitleCase(retailerDetails.businessDetails.district),
      state: toTitleCase(retailerDetails.businessDetails.state),
      email: retailerDetails.businessDetails.email,
      signatureUrl: retailerDetails.businessDetails.signature,
      storeName: retailerDetails.businessDetails.store_name,
    };

    return data;
  }
};

export const getFormatInvoicePayload = (
  cpResponse,
  cpPayload,
  invoiceInCpData
) => {
  let payload = {
    customerDetails: {
      firstName: invoiceInCpData.firstName,
      lastName: invoiceInCpData.lastName,
      address1: invoiceInCpData.address1,
      address2: invoiceInCpData.address2,
      customerId: null,
      district: invoiceInCpData.district,
      email: invoiceInCpData.email,
      mobile: cpPayload.customerDetails.mobile,
      pincode: String(invoiceInCpData.pincode),
      retailerId: cpPayload.retailerDetails.retailerId,
      state: invoiceInCpData.state,
    },
    retailerDetails: cpPayload.retailerDetails,
    products: cpPayload.products,
    discounts: [],
    additionalCharges: [],
    discountAmount: String(cpPayload.discountAmount || 0),
    invoiceDiscount: String(cpPayload.invoiceDiscount || 0),
    grandTotal: String(cpPayload.grandTotal || 0),
    taxableAmount: String(cpPayload.taxableAmount || 0),
    totalAmount: String(cpPayload.totalAmount || 0),
    receivedAmount: String(cpPayload.receivedAmount || ""),
    termsAndConditions: String(invoiceInCpData.termsAndConditions),
    roundOff: String(cpPayload.roundOff || 0),
    isRoundOff: Boolean(cpPayload.isRoundOff),
    fullyPaid: Boolean(cpPayload.fullyPaid),
    invoiceDate: getDateFormat(invoiceInCpData.invoiceDate),
    invoiceNo: "",
    orderId: String(cpResponse.orderId),
    generatedPaymentId: String(cpResponse.generatedPaymentId),
    type: cpPayload.type,
    mop: cpResponse.mop,
    paymentRefId: "",
  };
  return payload;
};

export const appendParams = (url, params) => {
  let paramsStr = "";

  Object.keys(params).map((key, index) => {
    paramsStr += (index > 0 ? "&" : "") + key + "=" + params[key];
    return null;
  });

  return url + "?" + paramsStr;
};

export const useQueryParams = (query = null) => {
  const result = {};
  new URLSearchParams(query || window.location.search).forEach((value, key) => {
    result[key] = value;
  });
  return result;
};

export const strFormatter = (str = "") => {
  return toTitleCase(str.split("_").join(" "));
};

export const replaceDoubleBraces = (str, result) => {
  let obj = {};
  for (const val in str) {
    if (typeof str[val] === "string") {
      obj = {
        ...obj,
        [val]: str[val].replace(/{{(.+?)}}/g, (_, g1) => result[g1] || g1),
      };
    } else {
      obj = { ...obj, [val]: str[val] };
    }
  }
  return obj;
};

export const eachKeyEmptyCheck = (obj) => {
  for (const key in obj) {
    if (obj[key] === "" || obj[key] === null) return true;
  }
};
