import { useEffect, useState } from "react";
import { wp_CONTENT_CATEGORY_IDS } from "../../helpers/constants";
import Api from "../../services/apiv2.service";

const useOffer = () => {
  const [loadingPost, setLoadingPost] = useState(true);
  const [respData, setRespData] = useState(null);

  const getOffers = async () => {
    const resp = await Api.getPostUpdates(wp_CONTENT_CATEGORY_IDS.OFFERS);
    setRespData(resp.data);
    setLoadingPost(false);
  };

  useEffect(() => {
    getOffers();
  }, []);

  return { respData, loadingPost };
};

export default useOffer;
