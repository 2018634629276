import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CircularProgress, Box } from "@mui/material";
import useOfferCard from "./useOfferCard";
import OfferDetailsModal from "../OfferDetailsModal";

export default function OfferCard({ post }) {
  const { imageURL, loading } = useOfferCard(post.featured_media);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Card
        sx={{ maxWidth: "100%", cursor: "pointer" }}
        onClick={() => setOpen(!open)}
      >
        {loading ? (
          <Box style={{ textAlign: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <div className="fill-card-img-div">
            <CardMedia
              component="img"
              style={{ maxHeight: 350 }}
              image={imageURL?.guid?.rendered}
              alt="green iguana"
            />
          </div>
        )}
        <CardContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography gutterCenter variant="h5" component="div">
            {post.title.rendered}
          </Typography>
        </CardContent>
      </Card>
      <OfferDetailsModal
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        post={post}
      />
    </>
  );
}
