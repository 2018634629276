import {
  Box,
  Button,
  TableCell,
  TableRow,
  Link,
  Drawer,
  Chip,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import moment from "moment";
import React from "react";
import {
  formatCurrency,
  formatDate,
  formatPaymentMode,
  getTransactionStatus,
} from "../../helpers/constants";
import AppDatePicker from "../AppDatePicker";
import AppInput from "../AppInput";
import useDrawer from "./useDrawer.hook";
import DrawerLayout from "./component/DrawerLayout";
import useTransactionHistoryCardHook from "./useTransactionHistoryCardHook";
import ModalContentORLoader from "../ModalContentORLoader";
import useRedirectionDialog from "../../hooks/useRedirectionDialog";
import useAnalytics from "../../screens/transaction-history/useAnalytics.hook";
import useCreateInstallation from "../../hooks/useCreateInstallation";
import Loader from "../../components/Loader";

const CustomTableCell = withStyles(() => ({
  root: {
    minWidth: 150,
    textAlign: "center !important",
    fontSize: "14px",
    lineHeight: "1.5rem !important",
    fontWeight: "500 !important",
    padding: "6.5px !important",
    color: "#527466",
  },
}))(TableCell);

const TransactionHistoryCard = (data) => {
  const {
    orderId,
    created_at,
    orderStatus,
    categoryName,
    totalOrderAmount,
    settlementAmount = 0,
    paid_through,
    customerMobile,
    settlementDate,
    storeName,
    retailerName,
    adminUser,
    onEditSettlement,
    paymentRefrence,
    orderType,
    invoiceUrl,
    invoiceNumber,
    totalCategories,
    editOrderInProgress,
    editOrderLimitExceeded,
    invoiceState,
    handleInvoiceModalOpen,
    products,
    paymentDate,
    handleRaiseRequestOpen,
    installationFeature,
    fasterCheckout,
  } = data;
  const {
    edit,
    setEdit,
    error,
    handleEditSettlementData,
    editSettlementData,
    settledAmount,
    setSettledAmount,
    settledDateTime,
    setSettledDateTime,
  } = useTransactionHistoryCardHook(
    orderId,
    settlementAmount,
    totalOrderAmount,
    settlementDate,
    onEditSettlement,
    created_at
  );
  const { drawerActive, handleDrawerActiveStatus, activeOrderId } = useDrawer();

  const { dialogActive, setRedirectionURL } = useRedirectionDialog();

  const {
    logCreateInvoiceBtnClick,
    logEditInvoiceBtnClick,
    logViewInvoiceBtnClick,
    logCategoryBtnClick,
    logCloseInvoiceCategoryBtnClick,
  } = useAnalytics();
  const { createInstallationLoading } = useCreateInstallation();

  const handleInvoiceState = (invoiceState, invoiceUrl) => {
    switch (invoiceState) {
      case "NOT_INITIATED": {
        return (
          <>
            <Button
              variant="outlined"
              size="small"
              style={{ fontSize: "10px" }}
              color="primary"
              onClick={() => {
                logCreateInvoiceBtnClick();
                setRedirectionURL(
                  `/generate-invoice?orderId=${orderId}&redirectFrom=order-history&cp=1${
                    fasterCheckout ? "&qc=1" : ""
                  }`
                );
              }}
            >
              Create invoice
            </Button>
            <Button
              variant="outlined"
              size="small"
              style={{ fontSize: "10px", marginTop: "10px" }}
              color="primary"
              onClick={() => handleInvoiceModalOpen(orderId)}
            >
              Upload Invoice
            </Button>
          </>
        );
      }
      case "SYSTEM_GENERATED": {
        return (
          <Button
            variant="outlined"
            size="small"
            style={{ fontSize: "10px" }}
            color="primary"
            onClick={() => {
              logEditInvoiceBtnClick();
              setRedirectionURL(
                `/generate-invoice?invoiceNumber=${invoiceNumber}&edit=1`
              );
            }}
            disabled={editOrderLimitExceeded || editOrderInProgress}
          >
            Edit invoice
          </Button>
        );
      }
      case "USER_GENERATED": {
        return (
          <Button
            variant="outlined"
            size="small"
            style={{ fontSize: "10px", marginTop: "10px" }}
            color="primary"
            onClick={() => handleInvoiceModalOpen(orderId)}
          >
            {invoiceUrl === "" ? "Upload Invoice" : "Re-Upload Invoice"}
          </Button>
        );
      }
      default:
        return "";
    }
  };

  return (
    <>
      {createInstallationLoading && <Loader open={createInstallationLoading} />}
      <TableRow
        key={orderId}
        style={{
          backgroundColor: invoiceState === "NOT_INITIATED" ? "" : "#CCEFD1",
        }}
      >
        <CustomTableCell>{orderId}</CustomTableCell>
        <CustomTableCell>
          {/* {moment(created_at).format("DD-MMM-YYYY")} */}
          {moment(paymentDate).format("DD-MMM-YYYY")}
        </CustomTableCell>
        <CustomTableCell>{getTransactionStatus(orderStatus)}</CustomTableCell>
        {adminUser && (
          <>
            <CustomTableCell>{storeName || "-"}</CustomTableCell>
            <CustomTableCell style={{ width: 100 }}>
              {paymentRefrence || "-"}
            </CustomTableCell>
            <CustomTableCell>{retailerName || "-"}</CustomTableCell>
          </>
        )}
        <CustomTableCell style={{ minWidth: 180 }}>
          {customerMobile || "-"}
        </CustomTableCell>
        <CustomTableCell>
          <Button
            onClick={() => {
              logCategoryBtnClick();
              handleDrawerActiveStatus(orderId);
            }}
          >
            {categoryName}{" "}
            {totalCategories > 0 ? (
              <Chip
                label={`+${totalCategories}`}
                style={{
                  backgroundColor: "#338F6B",
                  color: "white",
                  marginLeft: "5px",
                }}
              />
            ) : null}
          </Button>
        </CustomTableCell>
        <CustomTableCell>₹ {totalOrderAmount}</CustomTableCell>
        {/* {adminUser && ( */}
        <CustomTableCell style={{ fontSize: "12px" }}>
          {/* {orderType ? orderType.replace("_", " ") : "-"} */}
          {orderType}
        </CustomTableCell>
        {/* )} */}
        <CustomTableCell style={{ minWidth: 160, fontSize: "12px" }}>
          {formatPaymentMode(paid_through)}
        </CustomTableCell>

        {/* INVOICE */}
        <CustomTableCell>
          {invoiceUrl && invoiceUrl !== "" ? (
            <Link
              href={invoiceUrl}
              onClick={logViewInvoiceBtnClick}
              target="_blank"
              rel="noreferrer"
            >
              {invoiceNumber}
            </Link>
          ) : (
            "-"
          )}
          {handleInvoiceState(invoiceState, invoiceUrl)}
        </CustomTableCell>
        {installationFeature && (
          <CustomTableCell>
            {products.map((product) => {
              if (product.installable) {
                return (
                  <div key={product.hsn}>
                    <p style={{ fontSize: "11px" }}>
                      {product.brand} - {product.model_name}
                    </p>
                    <span>
                      <Button
                        size="small"
                        variant="outlined"
                        sx={{ mr: "5px" }}
                        onClick={() => handleRaiseRequestOpen(product, orderId)}
                        disabled={
                          product.request_already_raised ||
                          invoiceState === "NOT_INITIATED"
                        }
                      >
                        {invoiceState !== "NOT_INITIATED"
                          ? product.request_already_raised
                            ? "Request Raised"
                            : "Raise Request"
                          : "Create Invoice to raise Request"}
                      </Button>
                    </span>
                  </div>
                );
              } else {
                return (
                  <div key={product.hsn}>
                    <p style={{ fontSize: "11px" }}>
                      {product.brand} - {product.model_name}
                    </p>
                    <span>
                      <Button
                        size="small"
                        variant="outlined"
                        sx={{ mr: "5px" }}
                        disabled={true}
                      >
                        Not Serviceable
                      </Button>
                    </span>
                  </div>
                );
              }
            })}
          </CustomTableCell>
        )}

        <CustomTableCell style={{ minWidth: 200 }}>
          {(edit && (
            <Box>
              <AppInput
                type="Number"
                value={settledAmount}
                onChange={setSettledAmount}
                placeholder="Settlement Amount"
                error={!!error.priceError}
                helperText={error.priceError}
              />
            </Box>
          )) ||
            formatCurrency(settlementAmount)}
        </CustomTableCell>
        <CustomTableCell style={{ minWidth: 210 }}>
          {(edit && (
            <Box>
              <AppDatePicker
                name="Settlement Date"
                label="Settlement Date"
                value={settledDateTime}
                onChange={(date) => {
                  if (moment(date).isValid()) {
                    const pickedDate = moment(date);
                    const selectedDateTime = moment();
                    selectedDateTime.date(pickedDate.date());
                    selectedDateTime.month(pickedDate.month());
                    selectedDateTime.year(pickedDate.year());
                    setSettledDateTime(selectedDateTime);
                  }
                }}
                error={!!error.dateError}
                helperText={error.dateError}
                minDate={new Date(created_at)}
                maxDate={new Date()}
              />
            </Box>
          )) ||
            // Dirty hack: need to fix API for date format
            formatDate(`${settlementDate}Z`)}
        </CustomTableCell>

        {adminUser && (
          <CustomTableCell>
            {(edit && (
              <Box display="flex">
                <Button
                  variant="contained"
                  onClick={handleEditSettlementData}
                  style={{ marginRight: 5 }}
                >
                  Submit
                </Button>
                <Button variant="outlined" onClick={() => setEdit(false)}>
                  Cancel
                </Button>
              </Box>
            )) || (
              <Button
                size="small"
                variant="contained"
                onClick={editSettlementData}
                disabled={orderStatus === "SETTLEMENT_COMPLETED"}
              >
                Edit
              </Button>
            )}
          </CustomTableCell>
        )}
      </TableRow>
      <Drawer
        BackdropProps={{
          invisible: true,
          sx: { backgroundColor: "transparent", opacity: 0.4 },
        }}
        PaperProps={{
          sx: {
            width: "350px",
          },
        }}
        anchor="right"
        open={drawerActive}
        onClose={handleDrawerActiveStatus}
      >
        <DrawerLayout
          onClose={() => {
            logCloseInvoiceCategoryBtnClick();
            handleDrawerActiveStatus();
          }}
          orderDetails={{
            orderId,
            totalOrderAmount,
            invoiceUrl,
            invoiceNumber,
            payment_date: moment(created_at).format("DD-MMM-YYYY"),
          }}
          activeOrderId={activeOrderId}
        />
      </Drawer>
      <ModalContentORLoader
        open={dialogActive}
        title="Redirecting..."
        hideClose={true}
        msg="Please wait while we redirect you to Create Invoice Screen"
      />
    </>
  );
};

export default TransactionHistoryCard;
