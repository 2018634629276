import { useEffect, useState } from "react";

const useOnlineHook = () => {
  const [isOnline, setIsOnline] = useState(false);
  const [showNetworkMsg, setShowNetworkMsg] = useState(false);

  useEffect(() => {
    let intervalNetowrk = setInterval(function () {
      setIsOnline(window.navigator?.onLine);
      if (window.navigator?.onLine) {
        setTimeout(() => setShowNetworkMsg(false), 1000);
      } else {
        setShowNetworkMsg(true);
      }
    }, 1000);
    return () => clearInterval(intervalNetowrk);
  }, []);
  return {
    isOnline,
    showNetworkMsg,
  };
};

export default useOnlineHook;
