/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Api, { endpoints } from "../../../services/api.service";
import useAnalytics from "../useAnalytics.hook";

const PAGE_SIZE = 10;

const useCustomerDetails = () => {
  const [customerData, setCustomerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const { logPageLoaded } = useAnalytics();

  const fetchCustomerDetails = async (pageNo) => {
    const queryData = {
      pageSize: PAGE_SIZE,
      pageNo: pageNo,
    };
    const response = await Api.get(endpoints.getCustomerDetails, queryData);
    setLoading(false);
    setCustomerData(response);
  };

  useEffect(() => {
    if (pageNo) {
      fetchCustomerDetails(pageNo - 1);
    }
  }, [pageNo]);

  useEffect(() => {
    logPageLoaded();
  }, []);

  const handleSetPage = (val) => {
    setPageNo(val);
  };

  return { customerData, handleSetPage, pageNo, PAGE_SIZE, loading };
};

export default useCustomerDetails;
