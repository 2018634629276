import { useEffect, useState } from "react";
import Api from "../../services/apiv2.service";
import useAnalytics from "./useAnalytics.createCampaign.hook";
import { WHATSAPP_CHANNEL_NAME } from "./message-constants";

const useChannelAndCampaign = (setSelectedCampaignName, setRetailerInfo) => {
  const [disableContinueButton, setDisableContinueButton] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [templateName, setTemplateName] = useState(null);
  const [templateSelectionData, settemplateSelectionData] = useState({
    channel_name: WHATSAPP_CHANNEL_NAME,
    campaign_id: "",
    campaign_data: "",
  });
  const { logChannelClick, logCampaignSelection } = useAnalytics();

  const handleChannelSelection = (channel_name) => {
    logChannelClick(channel_name);
    settemplateSelectionData({
      ...templateSelectionData,
      channel_name: channel_name,
      campaign_id: "",
    });
  };

  const handleCampaignSelection = (
    campaign_id,
    template_name,
    campaign_data,
    channelName
  ) => {
    logCampaignSelection(template_name, channelName);
    settemplateSelectionData({
      ...templateSelectionData,
      campaign_id,
      campaign_data,
    });
    setTemplateName(template_name);
  };

  useEffect(() => {
    setSelectedCampaignName({
      templateName,
      data: templateSelectionData.campaign_data,
      campaingWhatsappData: templateSelectionData,
    });
  }, [
    templateName,
    templateSelectionData.campaign_data,
    setSelectedCampaignName,
    templateSelectionData,
  ]);

  useEffect(() => {
    const channelLists = {
      whatsapp: Api.whatsappTemplates,
      sms: Api.smsTemplates,
    };
    async function fetchTemplates(channel) {
      const res = await channelLists[channel]();
      if (res?.data?.status === "success") {
        setTemplates(res.data?.data);
        setRetailerInfo({
          storeName: res.data.storeName,
          retailerNumber: res.data.retailerNumber,
        });
        setLoadingData(false);
      }
    }
    fetchTemplates(templateSelectionData.channel_name);
    // eslint-disable-next-line
  }, [templateSelectionData.channel_name]);

  useEffect(() => {
    if (
      templateSelectionData.channel_name &&
      templateSelectionData.campaign_id !== ""
    ) {
      setDisableContinueButton(false);
    } else {
      setDisableContinueButton(true);
    }
  }, [templateSelectionData]);

  return {
    disableContinueButton,
    templateSelectionData,
    handleCampaignSelection,
    handleChannelSelection,
    templates,
    loadingData,
  };
};

export default useChannelAndCampaign;
