const STRING_GOPAY = "GOPAY";
const STRING_GOSTOR_GOPAY = "GOSTOR_GOPAY";
const STRING_GOSTOR = "GOSTOR";

export const loginTypes = {
  [STRING_GOPAY]: (res, handleStorageData) => {
    handleStorageData(res);
  },
  [STRING_GOSTOR_GOPAY]: (res, handleStorageData) => {
    handleStorageData(res, true);
  },
  [STRING_GOSTOR]: (res) => {
    const win = window.open(res.login_url, "_blank");
    win.focus();
  },
};
