import * as yup from "yup";
export function addDays(days) {
  var result = new Date();
  result.setDate(result.getDate() + days);
  return result;
}

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const validationSchema = yup.object({
  name: yup
    .string()
    .label("Customer Name")
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .max(40)
    .required(),
  mobile: yup
    .string()
    .required("Phone Number is a required field")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "too short")
    .max(10, "too long"),
  address1: yup
    .string()
    .matches("^[a-zA-Z0-9_ ]*$", "Please enter valid Address")
    .required("Address 1  required"),
  pincode: yup.string().required("Valid Pincode Required"),
  brand: yup.string().required("Brand is required"),
  category: yup.string().required("Category is required"),
  subcategory: yup.string().required("Subcategory is required"),
});

export const MIN_DATE_INVOICE_DATE = "01-01-2022";
export const DATE_FORMAT = "YYYY-MM-DD";

export const initialValues = {
  name: "",
  mobile: "",
  address1: "",
  pincode: "",
  brand: "",
  category: "",
  subcategory: "",
};
