/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

const ErrorListener = ({ isSubmitting, errors, values, onError }) => {
  const [wasSubmitting, setWasSubmitting] = useState(isSubmitting);
  useEffect(() => {
    if (isSubmitting === false && wasSubmitting === true) {
      // serialize errors and send to analytics endpoint
      onError && onError();
    }
  }, [isSubmitting, wasSubmitting, errors, values]);

  useEffect(() => {
    setWasSubmitting(isSubmitting);
  }, [isSubmitting]);

  return null;
};

export default ErrorListener;
