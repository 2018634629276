import React from "react";
import useStyles from "./style";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Backdrop,
  Box,
  Modal,
  Fade,
  Typography,
} from "@mui/material";

const AppModal = ({
  title,
  showModal,
  closeOnBackgroundClick = true,
  handleClose,
  showCrossBtn = true,
  children,
}) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={showModal}
      onClose={closeOnBackgroundClick && handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showModal}>
        <Box className={classes.container}>
          <Box className={classes.headerContainer}>
            <Typography className={classes.header}>{title}</Typography>
            {showCrossBtn && (
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          <Box className={classes.content}>{children}</Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AppModal;
