import React from "react";
import { Drawer, IconButton } from "@mui/material/";
import { useTheme } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import Storefront from "@mui/icons-material/Storefront";
import { Logo } from "../../assets/images";
import { AdminRoutes, ROUTES } from "./NavRoutes";
import { isAdmin } from "../../helpers/common-utils";
import { getGostorLink } from "../../helpers/common-utils";
import DrawerHeader from "./DrawerHeader";
import useAppDrawer from "./hook/useAppDrawer";
import SidebarLink from "./SidebarLink";
import GostorRedirectionModal from "./component/GostorRedirectionModal";
import SidebarWithDropDown from "./SidebarWithDropDown";
import useAnalytics from "./useAnalytics.hook";
import EligibilityButton from "./EligibilityButton";
import { useGrowthBook } from "@growthbook/growthbook-react";

export default function AppDrawer({
  open,
  drawerWidth,
  handleDrawerClose,
  isMobile,
}) {
  const theme = useTheme();
  const growthbook = useGrowthBook();
  let allFeatures = growthbook.getFeatures();

  const AppRoutes = isAdmin() ? AdminRoutes : ROUTES;

  const { openModalStatus, handleClose, handleOpen } = useAppDrawer();

  const { logGostorLanding } = useAnalytics();

  return (
    <>
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            borderRight: "0px",
          },
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant={!isMobile ? "persistent" : "temporary"}
        anchor="left"
        open={open}
      >
        <DrawerHeader style={{ marginBottom: "40px" }}>
          <img
            src={Logo}
            alt="logo"
            style={{ width: "150px", padding: "10px" }}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon sx={{ color: "white" }} />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        {AppRoutes.map((route, index) => {
          const isActive = window.location.href.indexOf(route.path) > -1;
          const Component = route.IconComponent;
          if (
            Object.keys(allFeatures).indexOf(route.featureName) > -1 &&
            growthbook.isOn(route.featureName) === false
          ) {
            return null;
          }

          if (route?.type === "Modal") {
            return (
              <EligibilityButton
                key={route.path}
                isActive={isActive}
                Icon={Component}
                route={route}
              />
            );
          } else {
            return (
              <SidebarWithDropDown
                badgeCount={route.badgeCount}
                key={route.path}
                isActive={isActive}
                Icon={Component}
                route={route}
              />
            );
          }
        })}
        {getGostorLink() && (
          <SidebarLink
            label="GoStor"
            handleFun={() => {
              logGostorLanding();
              handleOpen();
            }}
            Icon={Storefront}
          />
        )}
        {/* <SidebarLink label="Logout" handleFun={handleLogout} Icon={Logout} /> */}
      </Drawer>
      {/* redirection modal */}
      <GostorRedirectionModal
        handleClose={handleClose}
        openModalStatus={openModalStatus}
      />
    </>
  );
}
