import { styled } from "@mui/material/styles";
import { TableCell } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    margin: "10px",
  },
}));

export default StyledTableCell;
