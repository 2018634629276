import UserLeaveConfirmation from "../components/UserLeaveConfirmation";
import { showUserCofirmationURL } from "../components/UserLeaveConfirmation/confirmationURL";
import { globals } from "./globals";

const emptyValueCheck = (formValues) => {
  // eslint-disable-next-line
  let result = formValues?.products?.every((formValueObj) => {
    delete formValueObj.quantity;
    delete formValueObj.title;
    for (var key in formValueObj) {
      if (formValueObj[key] !== "") return true;
    }
  });
  return result;
};

const evaluateShowPrompt = (formValues) => {
  return formValues?.mobile !== "" ||
    formValues?.mop !== "" ||
    formValues?.paymentRefId !== "" ||
    emptyValueCheck(formValues)
    ? true
    : false;
};

export const onUserNavigateToOtherPage = (confirmOpen, setConfirmOpen) => {
  let qc = localStorage.getItem("qc");
  if (qc && window.location.pathname === "/collect-payment") {
    return false;
  }
  return (message, callback) => {
    const { collectPaymentData, allowRedirection } = globals.localStorageKeys;
    let getRedirectionStatus = localStorage.getItem(allowRedirection);
    let formValues = JSON.parse(localStorage.getItem(collectPaymentData));
    let showPromptCheck = evaluateShowPrompt(formValues);
    if (
      window.location.pathname.includes(showUserCofirmationURL) &&
      !getRedirectionStatus &&
      showPromptCheck
    ) {
      return UserLeaveConfirmation(
        message,
        callback,
        confirmOpen,
        setConfirmOpen
      );
    } else {
      let getRedirectionStatus = localStorage.getItem(allowRedirection);
      if (
        window.location.search.endsWith(
          globals.REDIRECT_FROM_COLLECT_PAYMENT_PAGE
        ) &&
        !getRedirectionStatus
      ) {
        return UserLeaveConfirmation(
          message,
          callback,
          confirmOpen,
          setConfirmOpen
        );
      } else {
        callback(true);
      }
    }
  };
};
