import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React from "react";
import moment from "moment";

export default function InstallationMobileView({ installationData }) {
  const { additionalInfo } = installationData;

  return (
    <>
      {additionalInfo?.tickets && additionalInfo.tickets.length > 0 ? (
        <TableContainer component={Paper}>
          {additionalInfo.tickets.map((tickets) => {
            return (
              <Table className="mobile-tickets">
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Installation Id
                    </TableCell>
                    <TableCell align="right">{tickets.requestId}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Created At
                    </TableCell>
                    <TableCell align="right">
                      {moment(tickets.createdAt)
                        .local()
                        .format("DD-MMM-YYYY hh:mm A")}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Date of Installation
                    </TableCell>
                    <TableCell align="right">
                      {moment(tickets.appointmentDate)
                        .local()
                        .format("DD-MMM-YYYY hh:mm A")}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Customer Name
                    </TableCell>
                    <TableCell align="right">{tickets.customerName}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Phone No.
                    </TableCell>
                    <TableCell align="right">{tickets.customerPhone}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Category
                    </TableCell>
                    <TableCell align="right">{tickets.category}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Brand
                    </TableCell>
                    <TableCell align="right">{tickets.brand}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Product
                    </TableCell>
                    <TableCell align="right">{tickets.product}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Status
                    </TableCell>
                    <TableCell align="right">{tickets.status}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            );
          })}
        </TableContainer>
      ) : (
        ""
      )}
    </>
  );
}
