/* eslint-disable no-unused-vars */
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material"
import React from "react"
import { Logo } from "../../assets/images"
import AppButton from "../../components/AppButton"
import AppInput from "../../components/AppInput"

const SignUp = () => (
  <>
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={3}
    >
      <img src={Logo} alt="logo" width={120} />
      <Box display="flex" alignItems="center">
        <Typography color="textSecondary">Already have an account?</Typography>
        <Box ml={2}>
          <AppButton href="/sign-in" variant="outlined">
            Login
          </AppButton>
        </Box>
      </Box>
    </Box>
    <Container
      maxWidth="md"
      sx={{ paddingTop: { xs: 5, md: 15 }, marginBottom: 5 }}
    >
      <Paper elevation={3}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            borderRight={1}
            borderColor="border.extraLight"
          >
            <Box p={5}>
              <Box mb={8}>
                <Typography variant="h6" color="primary">
                  Create your seller account on Gostor and grow your business.
                </Typography>
              </Box>
              <Typography variant="body1">All you need is</Typography>
              <Box my={3}>
                <Box display="flex">
                  <ArrowForwardIcon color="primary" />
                  <Typography variant="body1" fontWeight="600">
                    GSTIN
                  </Typography>
                </Box>
                <Box display="flex">
                  <ArrowForwardIcon color="primary" />
                  <Typography variant="body1" fontWeight="600">
                    Active Bank Account
                  </Typography>
                </Box>
              </Box>
              <Typography variant="body1" fontWeight={500}>
                and you will just be a few clicks away to start your journey
                with us.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box p={6}>
              <Grid item xs={12}>
                <AppInput variant="standard" label="Enter Your Name*" />
              </Grid>
              <Grid item xs={12} my={3}>
                <AppInput variant="standard" label="Enter Email Id*" />
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs={12} md={8}>
                  <AppInput variant="standard" label="Enter Mobile Number*" />
                </Grid>
                <Grid item xs={12} md={4} maxWidth={150}>
                  <AppButton
                    variant="outlined"
                    fullWidth
                    style={{ height: "100%", minHeight: 50, padding: 2 }}
                  >
                    Send OTP
                  </AppButton>
                </Grid>
              </Grid>
              <Box mt={7} mb={5} textAlign="center">
                <Typography variant="caption" textAlign="center">
                  By clicking on Sign up, you agree to our
                  <Link href="/"> Terms and Conditions</Link>
                </Typography>
                <Box mt={1}>
                  <AppButton fullWidth>Sign Up</AppButton>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  </>
)

export default SignUp
