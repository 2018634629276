import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker.hook";

const useAnalytics = () => {
  const { logEvent } = useAnalyticsEventTracker("Payment Popup");

  const logPopupLoaded = () => {
    logEvent("PAYMENT_POPUP_CP", "Payment Popup");
  };

  const logPopupClosed = () => {
    logEvent("CLOSE_PAYMENT_POPUP_CP", "Payment Close Popup");
  };

  const logPaymentSuccess = () => {
    logEvent("PAYMENT_SUCCESS_POPUP_CP", "Payment success Popup");
  };

  const logPaymentFailed = () => {
    logEvent("PAYMENT_FAILURE_POPUP_CP", "Payment Failed Popup");
  };

  return {
    logPopupLoaded,
    logPopupClosed,
    logPaymentSuccess,
    logPaymentFailed,
  };
};

export default useAnalytics;
