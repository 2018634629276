import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { createMarkup } from "../../utils";
import { Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "0px",
  left: "0px",
  width: "100%",
  height: "100vh",
  bgcolor: "background.paper",
  borderRaius: "10px",
  boxShadow: 24,
  overflow: "auto",
  p: 4,
  display: "flex",
  justifyContent: "center",
};

const OfferDetailsModal = ({ open, handleClose, post }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div style={{ width: "700px", maxWidth: "100%" }}>
          <Typography
            variant="h6"
            component="h6"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {post.title.rendered}
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, overflow: "auto" }}
          >
            <span
              className="wordpress-content"
              dangerouslySetInnerHTML={createMarkup(post.content.rendered)}
            />
          </Typography>
        </div>
      </Box>
    </Modal>
  );
};

export default OfferDetailsModal;
