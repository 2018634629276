import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material"
import React from "react"

const AppDialog = ({ title, open, handleClose, children }) => (
  <Dialog
    disablePortal
    onClose={handleClose}
    open={open}
    hideBackdrop
    fullScreen
    style={{ position: "absolute" }}
    PaperProps={{
      style: {
        borderRadius: 10,
        boxShadow: 3,
      },
    }}
  >
    <DialogTitle>
      <Typography color="text.light" fontWeight="bold">
        {title}
      </Typography>
    </DialogTitle>
    <DialogContent>{children}</DialogContent>
  </Dialog>
)

export default AppDialog
