import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    contentContainer: {
      padding: "6px",
      [theme.breakpoints.down("md")]: {
        padding: "0px",
      },
    },
    retailerContainer: {
      backgroundColor: "#F6F9F7",
      borderRadius: "10px",
      padding: "12px 0 30px",
      [theme.breakpoints.down("md")]: {
        backgroundColor: "#fff",
        padding: "0px",
      },
    },
    loadingContainer: {
      textAlign: "center",
    },
    tableContianer: {
      width: "50%",
      border: "none",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        fontSize: "11px",
      },
    },
    tableTitle: {
      width: "200px",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("md")]: {
        width: "100px",
      },
    },
    tableCell: {
      borderBottom: "none",
      color: "#527466",
    },
    tableRow: {
      borderBottom: "1px solid #8080802b",
    },
    bankDetails: {
      display: "grid",
      gridTemplateColumns: "15% 20%",
      padding: "16px",
      gap: "32px",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      },
    },
    header: {
      fontWeight: "550",
      color: "#2f4f4f",
    },
    headerCaption: {
      fontWeight: "400",
      fontSize: "14px",
    },
    divider: {
      display: "none",
      [theme.breakpoints.down("md")]: {
        display: "block",
        borderBottom: "1px solid #8080802b",
      },
    },
    saveButton: {
      marginBottom: 20,
      float: "right",
      marginRight: 10,
    },
    fullWidth: {
      width: "100%",
    },
    paddingFields: {
      padding: "7px 0",
    },
  })
);

export default useStyles;
