import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  loginBannerContainer: {
    padding: "20px",
  },
  bannerContainer: {
    textAlign: "center",
    marginTop: "100px",
    [theme.breakpoints.down("md")]: {
      marginTop: "0px",
    },
  },
  bannerImg: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
  },
  loginBGColor: {
    backgroundColor: "#F6F9F7",
    borderRadius: "30px",
    [theme.breakpoints.down("md")]: {
      backgroundColor: "transparent",
    },
  },
  storeDescContainer: {
    textAlign: "center",
    marginTop: "50px",
    [theme.breakpoints.down("md")]: {
      marginTop: "0px",
    },
  },
  loginSystemContainer: {
    padding: "20px",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    },
  },
  loginContainer: {
    height: "92vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      alignItems: "start",
    },
  },
  downloadLogo: {
    height: "92vh",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      alignItems: "start",
    },
  },
  alignDowloadLogo: {
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "200px",
    marginBottom: "50px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: "0px",
      width: "70%",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "50px",
    },
  },
}));

export default useStyle;
