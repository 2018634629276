import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material"
import React from "react"

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
))

const AppConfirmationDialog = ({
  open,
  handleClose,
  onSuccess,
  title,
  description,
  loading,
}) => (
  <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
    <DialogTitle>{title}</DialogTitle>
    <Box p={4}>
      <DialogContent style={{ marginBottom: 20, whiteSpace: "break-spaces" }}>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      {loading && (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      )}
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={onSuccess}
          autoFocus
          variant="contained"
          disabled={loading}
        >
          Yes
        </Button>
      </DialogActions>
    </Box>
  </Dialog>
)

export default AppConfirmationDialog
