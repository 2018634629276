//  Action.js

import Api from "../../../services/api.service";
import { endpoints } from "../../../services/api.service";
import {
  GET_RETAILER_DETAILS_ERROR,
  GET_RETAILER_DETAILS_LOADING,
  GET_RETAILER_DETAILS_SUCCESS,
} from "./constants";
import { useGenerateInvoiceContex } from "./contex";

const useRetailer = () => {
  const { dispatch } = useGenerateInvoiceContex();

  const getRetailsDetails = async () => {
    dispatch(getRetailsDetailsLoading());
    try {
      let data = await Api.get(endpoints.getRetailer);
      dispatch(
        getRetailsDetailsSuccess({
          ...data,
          termsAndConditions: data.retailerPolicy,
        })
      );
    } catch (error) {
      dispatch(getRetailsDetailsError(error));
    }
  };

  const getRetailsDetailsLoading = () => {
    return {
      type: GET_RETAILER_DETAILS_LOADING,
    };
  };

  const getRetailsDetailsSuccess = (retailerDetails) => {
    return {
      type: GET_RETAILER_DETAILS_SUCCESS,
      data: retailerDetails,
    };
  };

  const getRetailsDetailsError = (error) => {
    return {
      type: GET_RETAILER_DETAILS_ERROR,
      error,
    };
  };

  return {
    getRetailsDetails,
    getRetailsDetailsSuccess,
  };
};

export default useRetailer;
