import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "50px 40px",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        padding: "10px 20px",
      },
    },
    header: {
      fontSize: "14px",
      color: theme.palette.primary.dark,
      marginBottom: "10px",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
    list: {
      fontSize: "12px",
      color: theme.palette.primary.dark,
      border: "1px solid",
      borderRadius: "5px",
      padding: "20px",
      paddingLeft: "30px",
    },
  })
);

export default useStyles;
