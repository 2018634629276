import { useEffect, useRef, useState } from "react"
import { COMMON_OFFSET } from "../../helpers/constants"
import Api, { endpoints } from "../../services/api.service"

const useRetailers = () => {
  const tableRef = useRef(null)
  const [retailers, setRetailers] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [open, setOpen] = useState(false)
  const [filters, setFilters] = useState({})
  const [error, setError] = useState()
  const [allCategories, setAllCategories] = useState([])

  const itemsPerRow = COMMON_OFFSET
  const fetchRetailers = async () => {
    try {
      setLoading(true)
      const data = {
        page: page - 1,
        offSet: itemsPerRow,
        ...filters,
      }

      const { retailerData = [], retailerCount } = await Api.get(
        endpoints.retailers,
        data
      )
      setRetailers(retailerData)
      setTotalCount(retailerCount)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }
  const fetchCategories = async () => {
    try {
      const { data: categories = [] } = await Api.get(endpoints.getCategories)
      setAllCategories(categories.map((c) => c.name))
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  useEffect(() => {
    fetchRetailers()
    if (tableRef.current) tableRef.current.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filters])

  useEffect(() => {
    fetchCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFilterChange = (key, value) => {
    setPage(1)
    const updateFilters = { ...filters }
    updateFilters[key] = value
    setFilters(updateFilters)
  }

  return {
    tableRef,
    retailers,
    totalCount,
    loading,
    page,
    setPage,
    error,
    setError,
    filters,
    handleFilterChange,
    open,
    setOpen,
    itemsPerRow,
    allCategories,
  }
}

export default useRetailers
