import { useEffect, useRef, useState } from "react";
import usePikaso from "pikaso-react-hook";
import * as defaults from "./defaults";
import Api from "../../../services/apiv2.service";
import useAnalytics from "../useAnalytics.createCampaign.hook";

const WHEN_UNSELECT_LENGTH = 0;
const WHEN_SINGLE_ELEM_SELECT_LENGTH = 1;

export const useImageEditorToolBox = (editor) => {
  const selectedElement = useRef(null);
  const [toolBarVisibility, setToolBarVisibility] = useState(false);

  useEffect(() => {
    if (!editor) return;
    editor.on("selection:change", (data) => {
      if (data.shapes.length === WHEN_UNSELECT_LENGTH) {
        selectedElement.current = null;
        setToolBarVisibility(false);
      }
      if (data.shapes.length === WHEN_SINGLE_ELEM_SELECT_LENGTH) {
        const [shape] = data.shapes;
        selectedElement.current = shape;
        setToolBarVisibility(true);
      }
    });
  }, [editor]);

  const onTagBgChange = (color) => {
    if (selectedElement.current)
      selectedElement.current.updateTag({ fill: color });
  };

  const onTextPropertyChange = (attrName) => {
    return (e) => {
      const value = e.target ? e.target.value : e;
      if (selectedElement.current)
        selectedElement.current.updateText({ [attrName]: value });
    };
  };

  return { onTextPropertyChange, toolBarVisibility, onTagBgChange };
};

export const useImageEditor = (
  images,
  imageId,
  imageUrl,
  handlePrevieImageClick,
  drawInstructions = defaults.defaultTextDrawingsPayload
) => {
  // const drawings = useRef([]);
  const [containerRef, editor] = usePikaso(defaults.editorInit);
  const { logSaveBtnClickInWhatsApp } = useAnalytics();

  // const addToDrawing = (drawing) => {
  //   drawings.current.push(drawing);
  // };

  const loadJsonToCanvas = images.filter((image) => image._id === imageId)[0];

  useEffect(() => {
    if (!editor) return;
    // editor.board.background.setImageFromUrl(imageUrl);
    // drawInstructions.forEach((drawing) => {
    //   const { type, drawInfo } = drawing;
    //   const addedShape = editor.shapes[type].insert(drawInfo);
    //   addToDrawing(addedShape);
    // });

    editor.load(loadJsonToCanvas.raw);
  }, [drawInstructions, editor, imageUrl, loadJsonToCanvas]);

  const addNewTextDrawing = () => {
    const newElem = editor.shapes.label.insert(
      defaults.DEFAULT_LABEL_CONFIG.drawInfo
    );
    window.debugthis = newElem;
    console.log(newElem.node._id);
  };

  const handleClick = async () => {
    logSaveBtnClickInWhatsApp();
    const url = editor.export.toImage();
    const response = await fetch(url);
    const blob = await response.blob();
    setPreviewImageValue(blob);
  };

  const setPreviewImageValue = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    let resp = await Api.whatsappUploadImage(formData, "");
    if (resp?.ok) {
      handlePrevieImageClick(resp?.data);
    }
  };

  return { editor, containerRef, addNewTextDrawing, handleClick };
};
