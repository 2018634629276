/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import clsx from "clsx";
import useStyles from "./style";

import useExtrasHook from "./useExtra.hook";

import CustomSelect from "../CustomSelect";
import CustomInput from "../CustomInput";

import { formatCurrency } from "../../../../../helpers/common-utils";
import { CARD_LESS_EMI, paymentTypes } from "../../../../../constants/payments";
import { useLocation } from "react-router";
import {
  getPaymentModeLabel,
  getPaymentModes,
} from "../../../../../helpers/payments";
import { tenures } from "../../../../../constants/invoice";
import useAnalytics from "../../useAnalytics.hook";

const Extras = ({
  values,
  setFieldValue,
  disableFields,
  isCollectPayment,
  isEdit,
  invoiceData,
  invoiceInCp,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let checkQC = searchParams.get("qc");
  const { gsts, taxableAmount, finalPrice, amountPaid, balanceAmount } =
    useExtrasHook(values, invoiceData, checkQC);

  const classes = useStyles();
  const { logPaymentMethod } = useAnalytics();

  const handlePaymentChange = (paymentType) => {
    setFieldValue("type", paymentType);
    logPaymentMethod(paymentType);
  };

  useEffect(() => {
    if (values?.type !== "OTHERS") {
      setFieldValue("mop", "");
      setFieldValue("paymentRefId", "");
    }
  }, [values?.type]);

  return (
    <div className={classes.container}>
      <div className={classes.fieldWrapper}>
        <div className={classes.fieldLabel}>Sale Price</div>
        <div className={classes.fieldValue}>
          {formatCurrency(taxableAmount)}
        </div>
      </div>

      {gsts?.length > 0 && (
        <div className={classes.taxWrapper}>
          {gsts?.map((gst, index) => {
            return (
              <div key={`gst-${index}`} style={{ width: "100%" }}>
                <div className={classes.taxContainer}>
                  <div className={classes.fieldLabel}>
                    SGST@{gst.sgstPerc} %
                  </div>
                  <div className={classes.fieldValue}>
                    {formatCurrency(gst.sgstAmount)}
                  </div>
                </div>
                <div className={classes.taxContainer}>
                  <div className={classes.fieldLabel}>
                    CGST@{gst.cgstPerc} %
                  </div>
                  <div className={classes.fieldValue}>
                    {formatCurrency(gst.cgstAmount)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {amountPaid !== 0 && (
        <>
          <div className={classes.fieldWrapper}>
            <div className={classes.fieldLabel}>Amount Paid</div>
            <div className={clsx(classes.fieldValue, classes.totalAmount)}>
              {formatCurrency(amountPaid)}
            </div>
          </div>

          {!checkQC && (
            <div className={classes.fieldWrapper}>
              <div className={classes.fieldLabel}>
                {balanceAmount >= 0 ? "Balance Amount" : "Refund Amount"}
              </div>
              <div className={clsx(classes.fieldValue, classes.totalAmount)}>
                {formatCurrency(Math.abs(balanceAmount))}
              </div>
            </div>
          )}
        </>
      )}

      {!isEdit && (
        <div className={classes.fieldWrapper}>
          <div className={classes.fieldLabel}>Total Amount</div>
          <div className={clsx(classes.fieldValue, classes.totalAmount)}>
            {formatCurrency(finalPrice)}
          </div>
        </div>
      )}

      {(balanceAmount > 0 || !isEdit) && (
        <>
          {/* <div className={classes.fieldWrapper}>
          <CustomSelect
              id={`payment-type`}
              label={`Payment Type*`}
              name={"type"}
              options={paymentTypes}
            />
            </div> */}

          {!invoiceInCp && (
            <div className={classes.payments}>
              <div className={classes.paymentTitle}>Payment Details</div>
              <div className={classes.paymentTypeWrapper}>
                {paymentTypes?.map((payment, index) => {
                  return (
                    <div
                      key={`payment-type-${index}`}
                      className={clsx(
                        classes.paymentTypeContainer,
                        values.type === payment.value && classes.selectedWrapper
                      )}
                      style={{
                        cursor: disableFields ? "not-allowed" : "pointer",
                      }}
                      onClick={() =>
                        !disableFields && handlePaymentChange(payment.value)
                      }
                    >
                      <img
                        className={classes.paymentImg}
                        src={
                          values.type === payment.value
                            ? payment.activeIcon
                            : payment.icon
                        }
                        alt={payment.label}
                      />
                      {payment.label}
                      <div
                        className={clsx(
                          classes.checkBoxOterCircle,
                          values.type === payment.value && classes.selected
                        )}
                      >
                        {values.type === payment.value && (
                          <div className={classes.checkboxSelected}></div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {isCollectPayment &&
            values.type === "ONLINE" &&
            (location?.state?.mop || invoiceData?.mop) && (
              <div
                className={classes.fieldWrapper}
                style={{
                  marginTop: "20px",
                  borderTop: "1px solid #ccc",
                  borderBottom: "1px solid #ccc",
                }}
              >
                <div className={classes.fieldLabel}>Payment Mode</div>
                <div className={clsx(classes.totalAmount)}>
                  {getPaymentModeLabel(
                    location?.state?.mop || invoiceData?.mop
                  )}
                </div>
              </div>
            )}

          {values.type === "OTHERS" && (
            <div
              className={clsx(
                classes.fieldWrapper,
                classes.paymentFieldWrapper
              )}
            >
              <CustomSelect
                id={`mop`}
                label={`Payment Mode*`}
                name={"mop"}
                options={getPaymentModes()}
                disabled={disableFields}
              />

              <CustomInput
                name="paymentRefId"
                label="Payment Reference Id*"
                disabled={disableFields}
                autoComplete="off"
              />
            </div>
          )}

          {values.paidThrough === CARD_LESS_EMI && !isEdit && (
            <>
              {!checkQC && (
                <div className={classes.fieldWrapper}>
                  <div className={classes.fieldLabel}>Balance Amount</div>

                  <div
                    className={clsx(classes.fieldValue, classes.totalAmount)}
                  >
                    {formatCurrency(balanceAmount)}
                  </div>
                </div>
              )}

              <div
                className={clsx(
                  classes.fieldWrapper,
                  classes.paymentFieldWrapper
                )}
              >
                <CustomInput
                  name="receivedAmount"
                  label="Down Payment"
                  startingText="₹"
                  // disabled={disableFields}
                  autoComplete="off"
                />

                <CustomInput
                  name="emiAmount"
                  label="EMI Amount"
                  startingText="₹"
                  // disabled={disableFields}
                  autoComplete="off"
                />
              </div>

              <div
                className={clsx(
                  classes.fieldWrapper,
                  classes.paymentFieldWrapper
                )}
              >
                <CustomSelect
                  id={`tenure`}
                  label={`Tenure`}
                  name={"tenure"}
                  options={tenures}
                />

                <CustomInput
                  name="provider"
                  label="Provider"
                  disabled
                  autoComplete="off"
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Extras;
