import CashHover from "../assets/images/icons/payments/cash-hover.png";
import Cash from "../assets/images/icons/payments/cash.png";
import OnlineHover from "../assets/images/icons/payments/online-hover.png";
import Online from "../assets/images/icons/payments/online.png";
import OtherHover from "../assets/images/icons/payments/other-hover.png";
import Other from "../assets/images/icons/payments/other.png";

export const paymentModes = [
  // { label: "Cash", value: "CASH", show: true },
  { label: "Credit Card", value: "CREDIT_CARD", show: true },
  { label: "Credit Card EMI", value: "CREDIT_CARD_EMI", show: false },
  { label: "Debit Card", value: "DEBIT_CARD", show: true },
  { label: "UPI", value: "UPI", show: true },
  { label: "Net Banking", value: "NET_BANKING", show: true },
  { label: "Card Less EMI", value: "CARDLESS_EMI", show: false },
  { label: "Bajaj Finance", value: "BAJAJ_FINANCE", show: true },
  // { label: "Cheque", value: "CHEQUE", show: true },
  // { label: "Others", value: "OTHER", show: true },
];

export const paymentTypes = [
  {
    icon: Online,
    activeIcon: OnlineHover,
    label: "Online",
    value: "ONLINE",
  },
  {
    icon: Cash,
    activeIcon: CashHover,
    label: "Cash",
    value: "CASH",
  },
  {
    icon: Other,
    activeIcon: OtherHover,
    label: "Others",
    value: "OTHERS",
  },
];

export const paymentStatuses = {
  PAYMENT_COMPLETE: "PAYMENT_COMPLETE",
  PAYMENT_PENDING: "PAYMENT_PENDING",
  PAYMENT_FAILED: "PAYMENT_FAILED",
};

export const CARD_LESS_EMI = "CARDLESS_EMI";
