import React from "react";
const SectionHeader = ({ heading }) => {
  return (
    <div className="template-header-text">
      <span className="header-bar"></span>
      <span className="header-message"> {heading} </span>
    </div>
  );
};

export default SectionHeader;
