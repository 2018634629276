import { Box } from "@mui/material";
import React from "react";
import Autocomplete from "./AutoComplete";
import useCatalogue from "./useCatalogue";

function Catalogue({
  onSelectProduct,
  searchKey = null,
  clearOnSelect = false,
  label,
}) {
  const {
    searchCatalogue,
    getBrandDetails,
    catalogues,
    isLoading,
    noDataFound,
  } = useCatalogue({
    onSelectProduct,
    searchKey,
  });

  return (
    <Box>
      <Autocomplete
        searchKey={searchKey}
        name={`search-catalogue`}
        onChange={searchCatalogue}
        onValueSelect={getBrandDetails}
        list={catalogues}
        label={label}
        clearOnSelect={clearOnSelect}
        isLoading={isLoading}
        noDataFound={noDataFound}
        isCatalogue={true}
      />
    </Box>
  );
}

export default React.memo(Catalogue);
