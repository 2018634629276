import { useLocation } from "react-router-dom";

const useSearchEditExist = () => {
  const { search } = useLocation();
  const checkEditExist = (queryVariable) => {
    let searchParams = new URLSearchParams(search);
    if (searchParams.get(queryVariable) === "1") {
      return true;
    } else {
      return false;
    }
  };

  return { checkEditExist };
};

export default useSearchEditExist;
