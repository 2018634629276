import useAnalyticsEventTracker from "../../../../../analytics/useAnalyticsEventTracker.hook";

const useAnalytics = () => {
  const { logEvent } = useAnalyticsEventTracker("Confirmation Popup");

  const logConfirmationPopupLoaded = () => {
    logEvent("CONFIRMATION_POPUP_INV", "Confirmation Popup");
  };

  const logSubmitBtnClick = () => {
    logEvent("CONFIRMATION_POPUP_SUBMIT_INV", "Confirmation popup Submit CTA");
  };

  const logCancelBtnClick = () => {
    logEvent("CONFIRMATION_POPUP_CANCEL_INV", "Confirmation popup Cancel CTA");
  };

  const logCloseBtnClick = () => {
    logEvent("CONFIRMATION_POPUP_CLOSE_INV", "Confirmation popup close CTA");
  };

  return {
    logConfirmationPopupLoaded,
    logSubmitBtnClick,
    logCancelBtnClick,
    logCloseBtnClick,
  };
};

export default useAnalytics;
