import { create } from "apisauce";
import config from "../config";
import { getRefreshAccessToken, getToken } from "../helpers/common-utils";
import getRefreshToken from "./getRefreshToken";

const typeJSON = "application/json";
const api = create({
  // baseURL: config.apiUrl,
  headers: {
    Accept: typeJSON,
    "Content-Type": typeJSON,
  },
});

const apiURL = {
  gopay: config.apiGopayUrl,
  pos: config.apiPosUrl,
};

export const endpoints = {
  generateOtp: `${apiURL["pos"]}identity/v2/dashboard/send_otp`,
  login: `${apiURL["pos"]}identity/v2/dashboard/verify_otp`,
  getAccessToken: `${apiURL["pos"]}identity/v2/dashboard/refresh_access_token`,
  retailers: `${apiURL["gopay"]}identity/v2/dashboard/retailer_details`, // getback
  retailerProfile: `${apiURL["pos"]}identity/v2/dashboard/retailer_profile`,
  transactionHistoryAdmin: `${apiURL["pos"]}order/admin_orders`,

  editSettlement: `${apiURL["pos"]}transactions/v2/gopay/edit_settlement`,

  getCategories: `${apiURL["gopay"]}identity/v2/categories`,
  getCustomerDetails: `${apiURL["pos"]}customer`,
  getStateCity: `${apiURL["pos"]}identity/v2/address`,
  getRetailer: `${apiURL["pos"]}identity/v2/dashboard/retailer_profile`,
  getCategoriesAndBrands: `${apiURL["pos"]}category/categories-and-brands`,
  getHsnGstDetails: `${apiURL["pos"]}category/category-hsn-mapping`,
  generateInvoice: `${apiURL["pos"]}invoice/create`,
  modifyInvoice: `${apiURL["pos"]}invoice/edit`,
  getInvoiceData: `${apiURL["pos"]}invoice/fetch-data`,
  generateInvoiceCollectPayment: `${apiURL["pos"]}invoice/create/cp`,
  createPaymentLink: `${apiURL["gopay"]}transactions/v2/order/create`,
  verifyPayment: `${apiURL["pos"]}order/verify-payment-and-generate-invoice`,
};

// const getRefreshToken = async () => {
//   const { accessToken, refreshToken } = await get(endpoints.getAccessToken);
//   localStorage.setItem("API_TOKEN", accessToken);
//   localStorage.setItem("REFRESH_TOKEN", refreshToken);
//   return accessToken;
// };

const makeAPICall = async (requestConfig, retryRequest = false) => {
  const {
    url = "",
    method = "get",
    headers: defaultHeaders,
    data,
    escapeToken,
  } = requestConfig;

  const token = url.includes("refresh_access_token")
    ? getRefreshAccessToken()
    : getToken();

  const headers = { ...defaultHeaders };
  if (token && !escapeToken) {
    headers.Authorization = `Bearer ${token}`;
  }
  const response = await api[method](url, data, { headers });

  if (response) {
    if (
      response?.status === 200 ||
      response?.status === 201 ||
      response?.data?.status === "success"
    ) {
      return response.data;
    }
    if (
      response?.status === 401 &&
      response?.data?.message === "Unauthorized" &&
      !retryRequest
    ) {
      if (url.includes("refresh_access_token")) {
        localStorage.removeItem("API_TOKEN");
        localStorage.removeItem("REFRESH_TOKEN");
        window.location.reload();
      } else {
        await getRefreshToken();
        return makeAPICall(requestConfig, true);
      }
    }
    if (
      (response?.status === 200 ||
        response?.status === 201 ||
        response?.data?.status === "success") &&
      response?.data?.msg
    ) {
      return response.data.msg;
    } else {
      throw new Error(
        response?.data?.message || response?.data?.msg || "Something went wrong"
      );
    }
  }
  throw new Error("Something went wrong");
};

const get = (url, data, options) =>
  makeAPICall({ method: "get", url, data, ...options });
const post = (url, data, options) =>
  makeAPICall({ method: "post", url, data, ...options });
const put = (url, data, options) =>
  makeAPICall({ method: "put", url, data, ...options });
const deleteRequest = (url, data, options) =>
  makeAPICall({ method: "delete", url, data, ...options });

const Api = {
  get,
  post,
  put,
  delete: deleteRequest,
};

export default Api;
