import { useEffect, useState } from "react";
import Api from "../../services/apiv2.service";

const useInstallation = () => {
  const [installationData, setInstallationData] = useState([]);
  const [installationDataLoading, setInstallationDataLoading] = useState(true);
  const [installationDataError, setInstallationDataError] = useState(null);
  const [page, setPage] = useState(1);
  const [queryParameter, setQueryParameter] = useState(null);

  const getInstallationData = async () => {
    try {
      let data = {
        offset: 20,
        pageNo: page - 1,
      };
      if (queryParameter) {
        data["mobile"] = queryParameter;
      }
      const installationData = await Api.getInstallationDetails(data);
      if (installationData.data.status === "SUCCESS") {
        setInstallationData(installationData.data);

        setInstallationDataLoading(false);
      }
    } catch (e) {
      setInstallationDataError(e);
    }
  };

  const handleOnMobileSearch = (e) => {
    setPage(1);
    if (e.target.value.length === 10) {
      setQueryParameter(e.target.value);
      // getInstallationData(e.target.value);
    } else if (e.target.value.length === 0) {
      setQueryParameter(null);
      // getInstallationData();
    }
  };

  useEffect(() => {});

  const refetchData = () => {
    getInstallationData();
  };

  useEffect(() => {
    getInstallationData();
    // eslint-disable-next-line
  }, [page, queryParameter]);

  return {
    installationData,
    installationDataError,
    installationDataLoading,
    setPage,
    page,
    handleOnMobileSearch,
    refetchData,
  };
};

export default useInstallation;
