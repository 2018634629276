import React from "react";
import { Typography } from "@mui/material";
import { arzoooPro, arzoooProLogo } from "../../../assets/images";
import useStyle from "../style";

export default function ProImageDesign() {
  const classes = useStyle();
  return (
    <div className={classes.loginBannerContainer}>
      <div>{/* <img src={LogoGreen} width={150} alt="gostor" /> */}</div>
      <div style={{ textAlign: "center", margin: "30px" }}>
        <img
          className={classes.bannerImg}
          src={arzoooProLogo}
          alt="login banner"
        />
      </div>
      <div className={classes.bannerContainer}>
        <img className={classes.bannerImg} src={arzoooPro} alt="login banner" />
      </div>
      <div className={classes.storeDescContainer}>
        <Typography
          fontSize={25}
          fontWeight={500}
          sx={{ display: { xs: "none", sm: "block", md: "block" } }}
        ></Typography>
      </div>
    </div>
  );
}
