import { useEffect, useState } from "react";
import { wp_CONTENT_CATEGORY_IDS } from "../../helpers/constants";
import Api from "../../services/apiv2.service";

const useHelp = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [respData, setRespData] = useState(null);

  const getHelps = async () => {
    const resp = await Api.getPostUpdates(wp_CONTENT_CATEGORY_IDS.HELP);
    setRespData(resp.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getHelps();
  }, []);

  return { respData, isLoading };
};

export default useHelp;
