import ReactDOM from "react-dom";
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const UserLeaveConfirmation = (
  message,
  callback,
  confirmOpen,
  setConfirmOpen
) => {
  const container = document.createElement("div");

  container.setAttribute("custom-confirm-view", "");

  const handleConfirm = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
    setConfirmOpen(false);
  };

  const handleCancel = () => {
    ReactDOM.unmountComponentAtNode(container);
    callback(false);
  };
  document.body.appendChild(container);
  ReactDOM.render(
    <Dialog
      PaperProps={{
        style: { borderRadius: "25px", width: "400px" },
      }}
      className="hello"
      open={confirmOpen}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          height: "55px",
          padding: "20px 10px 20px 20px",
          fontWeight: "bold",
          borderBottom: "1px solid #ccc",
        }}
      >
        Confirmation
      </DialogTitle>
      <DialogContent style={{ padding: "30px" }}>
        <DialogContentText
          id="alert-dialog-description"
          style={{ color: "black" }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ backgroundColor: "#f3f3f3" }}>
        <Button onClick={handleCancel} variant="contained" color="inherit">
          NO
        </Button>
        <Button
          onClick={handleConfirm}
          autoFocus
          variant="outlined"
          style={{ backgroundColor: "#338F6B", color: "white" }}
        >
          YES
        </Button>
      </DialogActions>
    </Dialog>,

    container
  );
};

export default UserLeaveConfirmation;
