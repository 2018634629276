import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: "white",
    },
    content: {
      display: "flex",
      position: "relative",
      zIndex: 1,
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    warning: {
      padding: "10px 24px",
      color: "white",
      fontSize: "12px",
      position: "fixed",
      top: "70px",
      left: "0px",
      right: "0px",
      zIndex: 10,
    },
    qrContainer: {
      padding: "40px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
    title: {
      fontWeight: "600",
      padding: "20px 0px",
    },
    paymentContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "10px",
    },
    paymentMode: {
      display: "flex",
      alignItems: "center",
      border: "0.5px solid #1E4E3C64",
      borderRadius: "4px",
    },
    icon: {
      height: "18px",
      margin: "5px",
    },
    label: {
      fontSize: "8px",
      padding: "5px",
    },
    productContainer: {
      backgroundColor: theme.palette.color.bg,
      width: "300px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    productTitle: {
      fontSize: "18px",
      fontWeight: "600",
      padding: "10px 20px",
      borderBottom: "1px solid #ccc",
    },
    prductsList: {
      height: "550px",
      padding: "10px 20px",
      overflow: "auto",
      [theme.breakpoints.down("md")]: {
        height: "auto",
      },
    },
    productTile: {
      margin: "10px 0px",
      padding: "20px",
      backgroundColor: "white",
      borderRadius: "12px",
    },
    productImg: {
      width: "100px",
      height: "100px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    img: {
      width: "100%",
      height: "auto",
    },
    field: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "12px",
    },
    fieldLabel: {
      width: "35%",
    },
    fieldValue: {
      width: "65%",
      color: theme.palette.primary.main,
      wordBreak: "break-all",
      textAlign: "right",
    },
    toCapitalize: {
      textTransform: "capitalize",
    },
    paymentLinkContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "20px",
    },
    orTxt: {
      fontSize: "20px",
      fontWeight: "600",
    },
    networkError: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    networkErrorTxt: {
      fontSize: "14px",
      fontWeight: "600",
      textAlign: "justify",
      marginBottom: "10px",
    },
    copyLinkTxt: {
      fontSize: "16px",
      fontWeight: "600",
    },
    paymentLink: {
      marginTop: "20px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      padding: "10px",
      fontSize: "12px",
      fontWeight: "600",
      color: "#666",
    },
    successContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    redirectionTxt: {
      fontSize: "14px",
      fontWeight: "600",
      marginBottom: "20px",
    },

    inputContainer: {
      display: "flex",
      alignItems: "start",
      justifyContent: "space-between",
      rowGap: "10px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        rowGap: "0px",
        padding: "0px",
      },
    },
    inputFieldRight: {
      marginRight: "10px",
      [theme.breakpoints.down("md")]: {
        margin: "0px",
      },
    },
    inputFieldLeft: {
      marginLeft: "10px",
      [theme.breakpoints.down("md")]: {
        margin: "0px",
      },
    },
    txtField: {
      paddingBottom: "20px",
      [theme.breakpoints.down("md")]: {
        "& label": {
          fontSize: "12px",
        },
        "& input": {
          width: "100%",
          fontSize: "12px",
        },
      },
    },
    amountCalculations: {
      backgroundColor: "#e7f4ef",
      padding: "10px 20px",
      fontSize: "12px",
      [theme.breakpoints.down("md")]: {
        marginBottom: "25px",
      },
    },
    amountField: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    amount: {
      color: theme.palette.color.bg,
    },
    btnContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "20px",
      backgroundColor: theme.palette.color.bg,
      [theme.breakpoints.down("md")]: {
        justifyContent: "space-between",
      },
    },
    btn: {
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        width: "150px",
      },
    },
    btnCancel: {
      marginRight: "20px",
    },
  })
);

export default useStyles;
