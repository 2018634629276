import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker.hook";

const useAnalytics = () => {
  const { logEvent } = useAnalyticsEventTracker("Campaign History");

  const logPageLoaded = () => {
    logEvent(
      "CRM_CAMPAIGN_HISTORY_PAGE_LANDING",
      "Campaign History Page Landing"
    );
  };

  return {
    logPageLoaded,
  };
};

export default useAnalytics;
