import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker.hook";

const useAnalytics = () => {
  const { logEvent } = useAnalyticsEventTracker("Sidebar");

  const logLogoutEvent = () => {
    logEvent(`LOGOUT`, `Logout CTA`);
  };

  return {
    logLogoutEvent,
  };
};

export default useAnalytics;
