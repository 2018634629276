/* eslint-disable react-hooks/exhaustive-deps */
import {
  getFormatRetailerDetails,
  limitNumber,
  useQueryParams,
} from "../../../helpers/common-utils";
import { getDateFormat } from "../../../helpers/date";
import { useGenerateInvoiceContex } from "../store/contex";
import useInvoiceDetails from "../store/useInvoiceDetails";
import { useLocation } from "react-router";
import { useEffect, useRef, useState } from "react";
import useRedirectionDialog from "../../../hooks/useRedirectionDialog";
import useAnalytics from "./useAnalytics.hook";

const useGenerateInvoiceHook = () => {
  const [requestPayload, setPayload] = useState(null);

  const confirmationRef = useRef(null);
  const paymentRef = useRef(null);
  const invoiceCreatedRef = useRef(null);

  const {
    state: { retailerDetails, invoiceDetails, invoiceData },
  } = useGenerateInvoiceContex();
  const { generateInvoiceAction, modifyInvoiceAction, resetInvoiceDetails } =
    useInvoiceDetails();

  const params = useQueryParams();
  const location = useLocation();

  const { dialogActive, setRedirectionURL } = useRedirectionDialog();

  const { logPageLanding } = useAnalytics();

  const getFormatCustomerDetails = (formData, retailerDetails) => {
    let customer = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      mobile: formData.mobile,
      address1: formData.address1,
      address2: formData.address2 + (formData.area ? " " + formData.area : ""),
      pincode: formData.pincode,
      state: formData.state,
      district: formData.district,
      customerId: null,
      retailerId: String(retailerDetails?.retailerId),
    };
    return {
      ...customer,
      pincode: String(customer.pincode),
      customerId: null,
      retailerId: String(retailerDetails?.retailerId),
    };
  };

  const handleSubmit = (formData) => {
    if (!retailerDetails?.data) console.log("Retailer details not there!!!");

    let taxableAmount = 0;
    let totalAmount = 0;

    let products = formData?.products?.map((product) => {
      totalAmount += product.unitPrice
        ? Number(product.unitPrice) * Number(product.quantity)
        : Number(product.finalPrice);

      let gstValue = Number(product.gst) / 100 + 1;
      taxableAmount += Number(product.finalPrice) / gstValue;

      let productTaxableAmount = Number(product.finalPrice) / gstValue;
      let gst = productTaxableAmount * (Number(product.gst) / 100);
      let cgstPerc = Number(product.gst) / 2;

      let salesPrice = Number(productTaxableAmount);
      let finalPrice = product.unitPrice
        ? Number(product.unitPrice) * Number(product.quantity)
        : product.finalPrice;

      return {
        ...product,
        salesPrice: String(limitNumber(salesPrice, 2)),
        cgstPerc: String(limitNumber(cgstPerc, 2)),
        cgstAmount: String(limitNumber(gst / 2, 2)),
        sgstPerc: String(limitNumber(cgstPerc, 2)),
        sgstAmount: String(limitNumber(gst / 2, 2)),
        igstPerc: "0",
        igstAmount: "0",
        productDiscount: "0",
        taxableValue: String(limitNumber(productTaxableAmount, 2)),
        quantity: Number(product.quantity),
        discounts: [],
        finalPrice: String(limitNumber(Number(finalPrice), 2)),
        unitPrice: product.unitPrice
          ? product.unitPrice
          : Number(
              Number(product.finalPrice) / Number(product.quantity)
            ).toString(),
      };
    });
    let payload = {
      customerDetails: getFormatCustomerDetails(
        formData,
        retailerDetails?.data
      ),
      retailerDetails: {
        ...getFormatRetailerDetails(retailerDetails?.data),
        signatureUrl: formData.signatureUrl,
      },
      products,
      discounts: [], //  formData.discounts,
      additionalCharges: [], //  formData.additionalCharges,
      percentage: formData.percentage,
      discountAmount: String(formData.discountAmount || 0),
      invoiceDiscount: String(formData.invoiceDiscount || 0),
      grandTotal: String(formData.grandTotal || 0),
      taxableAmount: String(formData.taxableAmount || taxableAmount || 0),
      totalAmount: String(totalAmount || 0),
      receivedAmount: String(formData.receivedAmount || ""),
      termsAndConditions: String(formData.termsAndConditions),
      roundOff: String(formData.roundOff || 0),
      isRoundOff: Boolean(formData.isRoundOff),
      fullyPaid: Boolean(formData.fullyPaid),
      invoiceDate: getDateFormat(formData.invoiceDate),
      invoiceNo: "",
      orderId: "",
    };

    payload.type = formData.type === "CASH" ? "OFFLINE" : formData.type;
    payload.paymentRefId = formData.paymentRefId;

    if (formData?.paidThrough === "CARDLESS_EMI") {
      payload.receivedAmount = String(formData.receivedAmount || 0);
      payload.emiAmount = String(formData.emiAmount || "");
      payload.tenure = String(formData.tenure || "");
      payload.provider = String(formData.provider || "");
    }

    //  Edit Invoice Related
    const oldInvoiceData = invoiceData?.data?.invoiceDetails;

    if (params?.edit === "1") {
      payload.amountPaid = oldInvoiceData?.totalAmount;
      payload.generatedPaymentId = oldInvoiceData?.generatedPaymentId;
      payload.invoiceNo = oldInvoiceData?.invoiceNo;
      if (formData.mop || formData.type === "CASH")
        payload.mop = formData.type === "CASH" ? formData.type : formData.mop;
    }

    if (params?.edit !== "1") {
      if (formData.type !== "ONLINE") {
        payload.mop = formData.type === "CASH" ? formData.type : formData.mop;
      }

      if (params?.cp === "1") {
        let data = {
          mop: location?.state?.mop || payload?.mop,
          orderId:
            params?.orderId ||
            String(location?.state?.orderId || payload?.orderId),
        };
        payload = {
          ...payload,
          ...data,
        };
      }

      if (oldInvoiceData) {
        let data = {
          mop: oldInvoiceData?.mop || payload?.mop,
          orderId:
            params?.orderId ||
            String(location?.state?.orderId || payload?.orderId),
          generatedPaymentId: oldInvoiceData?.generatedPaymentId,
        };
        payload = {
          ...payload,
          ...data,
        };
      }
    }

    if (oldInvoiceData) {
      payload.orderId = oldInvoiceData.orderId;
    }
    console.log(payload);

    setPayload(payload);

    params?.edit === "1"
      ? modifyInvoiceAction({ params, payload })
      : generateInvoiceAction({ params, payload });
  };

  const onError = (errors = {}) => {
    const scrollToTopFields = [
      "firstname",
      "mobile",
      "address1",
      "pincode",
      "invoiceDate",
    ];
    let hasError = scrollToTopFields.some((field) => errors[field]);
    if (hasError) {
      window?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const resetGenerateInvoice = () => {
    localStorage.setItem("allowRedirection", true);
    resetInvoiceDetails();
    setRedirectionURL("/transaction-history");
  };

  const getPaymentData = (data) => {
    if (data.invoiceDetails.invoiceLink) {
      invoiceCreatedRef?.current?.openInvoiceModal(data.invoiceDetails);
    }
  };

  useEffect(() => {
    if (invoiceDetails?.data?.paymentLink) {
      let { paymentLink, generatedPaymentId } = invoiceDetails.data;
      paymentRef?.current?.collectPayment({
        payload: requestPayload,
        paymentLink,
        generatedPaymentId,
      });
    }

    if (invoiceDetails?.data?.invoiceNo) {
      invoiceCreatedRef?.current?.openInvoiceModal(invoiceDetails.data);
    }

    if (invoiceDetails?.data?.invoiceDetails?.invoiceNo) {
      invoiceCreatedRef?.current?.openInvoiceModal(
        invoiceDetails.data.invoiceDetails
      );
    }
  }, [invoiceDetails]);

  useEffect(() => {
    logPageLanding();
  }, []);

  return {
    confirmationRef,
    paymentRef,
    invoiceCreatedRef,
    dialogActive,
    handleSubmit,
    resetGenerateInvoice,
    onError,
    getPaymentData,
  };
};

export default useGenerateInvoiceHook;
