import React from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import AppHeader from "./AppHeader";
import { getStoreName } from "../../helpers/common-utils";
import useStyles from "./style";

const drawerWidth = 200;

const DrawerHeader = styled("div")(({ open }) => ({
  display: "flex",
  alignItems: "center",
  // necessary for content to be below app bar
  //   ...theme.mixins.toolbar,
  // height: "50px",
  width: `calc(100% - ${drawerWidth}px)`,
  justifyContent: "flex-end",
  // marginBottom: !open ? "40px" : 0,
}));

export default function MainChildren({
  open,
  children,
  handleDrawerOpen,
  pageTitle,
  isMobile,
}) {
  const classes = useStyles();
  return (
    <Box
      component="main"
      sx={{ flex: 1, width: `calc(100% - ${drawerWidth}px)` }}
      className={isMobile ? "mobile_spacing" : "desktop_spacing"}
    >
      <div
        style={{
          textAlign: "right",
          color: "white",
          textTransform: "capitalize",
        }}
      >
        <h3>
          {getStoreName() === "undefined" ? "" : `Hello, ${getStoreName()}`}
        </h3>
      </div>
      <DrawerHeader open={open} />
      {!open && (
        <>
          <AppHeader
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            pageTitle={pageTitle}
          />
        </>
      )}

      <Box className={classes.childrenContainer}>{children}</Box>
    </Box>
  );
}
