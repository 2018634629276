import { Add, History } from "@mui/icons-material";
import { Box, Popper } from "@mui/material";
import React from "react";
import { List, ListItem, NotFound } from "./utils";

const Options = ({
  list = [],
  searchKey,
  onSelectItem,
  setAnchorEl,
  anchorEl,
  isCatalogue,
}) => {
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <Popper
      style={{ zIndex: 10, maxWidth: `calc(100% - 20px)` }}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      placement={"bottom-start"}
    >
      <List>
        {list.length > 0 &&
          list.map((opt) => (
            <ListItem
              key={searchKey ? opt[searchKey] : opt}
              onClick={() => onSelectItem(opt)}
            >
              {searchKey ? opt[searchKey] : opt}
            </ListItem>
          ))}
        {list.length < 1 && (
          <>
            <ListItem disabled={true}>
              <History fontSize="medium" sx={{ mr: 1 }} />
              No Result Found
            </ListItem>
            {isCatalogue && (
              <NotFound onClick={() => onSelectItem({ [searchKey]: "others" })}>
                <Add fontSize="medium" sx={{ mr: 1 }} />
                <Box>Add Product Manually</Box>
              </NotFound>
            )}
          </>
        )}
      </List>
    </Popper>
  );
};

export default Options;
