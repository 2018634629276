import React, { useEffect } from "react";
import {
  handleUserTrackingAPI,
  TRACKING_VALUES,
} from "../../services/trackUser";
import { useHistory } from "react-router-dom";
import useAnalytics from "./useAnalytics.hook";

export default function Logout() {
  const history = useHistory();
  const { logLogoutEvent } = useAnalytics();

  useEffect(() => {
    logLogoutEvent();
    handleUserTrackingAPI(
      {
        event: TRACKING_VALUES["logout"],
        timestamp: Date.now(),
      },
      () => {
        localStorage.clear();
        history.push("/transaction-history");
      }
    );
    // eslint-disable-next-line
  }, []);

  return <div>Logout</div>;
}
