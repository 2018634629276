import { TextField } from "@mui/material";
import { useField } from "formik";
import React from "react";

const AppInput = ({ onChange, value, ...rest }) => (
  <TextField
    fullWidth
    size="small"
    value={value}
    defaultValue=""
    onChange={(e) => onChange(e.target.value)}
    {...rest}
  />
);

export const ControlledInput = ({ name, helperText, ...rest }) => {
  const [field, meta] = useField(name);
  const invalid = meta.touched && meta.error;
  return (
    <TextField
      variant="outlined"
      fullWidth
      size="small"
      error={invalid}
      helperText={invalid ? meta.error : helperText}
      {...field}
      {...rest}
    />
  );
};

export default AppInput;
