/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { isAdmin } from "../../helpers/common-utils";
import { COMMON_OFFSET, MIN_DATE, formatCSV } from "../../helpers/constants";
import Api, { endpoints } from "../../services/api.service";
import useAnalytics from "./useAnalytics.hook";
import useDebouncedEffect from "./useDebouncedEffect";

const useTransactionHistory = () => {
  const tableRef = useRef(null);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [storeName, setStoreName] = useState("");
  const [loading, setLoading] = useState(true);
  const [editLoading, setEditLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [settlementData, setSettlementData] = useState();
  const [askConfirmation, showConfirmation] = useState(false);
  const [error, setError] = useState();
  const [refetchData, setRefetchData] = useState(false);
  const { logPageLoaded, logOrderReportBtnClick } = useAnalytics();

  const adminUser = isAdmin();
  const itemsPerRow = COMMON_OFFSET;

  const getData = (currentPage = 0, isDownload = 0) => {
    const data = {
      pageNo: currentPage,
      offSet: itemsPerRow,
      isDownload,
      ...filters,
      startDate:
        filters.startDate && moment(filters.startDate).format("YYYY-MM-DD"),
      endDate: filters.endDate && moment(filters.endDate).format("YYYY-MM-DD"),
    };
    return Api.get(endpoints.transactionHistoryAdmin, data);
  };

  const downloadCSVData = async () => {
    logOrderReportBtnClick();
    try {
      setDownloading(true);
      const { orderData } = await getData(0, 1);
      if (orderData.length) {
        const csvData = formatCSV(orderData, adminUser);
        const aLink = document.createElement("a");
        aLink.download = "TransactionHistory.csv";
        aLink.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(
          csvData
        )}`;
        const event = new MouseEvent("click");
        aLink.dispatchEvent(event);
      }
    } catch (e) {
      setError(e);
    } finally {
      setDownloading(false);
    }
  };

  const handleFilterChange = (key, value) => {
    setPage(1);
    const updateFilters = { ...filters };
    updateFilters[key] = value;
    setFilters(updateFilters);
  };

  const fetchHistory = async () => {
    localStorage.removeItem("allowRedirection");
    if (tableRef.current) tableRef.current.scrollTo(0, 0);
    try {
      setLoading(true);
      const {
        orderData = [],
        orderCount = 0,
        storeDetails: { storeName: store = "" } = {},
      } = await getData(page - 1);
      setTransactionHistory(orderData);
      setTotalCount(orderCount);
      setStoreName(store);
      setRefetchData(false);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (refetchData) {
      fetchHistory();
    }
  }, [refetchData]);

  useDebouncedEffect(fetchHistory, [page, filters], 300);

  const handleEditSettlement = async () => {
    try {
      setEditLoading(true);
      await Api.put(endpoints.editSettlement, settlementData);
      await fetchHistory();
    } catch (e) {
      setError(e);
    } finally {
      setEditLoading(false);
      showConfirmation(false);
      if (settlementData.onComplete) {
        settlementData.onComplete();
      }
    }
  };

  const isInValidDate = (date, minDate = new Date(MIN_DATE)) => {
    if (date) {
      return !(
        moment(date).isValid() && moment(date).isBetween(minDate, moment())
      );
    }
    return false;
  };

  useEffect(() => {
    logPageLoaded();
  }, []);

  return {
    tableRef,
    transactionHistory,
    totalCount,
    loading,
    downloading,
    page,
    setPage,
    error,
    setError,
    filters,
    handleFilterChange,
    downloadCSVData,
    itemsPerRow,
    storeName,
    handleEditSettlement,
    showConfirmation,
    askConfirmation,
    settlementData,
    setSettlementData,
    editLoading,
    isInValidDate,
    fetchHistory,
  };
};

export default useTransactionHistory;
