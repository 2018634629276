import { Box, Pagination, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import "./styles.scss";
import useAnalytics from "./useAnalytics.hook";

const CustomPagination = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiPaginationItem-root": {
      borderRadius: 0,
      "&.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
      },
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
}))(Pagination);

const AppPagination = ({
  name = "",
  page,
  setPage,
  totalCount,
  itemsPerRow = 15,
}) => {
  const start = totalCount ? (page - 1) * itemsPerRow + 1 : 0;
  const count = page * itemsPerRow;
  const end = count < totalCount ? count : totalCount;
  const { logPaginationClick } = useAnalytics(name);

  return (
    <Box
      display="flex"
      alignItems="center"
      borderTop={1}
      borderColor="border.light"
      sx={{
        flexDirection: { md: "row", xs: "column" },
        padding: { md: "10px 30px", xs: 2 },
      }}
    >
      <Typography sx={{ marginBottom: { xs: 2, md: 0 } }}>
        showing {start} - {end} of {totalCount} entries
      </Typography>
      <Box flex={1}>
        {totalCount ? (
          <CustomPagination
            count={Math.ceil(totalCount / itemsPerRow)}
            page={page}
            onChange={(e, pageNum) => {
              logPaginationClick(pageNum > page ? "Next" : "Prev");
              setPage(pageNum);
            }}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default AppPagination;
