import { useState } from "react";
import { useHistory } from "react-router-dom";

const useRedirectionDialog = () => {
  let history = useHistory();
  const [dialogActive, setDialogActive] = useState(false);

  const setRedirectionURL = (url) => {
    setDialogActive(true);

    setTimeout(() => {
      setDialogActive(false);
      history.push(url);
    }, 3000);
  };

  return { dialogActive, setRedirectionURL };
};

export default useRedirectionDialog;
