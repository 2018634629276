import React, { useRef } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import { CloudUpload, DeleteOutlineOutlined } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import useStyles from "./css/style";

import useUpload from "./useUpload.hook";
import ModalContentORLoader from "../../components/ModalContentORLoader";
import { SMS_CHANNEL_NAME, redirectMessagePostCRM } from "./message-constants";
import useAnalytics from "./useAnalytics.createCampaign.hook";

export default function UploadData(props) {
  let { channel_name, template_id } = useParams();
  const fileUploadRef = useRef();
  const classes = useStyles();
  const { campaignName, retailerInfo, whatsappData, whatsappImage } = props;
  const {
    dragActive,
    selectedFile,
    handleFileClick,
    handleDragOver,
    handleDragLeave,
    changeHandler,
    fileSizeInKBorMB,
    handleDeleteFiles,
    handleSubmit,
    notificationOpen,
    handleNotificationClose,
    successMsg,
    handleBack,
    showLoader,
    handleShowLoader,
    confirmToSendAllCustomer,
    handleConfirmAllCustomersClick,
    sendingToAllCustomer,
    // handleSendingToAllCustomerClick,
  } = useUpload(
    channel_name,
    template_id,
    campaignName,
    retailerInfo,
    whatsappData,
    whatsappImage
  );
  const { logDownloadSampleBtnClicked } = useAnalytics();

  const { smsRedirectMsg, whatsappRedirectMsg } = redirectMessagePostCRM;
  const redirectMsg =
    channel_name === SMS_CHANNEL_NAME ? smsRedirectMsg : whatsappRedirectMsg;

  return (
    <div className="upload_container">
      <div
        className="upload_title"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <p style={{ fontWeight: "bold" }}>{campaignName}</p>
          <h3>Upload Customer Details</h3>
        </div>
        {whatsappData && (
          <div>
            <Button
              onClick={handleConfirmAllCustomersClick}
              style={{
                background: "#4E8C6E 0% 0% no-repeat padding-box",
                fontSize: "14px",
                color: "#FFFFFF",
                borderRadius: "8px",
              }}
            >
              Send To All Customer
            </Button>
          </div>
        )}
      </div>
      <div className={classes.fileUploadContainer}>
        <div
          className={`image-upload-wrap ${dragActive}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <input
            className="file-upload-input"
            type="file"
            ref={fileUploadRef}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={changeHandler}
          />
          <div className="drag-text">
            <CloudUpload fontSize="large" color="primary" />
            <h3>Drag and drop a file or select file</h3>
          </div>
        </div>
        <button
          className="file-upload-btn"
          type="button"
          onClick={() => handleFileClick(fileUploadRef)}
        >
          Add EXCEL/CSV
        </button>
        <div
          style={{
            textAlign: "right",
            marginTop: "20px",
            textDecoration: "underline",
          }}
        >
          <Link
            download={true}
            href="/sample_file/sample.csv"
            onClick={() => logDownloadSampleBtnClicked(channel_name)}
          >
            Download Sample File
          </Link>
        </div>
      </div>
      <div>
        {selectedFile && (
          <div className={classes.uploadedFilesContainer}>
            <Card variant="outlined">
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Typography>
                      <span style={{ fontWeight: "bold" }}>File Name :</span>{" "}
                      {selectedFile?.name}
                    </Typography>
                    <Typography>
                      <span style={{ fontWeight: "bold" }}>File Size :</span>{" "}
                      {fileSizeInKBorMB(selectedFile?.size)}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "right" }}>
                    <DeleteOutlineOutlined
                      onClick={handleDeleteFiles}
                      className="delete_icon"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
      <div
        style={{
          textAlign: "right",
          marginTop: "10px",
        }}
      >
        <Button
          variant="outlined"
          size="large"
          onClick={handleBack}
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          size="large"
          onClick={() => handleSubmit(false)}
          disabled={selectedFile || sendingToAllCustomer ? false : true}
        >
          Send Message
        </Button>
      </div>

      <ModalContentORLoader
        open={notificationOpen}
        title="Message Sent"
        onClose={handleNotificationClose}
        msg={successMsg}
      />
      <ModalContentORLoader
        open={showLoader}
        onClose={handleShowLoader}
        title="Redirecting..."
        hideClose={true}
        msg={redirectMsg}
      />

      <ModalContentORLoader
        open={confirmToSendAllCustomer}
        title="Send To All Existing Customers"
        onClose={handleConfirmAllCustomersClick}
        // onContinue={handleSendingToAllCustomerClick}
        onContinue={() => handleSubmit(true)}
        msg={"Are you sure you want to send to all customers?"}
      />
    </div>
  );
}
