import { isMobile } from "react-device-detect";
import { styled } from "@mui/system";

export const List = styled("ul")(() => ({
  // position: "absolute",
  background: "#fff",
  minWidth: isMobile ? "100%" : 270,
  maxWidth: isMobile ? "100%" : "fit-content",
  listStyle: "none",
  borderRadius: 4,
  minHeight: 50,
  maxHeight: 230,
  overflowY: "auto",
  overflowX: "hidden",
  zIndex: 10,
  top: 35,
  padding: 0,
  margin: 0,
  boxShadow:
    "0px 2px 0px -3px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 3px 0px rgb(0 0 0 / 12%)",
}));

export const ListItem = styled("li")(({ disabled }) => ({
  minHeight: "2.5rem",
  padding: "1rem",
  display: "flex",
  alignItems: "center",
  borderRadius: 4,
  cursor: "pointer",
  transition: "0.5s all",
  opacity: disabled ? 0.6 : 1,
  borderBottom: "1px solid #ccc",
  ":hover": {
    background: disabled ? "inherit" : "#338f6c",
    color: disabled ? "inherit" : "#fff",
  },
}));

export const NotFound = styled(ListItem)(() => ({
  height: 50,
  textAlign: "center",
  background: "#338f6c",
  color: "#fff",
  fontWeight: 500,
  marginTop: 8,
  ":hover": {
    background: "#338f6c",
  },
}));
