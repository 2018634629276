import React from "react";
import useDebounce from "../../hooks/useDebounce";
import Api from "../../services/apiv2.service";

const useCatalogue = ({ onSelectProduct, searchKey }) => {
  const [catalogues, setCatalogues] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [noDataFound, setNoDataFound] = React.useState(false);

  const createFlatObj = async (obj, returnObj = {}, join = null) => {
    for (const [key, value] of Object.entries(obj)) {
      if (key === "categories" && Array.isArray(value)) {
        returnObj["category"] = value[value.length - 1]?.name;
      } else if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null
      )
        createFlatObj(value, returnObj, `${key}`);
      else if (typeof value !== "object") {
        returnObj[join ? `${join}_${key}` : key] = value;
      }
    }
    return returnObj;
  };

  const filterArr = async (arr = []) => {
    if (arr.length < 1) {
      onSelectProduct(null);
      return [];
    }
    let returnArr = [];
    for (let i = 0; i < arr.length; i++) {
      returnArr.push(await createFlatObj(arr[i]));
    }
    // setting first index to show the current product
    onSelectProduct(returnArr[0]);
  };
  const setData = async (res, type) => {
    if (res?.data?.message === "SUCCESS" || res?.data?.status === "SUCCESS") {
      let resData = res?.data?.additionalInfo;
      switch (type) {
        case "catalogue":
          // return {
          setCatalogues(resData && resData.length > 0 ? resData : []);
          setNoDataFound(resData && resData.length > 0 ? false : true);
          // }
          return;

        case "product":
          return filterArr(resData?.products);

        default:
          break;
      }
    }
  };

  const getBrandDetails = async (e) => {
    if (e[searchKey] === "others") {
      onSelectProduct(null);
      return;
    }
    const payload = { listOfIds: [e._id] };
    const res = await Api.geProductDetails(payload);
    await setData(res, "product");
  };

  const getCatalogues = async (searchStr) => {
    const res = await Api.getCatalogue(searchStr);
    await setData(res, "catalogue");
    setIsLoading(false);
  };

  const searchCatalogue = useDebounce((value) => {
    setIsLoading(true);
    getCatalogues(value);
  }, 1000);

  return {
    searchCatalogue,
    getBrandDetails,
    catalogues,
    isLoading,
    noDataFound,
  };
};
export default useCatalogue;
