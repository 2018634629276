import { Grid, Box, CircularProgress } from "@mui/material";
import React from "react";
import Layout from "../../components/Layout";
import HelpCard from "./components/HelpCard";
import useHelp from "./useHelp";
import "./style.css";
import SectionHeader from "../../components/SectionHeader";

const Help = () => {
  const { respData, isLoading } = useHelp();
  return (
    <Layout pageTitle="Help">
      <SectionHeader heading="Help" />
      {isLoading ? (
        <Box style={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={4}>
          {respData?.length > 0
            ? respData.map((post) => (
                <Grid key={post.id} item xs="12" md="4">
                  <HelpCard post={post} />
                </Grid>
              ))
            : "No New Offers"}
        </Grid>
      )}
    </Layout>
  );
};

export default Help;
