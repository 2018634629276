import React, { useState } from "react";
import { Box, CssBaseline } from "@mui/material/";
import { isMobile } from "react-device-detect";
import AppDrawer from "./AppDrawer";
import MainChildren from "./MainChildren";
import "./style/style.css";
import { Helmet } from "react-helmet";

const DRAWERWIDTH = 200;

export default function Layout({ children, pageTitle }) {
  const [open, setOpen] = useState(!isMobile);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <CssBaseline />
      <AppDrawer
        isMobile={isMobile}
        open={open}
        drawerWidth={DRAWERWIDTH}
        handleDrawerClose={handleDrawerClose}
      />
      <MainChildren
        isMobile={isMobile}
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        pageTitle={pageTitle}
      >
        {children}
      </MainChildren>
    </Box>
  );
}
