import React from "react";
import {
  Typography,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { withStyles } from "@mui/styles";
import useDrawerAPI from "./useDrawerAPI.hook";
import useAnalytics from "../../../screens/transaction-history/useAnalytics.hook";
import { useHistory } from "react-router-dom";

const CustomTableCell = withStyles(() => ({
  root: {
    minWidth: 150,
    backgroundColor: "#338F6B",
    color: "white",
    textAlign: "center !important",
    fontSize: "15px !important",
    lineHeight: "1.5rem !important",
    fontWeight: "bold !important",
    padding: "6.5px !important",
  },
}))(TableCell);

export default function DrawerLayout({ onClose, activeOrderId, orderDetails }) {
  const { loading, data } = useDrawerAPI(activeOrderId);
  const {
    logCreateInvoiceCategoryBtnClick,
    // logEditInvoiceCategoryBtnClick,
    logViewInvoiceCategoryBtnClick,
  } = useAnalytics();
  const history = useHistory();
  return (
    <div style={{ padding: "10px 20px 10px 20px" }}>
      <div style={{ textAlign: "right" }}>
        <Button onClick={onClose}>
          <CloseIcon />
        </Button>
      </div>
      <div style={{ padding: "10px", borderBottom: "1px solid #555E7933" }}>
        <Typography style={{ fontWeight: "bold" }}>Product Details</Typography>
      </div>
      <div>
        {loading ? (
          <div
            style={{ width: "100%", textAlign: "center", marginTop: "20px" }}
          >
            <CircularProgress color="success" />
          </div>
        ) : (
          <>
            <TableContainer style={{ marginTop: "10px" }} component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <CustomTableCell align="center">Category</CustomTableCell>
                    <CustomTableCell align="center">Amount</CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length > 0 &&
                    data.map((product, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row" align="center">
                            {product.productCategory}
                          </TableCell>
                          <TableCell align="center">
                            {product.productPrice}
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ fontWeight: "bold" }}
                    >
                      Total Amount
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Rs. {orderDetails.totalOrderAmount}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                padding: "10px",
                borderBottom: "1px solid #555E7933",
                marginTop: "30px",
              }}
            >
              <Typography style={{ fontWeight: "bold" }}>
                Order Details
              </Typography>
            </div>
            <TableContainer style={{ marginTop: "10px" }} component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ fontWeight: "bold" }}
                    >
                      Order Id
                    </TableCell>
                    <TableCell align="center">{orderDetails.orderId}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ fontWeight: "bold" }}
                    >
                      Invoice
                    </TableCell>
                    <TableCell align="center">
                      {orderDetails.invoiceUrl &&
                      orderDetails.invoiceUrl !== "" ? (
                        <Link
                          href={orderDetails.invoiceUrl}
                          target="_blank"
                          rel="noreferrer"
                          onClick={logViewInvoiceCategoryBtnClick}
                        >
                          {orderDetails.invoiceNumber}
                        </Link>
                      ) : (
                        <Button
                          variant="outlined"
                          size="small"
                          style={{ fontSize: "10px" }}
                          color="primary"
                          onClick={() => {
                            logCreateInvoiceCategoryBtnClick();
                            history.push(
                              `/generate-invoice?orderId=${orderDetails.orderId}&redirectFrom=order-history&cp=1`
                            );
                          }}
                        >
                          Create invoice
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
    </div>
  );
}
