import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "50px 40px",
      marginTop: "20px",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        padding: "10px 20px",
      },
    },
    header: {
      fontSize: "14px",
      color: theme.palette.primary.dark,
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
    signature: {
      marginTop: "20px",
      fontSize: "12px",
      color: theme.palette.primary.dark,
      border: "1px dashed",
      borderRadius: "10px",
      padding: "20px",
      paddingLeft: "30px",
      textAlign: "center",
    },
    signatureImg: {
      position: "relative",
    },
    removeSignature: {
      position: "absolute",
      top: "-20px",
      right: "-20px",
      cursor: "pointer",
      textDecoration: "underline",
      padding: "10px",
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "#f6f9f7",
      },
    },
    addSignature: {
      cursor: "pointer",
      textDecoration: "underline",
      padding: "10px",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: "#f6f9f7",
      },
    },
  })
);

export default useStyles;
