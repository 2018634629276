import React from "react";
import { Button, TableBody, TableCell, TableRow } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import CustomTableRow from "./CustomTableRow";
import useAnalytics from "../../useAnalytics.hook";
import useSearchEditExist from "../../../../../hooks/useSearchEditExist";
const CustomTableBody = ({
  arrayHelpers,
  values,
  setFieldValue,
  categoriesBands,
  disableFields,
  totalAmount,
}) => {
  const { logAddProductBtnClick } = useAnalytics();
  const { checkEditExist } = useSearchEditExist();
  const products = values.products;
  const addProduct = () => {
    arrayHelpers.push({
      category: "",
      brand: "",
      hsn: "",
      gst: "",
      model: "",
      serialNo: "",
      quantity: "1",
      finalPrice: "",
    });
    logAddProductBtnClick();
  };

  let isEditExist = checkEditExist("edit");
  let checkIfQuickCheckout = checkEditExist("qc");

  //
  if (isEditExist && values?.type === "ONLINE") {
    disableFields = true;
  }

  if (checkIfQuickCheckout) {
    disableFields = false;
  }

  return (
    <TableBody>
      {products &&
        products.length > 0 &&
        products.map((product, index) => {
          if (products.length === 1) {
            product.finalPrice = totalAmount;
          }
          return (
            <CustomTableRow
              key={index}
              index={index}
              product={product}
              arrayHelpers={arrayHelpers}
              setFieldValue={setFieldValue}
              checkIfQuickCheckout={checkIfQuickCheckout}
              isEditExist={isEditExist}
              categoriesBands={categoriesBands}
              disableFields={disableFields}
            />
          );
        })}

      {!checkIfQuickCheckout && (
        <TableRow>
          <TableCell colSpan={10} className="add-btn-container">
            <Button
              className="add-btn"
              onClick={addProduct}
              disabled={disableFields}
            >
              <AddIcon fontSize="small" /> Add Product
            </Button>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default CustomTableBody;
