/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Loader from "../../../components/Loader";
import { useQueryParams } from "../../../helpers/common-utils";
import { useGenerateInvoiceContex } from "../store/contex";
import useInvoiceDetails from "../store/useInvoiceDetails";
import useRetailer from "../store/useRetailer";
import GenerateInvoice from "./index";

const GenerateInvoiceWrapper = () => {
  const {
    state: { invoiceData },
  } = useGenerateInvoiceContex();
  const { getOrderDetailsAction } = useInvoiceDetails();
  const { getRetailsDetails } = useRetailer();
  const params = useQueryParams();

  useEffect(() => {
    params?.invoiceNumber &&
      getOrderDetailsAction({ invoiceNumber: params?.invoiceNumber });
    params?.orderId && getOrderDetailsAction({ orderId: params?.orderId });
    localStorage.removeItem("allowRedirection");
    getRetailsDetails();
  }, []);

  if (invoiceData.loading) return <Loader open={true} />;

  return <GenerateInvoice invoiceData={invoiceData?.data?.invoiceDetails} />;
};

export default GenerateInvoiceWrapper;
