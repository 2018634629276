import { useState } from "react";
import { toast } from "react-toastify";
import { InvoicePatternStringLocations } from "../constants";
import Api from "../../../services/apiv2.service";

const isNotEmpty = (patternPayload) => {
  return Object.keys(patternPayload).every(
    (item) => patternPayload[item] !== ""
  );
};

const convertToHumanReadable = (errorMessage) => {
  const fieldToHumanReadable = {
    stringComponent: "Abbreviation",
    stringLocation: "Abbreviation position",
    enum: " ",
  };
  const normalizedText = errorMessage
    .split(" ")
    .map((text) => fieldToHumanReadable[text] || text)
    .join(" ");
  return normalizedText;
};

export const useInvoicePattern = (invoicingPattern) => {
  const [loading, setLoading] = useState(false);
  const [patternPayload, setPatternPayload] = useState({ ...invoicingPattern });
  const { stringComponent, startFrom, stringLocation } = patternPayload;
  const [prefix, suffix] = InvoicePatternStringLocations;
  const updateFormData = (field) => {
    return (e) => {
      const newFormData = { ...patternPayload, [field]: e.target.value };
      setPatternPayload(newFormData);
    };
  };
  const generateSampleInvoiceNumber = () => {
    const isElgible = isNotEmpty(patternPayload);
    if (!isElgible)
      return "All settings must be selected before a preview can be generated";
    const invoiceString = `${
      stringLocation === prefix ? stringComponent : ""
    }${startFrom}${stringLocation === suffix ? stringComponent : ""}`;
    return invoiceString;
  };

  const saveInvoicingPattern = async () => {
    setLoading(true);
    const isEligible = isNotEmpty(patternPayload);
    if (!isEligible) {
      setLoading(false);
      toast.error("All Fields are mandatory, and must not be empty.");
      return;
    }

    const finalPayload = { ...patternPayload, startFrom: parseInt(startFrom) };
    const resp = await Api.updateInvoicingPattern(finalPayload);
    if (!resp.ok) {
      const {
        message: [errorMessage],
      } = resp.data;
      toast.error(convertToHumanReadable(errorMessage));
    } else {
      toast.success("Your Invoice Settings saved!");
    }
    setLoading(false);
  };

  return {
    generateSampleInvoiceNumber,
    updateFormData,
    stringComponent,
    startFrom,
    stringLocation,
    loading,
    saveInvoicingPattern,
  };
};
