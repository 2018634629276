import { useEffect, useState } from "react";
import { useGenerateInvoiceContex } from "../../../store/contex";
import useAnalytics from "./useAnalytics.hook";

const useConfirmationHook = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const {
    state: { invoiceDetails },
  } = useGenerateInvoiceContex();

  const {
    logConfirmationPopupLoaded,
    logSubmitBtnClick,
    logCancelBtnClick,
    logCloseBtnClick,
  } = useAnalytics();

  const openModal = (data) => {
    setOpen(true);
    setData(data);
    logConfirmationPopupLoaded();
  };

  const closeModal = () => {
    logCancelBtnClick();
    setOpen(false);
  };

  useEffect(() => {
    if (invoiceDetails?.data?.invoiceNo) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceDetails?.data]);

  return {
    open,
    openModal,
    closeModal,
    logSubmitBtnClick,
    logCloseBtnClick,
    data,
  };
};

export default useConfirmationHook;
