import { useState } from "react";
import Api from "../../services/apiv2.service";
import { toast } from "react-toastify";

const useInvoiceUpload = (handleClose) => {
  const [dragActive, setDragActive] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [enableUploadButton, setEnableUploadButton] = useState(true);
  const [uploadProgress, setUploadProgress] = useState(false);
  const [onSuccess, setOnSuccess] = useState(false);
  const [invalidFileError, setInvalidFileError] = useState(false);

  const handleDragOver = () => {
    setDragActive("image-dropping");
  };
  const handleDragLeave = () => {
    setDragActive("");
  };

  const changeHandler = (event) => {
    const {
      files: [{ type }],
    } = event.target;
    if (type === "application/pdf" || type.includes("image/")) {
      setInvalidFileError(false);
      setSelectedFile(event.target.files[0]);
      setEnableUploadButton(false);
    } else {
      setInvalidFileError(
        "Invalid File Type, Please Upload Only PDF,JPG or PNG Format"
      );
    }
  };

  const handleFileClick = (fileUploadRef) => {
    fileUploadRef.current.click();
  };

  const handleDeleteFiles = () => {
    setSelectedFile("");
    setEnableUploadButton(true);
  };

  const handleSubmit = async (orderId) => {
    let formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("orderId", orderId);
    setUploadProgress(true);
    let res = await Api.uploadInvoice(formData);
    if (res.data.status === "success") {
      setUploadProgress(false);
      // Handle Invoice close
      handleInvoiceModalClose("success");
      toast.success("Invoice uploaded successfully");
    }
  };

  const fileSizeInKBorMB = (totalBytes) => {
    if (totalBytes < 1000000) {
      return Math.floor(totalBytes / 1000) + "KB";
    } else {
      return Math.floor(totalBytes / 1000000) + "MB";
    }
  };

  const handleInvoiceModalClose = (type = "failed") => {
    setSelectedFile("");
    setInvalidFileError(false);
    setOnSuccess(false);
    setEnableUploadButton(true);
    handleClose(type);
  };

  return {
    dragActive,
    handleDragOver,
    handleDragLeave,
    changeHandler,
    handleSubmit,
    handleFileClick,
    enableUploadButton,
    selectedFile,
    fileSizeInKBorMB,
    handleDeleteFiles,
    handleInvoiceModalClose,
    uploadProgress,
    onSuccess,
    invalidFileError,
  };
};

export default useInvoiceUpload;
