import React from "react";
import { Card, CardContent, Radio, Typography, Grid } from "@mui/material";

export default function WhatsappCampaignCard({
  handleOnSelectOfCampaign,
  selectionData,
  data,
  channelName,
}) {
  const { id, category, header, template, footer } = data;
  const campaignCardSelected = selectionData.campaign_id === id;
  const filterTemplateData = template
    .split("\n")
    .filter((element) => element !== "");

  return (
    <Grid item xs={2} sm={4} md={4} key={id}>
      <Card
        variant="outlined"
        className={`template_card ${
          campaignCardSelected ? "template_card_active" : ""
        }`}
      >
        <CardContent>
          <div className="template_content_container">
            <div className="template_title_container">
              <div>
                <h3>{category || "Templete Name Here"}</h3>
              </div>
              <div>
                <Radio
                  checked={campaignCardSelected}
                  onChange={() =>
                    handleOnSelectOfCampaign(
                      selectionData.campaign_id === id ? "" : id,
                      category,
                      template,
                      channelName
                    )
                  }
                />
              </div>
            </div>
            <div>
              <Typography>{header}</Typography>
              {(filterTemplateData || []).map((templateData) => {
                return (
                  <>
                    <Typography> {templateData} </Typography> <br />
                  </>
                );
              })}
              <Typography style={{ fontWeight: "bold" }}>{footer}</Typography>
            </div>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
}
