import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker.hook";

const useAnalytics = () => {
  const { logEvent } = useAnalyticsEventTracker("Customers Details");

  const logPageLoaded = () => {
    logEvent(
      "CRM_CUSTOMER_REPOSITORY_PAGE_LANDING",
      "Customers Details Page Landing"
    );
  };

  return {
    logPageLoaded,
  };
};

export default useAnalytics;
