/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { TableCell, TableRow, TextField } from "@mui/material";

import CustomInput from "../CustomInput";
import CustomSelect from "../CustomSelect";

import useCustomTableRow from "./useCustomTableRow.hook";
import useCategoryNbrandsHook from "../../useCategoriesNbands.hook";
import { useQueryParams } from "../../../../../helpers/common-utils";
// import useAnalytics from "../../useAnalytics.hook";

const CustomTableRow = ({
  index,
  product,
  arrayHelpers,
  setFieldValue,
  disableFields,
  checkIfQuickCheckout,
  isEditExist,
}) => {
  const params = useQueryParams();
  const { categories, setCategory, getCategoryName, getBrandName } =
    useCategoryNbrandsHook();
  const { hsnGstDetails } = useCustomTableRow(index, product, setFieldValue);

  const [fieldsDisabled, setFieldsDisabled] = useState(false);

  useEffect(() => {
    params?.invoiceNumber && setFieldsDisabled(true);
  }, []);
  // const { logRemoveProductBtnClick } = useAnalytics();

  useEffect(() => {
    if (product?.category && categories?.length > 0) {
      setCategory(product?.category || "");
    }
  }, [product?.category, categories]);

  useEffect(() => {
    setFieldValue(
      `products.${index}.title`,
      `${getCategoryName(product?.brand)} - ${getBrandName(product?.model)}`
    );
  }, [categories, product?.brand, product?.model]);

  return (
    <TableRow
      key={`product-row-${index}`}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        {index + 1}
      </TableCell>

      <TableCell className="table-cell" component="th" scope="row">
        <TextField
          className="txt-input"
          name={`products.${index}.category`}
          value={product?.category}
          disabled
          size="small"
        />
      </TableCell>
      <TableCell className="table-cell" component="th" scope="row">
        <TextField
          className="txt-input"
          name={`products.${index}.brand`}
          value={product?.brand}
          disabled
          size="small"
        />
      </TableCell>
      <TableCell className="table-cell" component="th" scope="row">
        {hsnGstDetails?.hsn_code?.length > 1 ? (
          <CustomSelect
            name={`products.${index}.hsn`}
            options={hsnGstDetails?.hsn_code?.map((hsn) => ({
              label: hsn,
              value: hsn,
            }))}
            disabled={disableFields}
          />
        ) : (
          <CustomInput name={`products.${index}.hsn`} disabled />
        )}
      </TableCell>
      <TableCell className="table-cell" component="th" scope="row">
        {hsnGstDetails?.gst_perc?.length > 1 ? (
          <CustomSelect
            name={`products.${index}.gst`}
            options={hsnGstDetails?.gst_perc?.map((gst) => ({
              label: gst,
              value: gst,
            }))}
            disabled={disableFields}
          />
        ) : (
          <CustomInput
            name={`products.${index}.gst`}
            endingText={"%"}
            disabled
          />
        )}
      </TableCell>

      <TableCell className="table-cell" component="th" scope="row">
        <CustomInput
          className="txt-input"
          name={`products.${index}.model`}
          disabled={product.productCatalogueId || fieldsDisabled}
          autoComplete="off"
        />
      </TableCell>
      <TableCell className="table-cell" component="th" scope="row">
        <CustomInput
          className="txt-input"
          name={`products.${index}.serialNo`}
          disabled={disableFields}
          autoComplete="off"
        />
      </TableCell>

      <TableCell className="table-cell" component="th" scope="row">
        <CustomInput
          className="txt-input"
          name={`products.${index}.quantity`}
          disabled
        />
      </TableCell>
      <TableCell className="table-cell" component="th" scope="row">
        <CustomInput
          className="txt-input"
          startingText="₹"
          autoComplete="off"
          // value={Number(product?.finalPrice) ? Number(product?.finalPrice) : ""}

          size="small"
          name={`products.${index}.finalPrice`}
          // value={`${products.${index}.finalPrice}`}
          disabled={
            Number(product?.unitPrice) * Number(product?.quantity)
              ? true
              : false
          }
        />
      </TableCell>
      {/* <TableCell component="th" scope="row">
        <IconButton
          size="small"
          onClick={() => {
            arrayHelpers.remove(index);
            logRemoveProductBtnClick();
          }}
          disabled={disableFields}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell> */}
    </TableRow>
  );
};

export default CustomTableRow;
