import { Add, Remove } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React from "react";
import { dataFormatter } from "../../../helpers/constants";

const SelectedProduct = ({
  product,
  onRemove,
  onAddOrRemove = () => {},
  onPriceChange = () => {},
  onBlur = () => {},
  touched,
  errors,
}) => {
  return (
    <Card sx={{ mb: 2, p: 2 }}>
      <Grid container alignItems={"center"}>
        <Grid
          md={2}
          xs={12}
          display={"flex"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <img
            src={product.image}
            height={100}
            width={"100%"}
            style={{ objectFit: "scale-down" }}
            alt="product"
          />
        </Grid>
        <Grid md={6} xs={12} paddingLeft={3} sx={{ fontSize: 14 }}>
          <Grid container>
            <Grid md={12}>
              <strong>Name:</strong> {product.model}
            </Grid>
            <Grid md={12}>
              <strong>Category:</strong> {product.category}
            </Grid>
            <Grid md={12}>
              <strong>Brand:</strong> {product.brand}
            </Grid>

            <Grid md={12}>
              <strong>MRP:</strong> {dataFormatter(product.mrp, "currency")}
            </Grid>
            <Grid md={12} display="flex" alignItems={"center"}>
              <strong>Quantity:</strong>
              <IconButton
                size="small"
                color="warning"
                sx={{ ml: 2 }}
                onClick={() => onAddOrRemove(-1)}
                disabled={product.quantity < 2}
              >
                <Remove fontSize="inherit" />
              </IconButton>
              <Box sx={{ px: 2 }}>{product.quantity}</Box>
              <IconButton
                size="small"
                color="primary"
                onClick={() => onAddOrRemove(1)}
              >
                <Add fontSize="inherit" />
              </IconButton>
            </Grid>
            <Grid md={5} xs={12}>
              <TextField
                margin="dense"
                type="number"
                name="unitPrice"
                value={product.unitPrice}
                required
                label="Price/Unit"
                fullWidth
                size="small"
                error={touched && Boolean(errors)}
                helperText={touched && errors}
                onChange={(e) => onPriceChange(e.target.value)}
                onBlur={onBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid md={4} xs={12} display={"flex"} justifyContent={"end"}>
          <Button variant="outlined" color="warning" onClick={onRemove}>
            X
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SelectedProduct;
