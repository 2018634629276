import React from "react";
import { useIdleTimer } from "react-idle-timer";
import {
  handleUserTrackingAPI,
  TRACKING_VALUES,
} from "../../services/trackUser";

function IdleTimer({ idleTimeout }) {
  const onIdle = () => {
    handleUserTrackingAPI({
      event: TRACKING_VALUES["idle"],
      timestamp: Date.now(),
    });
  };

  const onActive = (event) => {
    handleUserTrackingAPI({
      event: TRACKING_VALUES["active"],
      timestamp: Date.now(),
    });
  };

  const onAction = (event) => {
    if (
      event.type === "visibilitychange" &&
      event.target.visibilityState === "visible"
    ) {
      handleUserTrackingAPI({
        event: TRACKING_VALUES["focus_in"],
        timestamp: Date.now(),
      });
    } else if (
      event.type === "visibilitychange" &&
      event.target.visibilityState === "hidden"
    ) {
      handleUserTrackingAPI({
        event: TRACKING_VALUES["focus_out"],
        timestamp: Date.now(),
      });
    }
  };

  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: idleTimeout,
    debounce: 60000,
  });

  return null;
}

export default React.memo(IdleTimer);
