import React from "react";
import defaultImage from "./images/empty-image.svg";

function PreviewImage(props) {
  const { previewImage, data } = props;
  const { header, template, footer } = data;

  return (
    <div className="preview-wrapper">
      <h3 className="preview-text"> Preview </h3>
      <div className="preview-image-container">
        <img
          src={previewImage || defaultImage}
          alt="Not Found"
          width="100%"
          style={{
            objectFit: "contain",
          }}
          className={`${previewImage ? "" : "default-image"}`}
        />
      </div>
      <div className="preview-data">
        <div> {header} </div>
        <div> {template} </div>
        <div> {footer} </div>
      </div>
    </div>
  );
}

export default PreviewImage;
