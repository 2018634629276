import React from "react";
import { Box, CircularProgress } from "@mui/material";
import Layout from "../../components/Layout";
import ProfileCard from "../../components/ProfileCard";
import PersonalProfile from "./PersonalProfile";
import BankDetails from "./BankDetails";
import useStyle from "./style";
import useProfile from "./hook/useProfile";
import KYCDetails from "./KYCDetails";
import InvoicingSettings from "./InvoicingSettings";

const Profile = () => {
  const classes = useStyle();
  const { loading, profileData } = useProfile();

  return (
    <Layout pageTitle={"Profile"}>
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress color="success" />
        </div>
      ) : (
        <Box className={classes.contentContainer}>
          <Box className={classes.retailerContainer}>
            <Box>
              <ProfileCard name="Profile Details" collapseStatus={true}>
                <PersonalProfile
                  personalProfile={profileData.businessDetails}
                />
              </ProfileCard>
            </Box>
            <Box>
              <ProfileCard name="Bank Details" collapseStatus={false}>
                <BankDetails
                  bankData={profileData.bankDetails}
                  kyc={profileData.kyc}
                />
              </ProfileCard>
            </Box>
            <Box>
              <ProfileCard name="KYC Details" collapseStatus={false}>
                <KYCDetails kyc={profileData.kyc} />
              </ProfileCard>
            </Box>
            <Box>
              <ProfileCard name="Invoicing Settings" collapseStatus={true}>
                <InvoicingSettings
                  invoicingPattern={profileData.invoicingPattern}
                />
              </ProfileCard>
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
};

export default Profile;
