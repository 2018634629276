/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Form } from "formik";

import CustomerForm from "./components/CustomerForm";
import InvoiceDetailsForm from "./components/InvoiceDetails";
import Extras from "./components/Extras";
import ProductTable from "./components/ProductTable";
import Signature from "./components/Signature";
import TermsAndConditions from "./components/TermsAndConditions";
import { useGenerateInvoiceContex } from "../store/contex";

const GenerateInvoiceForm = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldError,
  formErrors,
  setFormErrors,
  disableFields,
  isCollectPayment,
  isEdit,
  invoiceData,
}) => {
  const {
    state: { retailerDetails },
  } = useGenerateInvoiceContex();

  const storeInvoiceDate = (invoiceDate = "") => {
    setFieldValue("invoiceDate", invoiceDate || "");
  };

  const getTotalAmount = (products) => {
    return products?.reduce(
      (accumulator, current) =>
        accumulator + Number(current.unitPrice) * Number(current.quantity),
      0
    );
  };

  const getBalanceAmount = () => {
    let totalAmount = getTotalAmount(values?.products);
    let amountPaid = invoiceData?.totalAmount;

    return Number(totalAmount) - Number(amountPaid);
  };

  useEffect(() => {
    setFieldValue(
      "termsAndConditions",
      retailerDetails?.data?.termsAndConditions
    );
    setFieldValue(
      "signatureUrl",
      retailerDetails?.data?.businessDetails?.signature
    );
  }, [retailerDetails?.data?.termsAndConditions]);

  return (
    <Form>
      <Box className="customer-section">
        <Grid container>
          <Grid item xs={12} md={8}>
            <Box className="bill-to-container">
              <Box className="header-container">
                <Box className="header">Bill To</Box>
              </Box>
              <Box className="customer-form-container">
                <CustomerForm
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  disableFields={disableFields}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <InvoiceDetailsForm
              storeInvoiceDate={storeInvoiceDate}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
          </Grid>
        </Grid>
      </Box>

      <ProductTable
        values={values}
        totalAmount={invoiceData?.totalAmount}
        setFieldValue={setFieldValue}
        disableFields={disableFields}
        errors={errors}
        touched={touched}
      />

      <Box className="third-section">
        <Grid container spacing={4} className="content">
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <TermsAndConditions />
            <Signature values={values} setFieldValue={setFieldValue} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Extras
              values={values}
              setFieldValue={setFieldValue}
              disableFields={disableFields}
              isCollectPayment={isCollectPayment}
              isEdit={isEdit}
              invoiceData={invoiceData}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="btn-container">
        <Button
          className="submit-btn"
          variant={"contained"}
          color="secondary"
          type="submit"
        >
          {isEdit && getBalanceAmount() <= 0
            ? "Modify Invoice"
            : values.type === "ONLINE" && !isCollectPayment
            ? "Collect payment"
            : isEdit
            ? "Modify Invoice"
            : "Generate Invoice"}
        </Button>
      </Box>
    </Form>
  );
};

export default GenerateInvoiceForm;
