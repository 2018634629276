import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    loadingContainer: {
      textAlign: "center",
    },
    mobileTitle: {
      fontWeight: "bold",
      color: "#338F6C",
    },
  })
);

export default useStyles;
