import React from "react";
import { Box, Table, TableBody, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import useStyle from "./style";

export default function KYCDetails({ kyc }) {
  const classes = useStyle();
  return (
    <Box>
      <Table
        aria-label="Bank details"
        className={classes.tableContianer}
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableBody className={classes.tableBody}>
          <TableRow>
            <TableCell className={classes.tableTitle}>GSTIN:</TableCell>
            <TableCell className={classes.tableCell} align="left">
              {kyc?.gstin || "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>PAN:</TableCell>
            <TableCell className={classes.tableCell} align="left">
              {kyc?.pan || "-"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
