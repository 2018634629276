import React from "react";
import WhatsappCampaignCard from "../../../components/CampaignCard/WhatsappCampaignCard";
import CampaignCard from "../../../components/CampaignCard";
import { Grid, CircularProgress } from "@mui/material";
import useStyles from "../css/style";
import { WHATSAPP_CHANNEL_NAME } from "../message-constants";

const CampaignView = ({
  loadingData,
  templates,
  templateSelectionData,
  handleCampaignSelection,
  channelName,
}) => {
  const classes = useStyles();
  return (
    <div className="campaign_container">
      <div className="campaign_title_container">
        <h3 className="campaign_title">Choose the Campaign</h3>
      </div>
      <div className={classes.templateContainer} style={{ width: "95%" }}>
        {loadingData ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="success" />
          </div>
        ) : (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {(templates || []).map((item) => {
              return channelName === WHATSAPP_CHANNEL_NAME ? (
                <WhatsappCampaignCard
                  key={item.id}
                  data={item}
                  selectionData={templateSelectionData}
                  handleOnSelectOfCampaign={handleCampaignSelection}
                  channelName={channelName}
                />
              ) : (
                <CampaignCard
                  key={item.id}
                  data={item}
                  selectionData={templateSelectionData}
                  handleOnSelectOfCampaign={handleCampaignSelection}
                  channelName={channelName}
                />
              );
            })}
          </Grid>
        )}
      </div>
    </div>
  );
};

export default CampaignView;
