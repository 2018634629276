export const GET_RETAILER_DETAILS_LOADING = "GET_RETAILER_DETAILS_LOADING";
export const GET_RETAILER_DETAILS_SUCCESS = "GET_RETAILER_DETAILS_SUCCESS";
export const GET_RETAILER_DETAILS_ERROR = "GET_RETAILER_DETAILS_ERROR";

export const STORE_CUSTOMER_DETAILS_SUCCESS = "STORE_CUSTOMER_DETAILS_SUCCESS";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const INCREMENT_QUANTITY = "INCREMENT_QUANTITY";
export const DECREMENT_QUANTITY = "DECREMENT_QUANTITY";

export const STORE_ADDITIONAL_CHARGE = "STORE_ADDITIONAL_CHARGE";
export const STORE_DISCOUNT_DETAILS = "STORE_DISCOUNT_DETAILS";
export const STORE_RECEIVED_AMOUNT_DETAILS = "STORE_RECEIVED_AMOUNT_DETAILS";
export const STORE_IS_ROUND_OFF = "STORE_IS_ROUND_OFF";

export const STORE_INVOICE_DATE = "STORE_INVOICE_DATE";

export const GENERATE_INVOICE_LOADING = "GENERATE_INVOICE_LOADING";
export const GENERATE_INVOICE_SUCCESS = "GENERATE_INVOICE_SUCCESS";
export const GENERATE_INVOICE_ERROR = "GENERATE_INVOICE_ERROR";

export const RESETTING = "RESETTING";
export const RESET_INVOICE_DETAILS = "RESET_INVOICE_DETAILS";
export const STORE_ERRORS = "STORE_ERRORS";

export const GET_INVOICE_DETAILS_LOADING = "GET_INVOICE_DETAILS_LOADING";
export const GET_INVOICE_DETAILS_SUCCESS = "GET_INVOICE_DETAILS_SUCCESS";
export const GET_INVOICE_DETAILS_ERROR = "GET_INVOICE_DETAILS_ERROR";
