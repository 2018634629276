import useAnalyticsEventTracker from "../../../analytics/useAnalyticsEventTracker.hook";
import { paymentTypes } from "../../../constants/payments";

const useAnalytics = () => {
  const { logEvent } = useAnalyticsEventTracker("Invoice");

  const logPageLanding = () => {
    logEvent("INVOICE_PAGE_LANDING", "Invoice Page Landing");
  };

  const logPaymentMethod = (value) => {
    let paymentMethod = paymentTypes.filter(
      (payment) => payment.value === value
    );
    paymentMethod = paymentMethod.length > 0 ? paymentMethod[0] : {};
    logEvent(
      `PAYMENT_DETAIL_${paymentMethod.value}_INV`,
      `Payment Details - ${paymentMethod.label} CTA`
    );
  };

  const logSubmitBtnClick = (paymentMethod) => {
    const button =
      paymentMethod === "ONLINE"
        ? {
            label: "Collect Payment",
            value: "COLLECT_PAYMENT",
          }
        : {
            label: "Generate Invoice",
            value: "GENERATE_INVOICE",
          };
    logEvent(`${button.value}_INV`, `${button.label} CTA`);
  };

  const logAddProductBtnClick = () => {
    logEvent("ADD_PRODUCTS_INV", "Add Products CTA");
  };

  const logRemoveProductBtnClick = () => {
    logEvent("REMOVE_PRODUCTS_INV", "Remove Products CTA");
  };

  const logConfirmationPopupLoaded = () => {
    logEvent("CONFIRMATION_POPUP_INV", "Confirmation Popup");
  };

  return {
    logPageLanding,
    logPaymentMethod,
    logSubmitBtnClick,
    logAddProductBtnClick,
    logRemoveProductBtnClick,
    logConfirmationPopupLoaded,
  };
};

export default useAnalytics;
