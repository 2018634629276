import { Typography } from "@mui/material"
import React from "react"

const AppNoData = ({ text = "No data available" }) => (
  <Typography textAlign="center" color="text.light">
    {text}
  </Typography>
)

export default AppNoData
