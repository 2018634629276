import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Api from "../../services/apiv2.service";
import moment from "moment";

const useBulkDownload = () => {
  const [loading, setLoading] = useState(true);
  const [bulkDowloadData, setBulkDownloadData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [disableDownloadBtn, setDisableDownloadBtn] = useState(true);

  const getDownloadList = async () => {
    setLoading(true);
    const res = await Api.getDownloadList();
    if (res.data.status === "success") {
      setLoading(false);
      setBulkDownloadData(res.data);
    } else {
      toast.error("Download Request Failed");
    }
  };

  const createBulkDownloadRequest = async () => {
    const data = {
      startDate: startDate,
      endDate: endDate,
    };

    const res = await Api.createBulkRequest(data);
    if (res.data.status === "success") {
      toast.success(res.data.msg);
      setStartDate(null);
      setEndDate(null);
      getDownloadList();
    } else {
      setStartDate(null);
      setEndDate(null);
      toast.error("Request failed");
    }
  };

  useEffect(() => {
    let startDateSanitize = moment(startDate);
    let endDateSanitize = moment(endDate);
    if (startDateSanitize.isValid() && endDateSanitize.isValid()) {
      let diffValue = endDateSanitize.diff(startDateSanitize, "days");
      if (diffValue >= 0) {
        setDisableDownloadBtn(false);
      } else {
        setDisableDownloadBtn(true);
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    getDownloadList();
  }, []);

  return {
    loading,
    bulkDowloadData,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    createBulkDownloadRequest,
    disableDownloadBtn,
  };
};

export default useBulkDownload;
