import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Prompt } from "react-router-dom";
import GlobalCss from "./assets/GlobalCss";
import theme from "./assets/theme";
import AppRoutes from "./routes";
import IdleTimer from "../src/components/IdleTimer";
import AppOnlineOROfflineNotifier from "./components/AppOnlineOROfflineNotifier";
import { onUserNavigateToOtherPage } from "./helpers/router-utils";
import ReactGA from "react-ga4";
import GrowthBookFeatureProvider from "./components/GrowthBookFeatureProvider";
import OfferPooling from "./components/OfferPooling";
import Loader from "./components/Loader";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";

const isProduction = process.env.NODE_ENV === "production";
console.log("Production: ", isProduction);
console.log("Software Version : ", version);

const App = () => {
  const [confirmOpen, setConfirmOpen] = useState(true);

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING_ID);
    ReactGA.gtag("config", { send_page_view: false });
    // ReactGA.send("pageview");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      isVerboseMode={true}
      loadingComponent={<Loader />}
    >
      <ThemeProvider theme={theme}>
        <Box className="App">
          <Router
            getUserConfirmation={onUserNavigateToOtherPage(
              confirmOpen,
              setConfirmOpen
            )}
          >
            <Prompt message="Are you sure you want to proceed?" />
            <GrowthBookFeatureProvider>
              <AppRoutes />
            </GrowthBookFeatureProvider>
          </Router>
        </Box>
        <GlobalCss />
        <ToastContainer />
        <AppOnlineOROfflineNotifier />
        <IdleTimer idleTimeout={60000} />
        <OfferPooling />
      </ThemeProvider>
    </CacheBuster>
  );
};

export default App;
